import React, { useState } from "react";
import FeedbackCard from "../../card/feedbackerCards/feedbackCard";
import { feedbacks } from "../../../services/feedbackData";
import ModelsSidebar from "../../models-Sidebar/models-sidebar";
import { motion, AnimatePresence } from "framer-motion";
import { selectCompanyDashboard } from "../../../redux/slices/companyDashboardSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import serviceFeedback from "../../../assets/images/serviceFeedback.png";

const initialState = {
  feedback_date: false,
  feedback_type: false,
  user: false,
  user_type: false,
  upDown: false,
};

function ServiceDetailsFeedbackModel({
  setstate,
  setPopup,
  feedServiceId,
  dashboard,
}) {
  const [show, setShow] = useState(false);
  const [Services, setServices] = useState(feedbacks);
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("");
  const State = useSelector(selectCompanyDashboard);
  const { usageReportFeedbacksData } = State;
  const { id } = useParams();
  const [feedbackId, setFeedbackId] = useState();
  const [userId, setuserId] = useState();

  const handlefeedbackDate = (type, set) => {
    setBtns({
      ...btns,
      feedback_date: true,
      upDown: set ? !btns.upDown : false,
      [active !== "feedback_date" && active]: false,
    });
    setActive("feedback_date");
    let sort = null;
    type === "asc"
      ? (sort = [...Services].sort((a, b) => a.feedback_date - b.feedback_date))
      : (sort = [...Services].sort(
          (a, b) => b.feedback_date - a.feedback_date
        ));
    setServices(sort);
  };

  const handleFeedbackType = (type, set) => {
    setBtns({
      ...btns,
      feedback_type: true,
      upDown: set ? !btns.upDown : false,
      [active !== "feedback_type" && active]: false,
    });
    setActive("feedback_type");
    let sort = null;
    type === "asc"
      ? (sort = [...Services].sort((a, b) => a.impact_rating - b.impact_rating))
      : (sort = [...Services].sort(
          (a, b) => b.impact_rating - a.impact_rating
        ));
    setServices(sort);
  };

  const handleUser = (type, set) => {
    setBtns({
      ...btns,
      user: true,
      upDown: set ? !btns.upDown : false,
      [active !== "user" && active]: false,
    });
    setActive("user");
    let sort = null;
    type === "asc"
      ? (sort = [...Services].sort((a, b) => a.service_name - b.service_name))
      : (sort = [...Services].sort((a, b) => b.service_name - a.service_name));
    setServices(sort);
  };

  const handleUserType = (type, set) => {
    setBtns({
      ...btns,
      user_type: true,
      upDown: set ? !btns.upDown : false,
      [active !== "user_type" && active]: false,
    });
    setActive("user_type");
    let sort = null;
    type === "asc"
      ? (sort = [...Services].sort((a, b) => a.sub_name - b.sub_name))
      : (sort = [...Services].sort((a, b) => b.sub_name - a.sub_name));
    setServices(sort);
  };

  const handleUpdown = () => {
    let type = "asc";

    if (!btns.upDown) {
      type = "dec";
    }

    btns.feedback_date && handlefeedbackDate(type, true);
    btns.feedback_type && handleFeedbackType(type, true);
    btns.user && handleUser(type, true);
    btns.user_type && handleUserType(type, true);
  };

  const closeSidebar = () => {
    if (show === true) {
      setShow(false);
    }
  };

  return (
    <div className="relative px-[20px]  min-w-[100vw] w-auto 2xl:min-w-full  2xl:w-[1300px]">
      <div
        style={{
          height: "calc(100vh - 80px)",
        }}
        className="relative overflow-x-scroll pt-[81px] overflow-scroll scrollbar-hide bg-[#363D50] rounded-[16px] flex flex-col"
      >
        <div className="w-full">
          <div className="flex w-full absolute bg-[#363D50] z-[2] rounded-t-[16px] top-0 left-0 justify-between px-[24px] py-[16px] border-b-[1px] border-[#41485C]">
            <div className="flex flex-start items-center gap-[16px]">
              <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
                <svg
                  width="33"
                  height="33"
                  viewBox="0 0 33 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.91063 9.66914C10.727 9.16154 11.7229 9.03114 12.6424 9.3114L19.3491 11.3554C20.2688 11.6357 20.7217 12.6703 20.3039 13.5362C19.9788 14.2099 19.223 14.56 18.4989 14.3725L15.1335 13.5006C15.0657 13.4823 14.9972 13.4752 14.9301 13.4783C14.728 13.4874 14.5407 13.5888 14.4222 13.7507C14.3787 13.8101 14.3445 13.8777 14.3222 13.9519C14.2997 14.026 14.2909 14.1013 14.2943 14.1748C14.3065 14.4446 14.483 14.6879 14.7485 14.7766L17.4288 15.7024C18.321 16.0105 19.2856 16.0409 20.1954 15.7895L27.3649 13.8084C27.8684 13.6693 28.4053 13.8576 28.7115 14.2807C29.1229 14.8491 28.9865 15.6446 28.4092 16.0435L19.9792 21.8671C19.1671 22.4281 18.1482 22.6003 17.1968 22.3374L9.8707 20.313C9.46585 20.2012 9.04776 20.1445 8.62774 20.1445H5.31011C4.94192 20.1445 4.64345 20.4429 4.64345 20.8111C4.64345 21.1793 4.94192 21.4778 5.31011 21.4778H8.62774C8.92776 21.4778 9.22639 21.5183 9.51557 21.5982L16.8417 23.6226C18.1736 23.9907 19.6001 23.7495 20.7371 22.9641L29.167 17.1405C30.3596 16.3166 30.6415 14.6732 29.7917 13.4989C29.159 12.6248 28.0499 12.2358 27.0098 12.5232L21.5426 14.0339C22.2493 12.4487 21.4109 10.5899 19.7378 10.08L13.0312 8.03599C11.7437 7.64362 10.3496 7.82618 9.20662 8.53682L4.31475 11.5783C4.00207 11.7727 3.90619 12.1838 4.1006 12.4965C4.295 12.8091 4.70608 12.905 5.01876 12.7106L9.91063 9.66914Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="flex flex-col">
                <span className="text-primary !text-[24px] !text-left">
                  Service feedback
                </span>
                <span className="text-secondary !text-left">
                  {usageReportFeedbacksData?.total_count} total
                </span>
              </div>
            </div>
            <div className="flex h-[40px] justify-end items-center">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  setPopup(false);
                }}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>

        {usageReportFeedbacksData?.total_count === 0 ? (
          <div className="card-section">
            <div className="cards_wrap inner_space flex flex-col gap-[40px] h-[100vh] lg:h-[calc(100vh-135px)] overflow-y-scroll scrollbar-hide home_module">
              <div className="imgWrapper">
                <img src={serviceFeedback} alt="img" className="boxImg" />
                <div className="boxHeading">No service feedback</div>
                <div className="boxText">
                  When users submit feedback for this service, it'll show up
                  here.
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-[24px]">
            <div className="grid grid-cols-1 !text-left mdl:grid-cols-2 items-start overflow-scroll scrollbar-hide gap-[40px]">
              {usageReportFeedbacksData?.feedbacks?.map((feedback) => (
                <FeedbackCard
                  feedbackId={feedback?.id}
                  id={id}
                  userid={feedback.user_id}
                  userName={feedback.user_name}
                  key={feedback?.id}
                  outlined={true}
                  image={feedback?.image_url}
                  userFeedback={feedback?.feedback_details}
                  name={feedback?.service_name}
                  subName={""}
                  rating={feedback?.rating}
                  date={feedback?.date}
                  model={true}
                  show={show}
                  setShow={setShow}
                  setFeedbackId={setFeedbackId}
                  setuserId={setuserId}
                />
              ))}
            </div>
          </div>
        )}

        <AnimatePresence>
          {show === true && (
            <motion.div
              className="w-full h-full absolute z-50 backdrop-blur-sm"
              initial={{ opacity: 0, x: 500 }}
              transition={{ duration: 0.3 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 500 }}
              onClick={closeSidebar}
            >
              <ModelsSidebar
                model={"feedback"}
                show={show}
                setShow={setShow}
                id={{ id: id, feedbackId: feedbackId }}
                serviceId={feedServiceId || id}
                dashboard={dashboard}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default ServiceDetailsFeedbackModel;
