import React from "react";
import Image from "../../../assets/images/model_Image.svg";
import UserImage from "../../../assets/images/TeamUser.svg";
import {
  getFeedBackDetails,
  selectPersonalDashboard,
  getFeedBackDetailsCompany,
  getcompanyFeedBackDetails,
} from "../../../redux/slices/personalDashboardSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCompanyDashboard } from "../../../redux/slices/companyDashboardSlice";

import { getInitials } from '../../../shared/lib/getInitials';
import { getRandomHex } from '../../../shared/lib/getRandomHex';

import negativeIcon from "../../../assets/images/negative.png";
import negativeHoverIcon from "../../../assets/images/nagative_hover.png";
import neutralIcon from "../../../assets/images/neutral.png";
import neutralHoverIcon from "../../../assets/images/neutral_hover.png";
import smileyIcon from "../../../assets/images/smiley.png";
import smileyHoverIcon from "../../../assets/images/smiley_hover.png";

function ServiceFeedbackInfoModel({
  company,
  setstate,
  setPopup,
  rating = 3,
  model = false,
  show,
  setShow,
  id,
  serviceId,
  dashboard,
}) {
  const dispatch = useDispatch();
  const personalState = useSelector(selectPersonalDashboard);
  const { feedbacksDetailsData } = personalState;
  const [hexColor, hexWithOpacity] = getRandomHex()
  useEffect(() => {
    if (dashboard == "companyDahboard") {
      dispatch(getFeedBackDetailsCompany(id.id, id.feedbackId));
    } else if (dashboard == "personalDashboard") {
      dispatch(getFeedBackDetailsCompany(id.id, id.feedbackId));
    } else if (dashboard == "companyDahboardfeedback") {
      dispatch(getcompanyFeedBackDetails(id.id, id.feedbackId));
    } else {
      dispatch(getFeedBackDetails(id.serviceId, id.feedbackId));
    }
  }, []);

  // useEffect(() => {
  //     dispatch(getFeedBackDetails(id, serviceId));
  // }, [id, serviceId]);

  return (
    <div
      className={`w-[340px] ${
        model ? "" : "h-[360px] md:h-[316px]"
      } md:w-[514px] px-[24px] pt-[24px] pb-[16px] flex flex-col gap-[16px] bg-[#363D50] rounded-[16px]`}
    >
      <div className="flex justify-between">
        <div className="flex flex-start items-center gap-[16px]">
          <img
            className="w-[40px] h-[40px] rounded-lg"
            src={feedbacksDetailsData?.feedback?.service_image_url}
            alt="service-img"
          />
          <div className="flex flex-col">
            <span className="text-primary !text-left">
              {feedbacksDetailsData?.feedback?.service_name}
            </span>
            <span className="text-secondary !text-left">
              {feedbacksDetailsData?.feedback?.service_category}
            </span>
          </div>
        </div>
        {model ? (
          <div className="flex gap-[16px]">
            <div className="flex h-[40px] justify-end items-center">
              <svg
                onClick={() => {
                  setShow(!show);
                }}
                className="cursor-pointer"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5001 3.08984L15.0001 7.58984V3.99984H13.0001V10.9998H20.0001V8.99984H16.4101L20.9101 4.49984L19.5001 3.08984ZM4.00009 12.9998V14.9998H7.59009L3.09009 19.4998L4.50009 20.9098L9.00009 16.4098V19.9998H11.0001V12.9998H4.00009Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        ) : (
          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>

      {model && (
        <div className="w-full flex flex-col pb-[8px]">
          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Given by </span>
            </div>
            <div className="flex gap-[4px] items-center">              
              <div
                style={{
                  backgroundColor: hexWithOpacity,
                  color: hexColor,
                }}
                className="w-[25px] h-[24px] rounded-full flex items-center justify-center !text-[14px] !font-semibold"
              >
                {getInitials(feedbacksDetailsData?.feedback?.given_by)}
              </div>
              <div>
                <span className="text-primary !text-left !font-normal !text-[14px]">
                  {feedbacksDetailsData?.feedback?.given_by}
                </span>
              </div>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Feedback type
              </span>
            </div>
            <div>
              {feedbacksDetailsData?.feedback?.feedbacks_type ===
                "negative" && (
                <div className="flex gap-[4px] items-center">
                  <div className="flex w-[24px] h-[24px] justify-center items-center">
                    <img
                      src={negativeHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>
                  <span className="text-primary !text-left !font-normal !text-[14px]">
                    Bad
                  </span>
                </div>
              )}

              {feedbacksDetailsData?.feedback?.feedbacks_type === "general" && (
                <div className="flex gap-[4px] items-center">
                  <div className="flex w-[24px] h-[24px] justify-center items-center">
                    <img
                      src={neutralHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>
                  <span className="text-primary !text-left !font-normal !text-[14px]">
                    Neutral
                  </span>
                </div>
              )}

              {feedbacksDetailsData?.feedback?.feedbacks_type ===
                "positive" && (
                <div className="flex gap-[4px] items-center">
                  <div className="flex w-[24px] h-[24px] justify-center items-center">
                    <img
                      src={smileyHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  </div>
                  <span className="text-primary !text-left !font-normal !text-[14px]">
                    Good
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Submitted</span>
            </div>
            <div className="flex gap-[4px]">
              <span className="text-primary !text-left !font-normal !text-[14px]">
                {feedbacksDetailsData?.feedback?.submitted}
              </span>
            </div>
          </div>
        </div>
      )}

      {model && <div className="w-full h-[2px] bg-[#434A5D]" />}

      <div className="w-[340px] md:w-[466px] md:h-[214px] flex flex-col gap-[24px]">
        <div className="w-[280px] md:w-[466px] h-[136px] flex flex-start overflow-y-scroll scrollbar-hide">
          <span className="text-primary !font-normal !text-[20px] !leading-[34px] !text-left">
            {feedbacksDetailsData?.feedback?.feedback_detail}
          </span>
        </div>

        {!model && (
          <div className="w-[280px] md:w-[466px] md:h-[54px] flex flex-col gap-[12px]">
            <div className="w-[280px] md:w-[466px] h-[2px] bg-[#434A5D]" />
            <div className="flex flex-col md:flex-row md:justify-between md:items-center h-[40px]">
              <div className="flex flex-start gap-[10px]">
                <img
                  className="w-[40px] h-[40px] rounded-lg"
                  src={
                    feedbacksDetailsData?.feedback?.image_url
                      ? feedbacksDetailsData?.feedback?.image_url
                      : UserImage
                  }
                  alt="service-img"
                />
                <div className="flex flex-col">
                  <span className="text-secondary !text-[#FFFFFF] text-left">
                    {feedbacksDetailsData?.feedback?.given_by}
                  </span>
                  <span className="text-secondary !text-left">
                    {feedbacksDetailsData?.feedback?.distinction}
                  </span>
                </div>
              </div>

              <div className="flex justify-end md:justify-start items-center gap-[16px] sdsh">
                <div>
                  <span>{feedbacksDetailsData?.feedback?.submitted}</span>
                </div>
                <div className="flex w-[24px] h-[24px] justify-center items-center">
                  {feedbacksDetailsData?.feedback?.feedbacks_type ===
                    "negative" && (
                    <img
                      src={negativeHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}

                  {feedbacksDetailsData?.feedback?.feedbacks_type ===
                    "general" && (
                    <img
                      src={neutralHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}

                  {feedbacksDetailsData?.feedback?.feedbacks_type ===
                    "positive" && (
                    <img
                      src={smileyHoverIcon}
                      alt="rating-img"
                      className="rating_icon active"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ServiceFeedbackInfoModel;
