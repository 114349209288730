import { useSelector } from "react-redux";
import { selectUser } from "../redux/slices/userSlice";

const useProfile = () => {
    const State = useSelector(selectUser);
    const {userDetails} = State
    return {
        id: userDetails?.user?.id,
        name: userDetails?.user?.name,
        age: userDetails?.user?.age,
        dob: userDetails?.user?.dob,
        city: userDetails?.user?.city,
        state: userDetails?.user?.state,
        phoneNo: userDetails?.user?.phoneNo,
        address: userDetails?.user?.address,
        role: userDetails?.user?.role,
        is_evaluator: true,
        is_tasker: true,
        company_name: userDetails?.user?.company_name,
    }
};
export default useProfile;

   // id: 1,
   //  firstName: "Kashif",
   //  lastName: "Rajput",
   //  age: 24,
   //  dob: '14 July 1998',
   //  city: 'Lahore',
   //  state: 'punjab',
   //  phoneNo: '+92 330 1234567',
   //  address: 'Street no 1, xyz, Pakistan',
   //  designation: "manager",
   //  is_evaluator: true,
   //  is_tasker: true,
   //  company_name: 'Alpha Corp'