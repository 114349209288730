import React, { useEffect } from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import TextField from "../../../../TextField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { selectPersonalDashboard, updateSubmissionData } from "../../../../../redux/slices/personalDashboardSlice";

function Second() {
  const dispatch = useDispatch();
  const {updateSubmissionsData} = useSelector(selectPersonalDashboard);

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };
  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start">
        <span className="text-primary text-left !text-[18px] !leading-[36px]">
          Is this service currently expensed for work?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
        <RadioButton
          checked={updateSubmissionsData?.expense_type === 'expensed_by_me'}
          onChange={() => handleInput('expense_type', 'expensed_by_me')}
        />
          <div className="text-left">
            <span className="text-secondary !text-[18px]">
            Yes - this service is expensed by me.
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
        <RadioButton
          checked={updateSubmissionsData?.expense_type === 'expensed_by_other'}
          onChange={() => handleInput('expense_type', 'expensed_by_other')}
        />
          <div className="flex flex-col text-left">
              <span className="text-secondary !text-[18px] mb-[4px]">
              Yes - this service is expensed by someone else.
              </span>
              {updateSubmissionsData?.expense_type === 'expensed_by_other' && (
                <TextField
                  placeholder={'Enter person, group, or team name'}
                  value={updateSubmissionsData?.expensed_by_name}
                  onChange={(e) => handleInput('expensed_by_name', e.target.value)}
                />
              )}
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
        <RadioButton
          checked={updateSubmissionsData?.expense_type === 'not_expensed'}
          onChange={() => handleInput('expense_type', 'not_expensed')}
        />
          <div className="text-left">
            <span className="text-secondary !text-[18px]">
            No - This service isn’t expensed
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
        <RadioButton
          checked={updateSubmissionsData?.expense_type === 'no_idea'}
          onChange={() => handleInput('expense_type', 'no_idea')}
        />
          <div className="text-left">
            <span className="text-secondary !text-[18px]">
            I don’t know
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Second;
