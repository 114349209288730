import React from 'react'
import ServiceFeedbackInfoModel from '../models/service-feedback-info-model/ServiceFeedbackInfoModel';
import ServiceRequestInfoModel from '../models/service-request-info-model/ServiceRequestInfoModel';
import ServiceSubmissionInfoModel from '../models/service-submission-info-model/service-submission-info-model';
import SubmittedServicesInfoModel from '../models/submitted-services-info-model/submitted-services-info-model';

function ModelsSidebar({ model, show, setShow, id, serviceId, dashboard, setPopup }) {
    return (
        <div onClick={(e)=>{e.stopPropagation()}} className={`shadow-2xl flex justify-end h-[80.5vh] h-full fixed right-0 bg-[#363D50] overflow-y-scroll scrollbar-hide ${show ? " max-w-[340px] md:max-w-[591px] slide-in" : "max-ml-[-340px] xl:max-ml-[-591px] slide-out hidden"}`}>
            {model === 'request' && <ServiceRequestInfoModel show={show} setShow={setShow} model={true} id={id} dashboard={dashboard} />}
            {model === 'feedback' && <ServiceFeedbackInfoModel company={true} show={show} setShow={setShow} model={true} id={id} serviceId={serviceId} dashboard={dashboard} />}
            {model === 'submission' && <ServiceSubmissionInfoModel show={show} setShow={setShow} model={true} id={id} />}
            {model === 'submitted_services' && <SubmittedServicesInfoModel show={show} setShow={setShow} model={true} id={id} setPopup={setPopup}/>}
        </div>
    )
}

export default ModelsSidebar