import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux'
import Button from "../../Buttons/button";
import { useNavigate } from 'react-router-dom';
import { selectCompanyDashboard, deleteService, deactivateService, setUserMenuUpdatedFalse } from "../../../redux/slices/companyDashboardSlice";
import BasicModal from "../../models/models";

const EmployeeMenuModel = ({ setstate, setPopup, setModel, userRole, empID, empReport }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [popup, setPopup] = useState(false);
  // const [id, setId] = useState("")
  // const [state, setState] = useState(0);

  const handleChangeEmpRole = () => {
    setstate(46)
    setPopup(true)
    dispatch(setUserMenuUpdatedFalse())
  }

  const handleViewEmpReport = (empID) => {
     navigate(`/company_dashboard/employees/employee_report/${empID}`);
     setModel(false)
     // setPopup(false)
  }

  const handleEmpReport = () => {
    setstate(48)
    setPopup(true)
    dispatch(setUserMenuUpdatedFalse())
  }

  const handleRemoveEmployee = () => {
    setstate(47)
    setPopup(true)
    dispatch(setUserMenuUpdatedFalse());
  }

  return (
    <div className="flex flex-col w-[320px] rounded-[24px] bg-[#363D50] popup_body">
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleChangeEmpRole()}
                  secondaryHover={true}
                >
                  Change employee role
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleEmpReport()}
                  secondaryHover={true}
                >
                  {empReport ? 'Deactivate employee' : 'Activate employee'}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleViewEmpReport(empID)}
                  secondaryHover={true}
                >
                  View employee report
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] popup_details">
        <div className="service_heading">
          <Button
            onClick={() => handleRemoveEmployee()}
            secondaryHover={true}
          >
            Remove
          </Button>
        </div>
      </div>
      {/* {popup && ( */}
      {/*   <BasicModal */}
      {/*     state={state} */}
      {/*     popup={popup} */}
      {/*     setPopup={setPopup} */}
      {/*     setState={setState} */}
      {/*     userRole={userRole} */}
      {/*     empID={empID} */}
      {/*     empReport={empReport} */}
      {/*   /> */}
      {/* )} */}
    </div>
  );
};

export default EmployeeMenuModel;
