

export const heading = [
    "Service",
    "Type",
    "Category",
    "Expensed",
    "Website",
    "Date"
]

export const tableData = [
    {
        name: "Tool Title",
        type: "Missing",
        category: "Software",
        expensed: "true",
        website: "#",
        date: "03/11/2023"
    },
    
    {
        name: "Tool Title",
        type: "New",
        category: "Software",
        expensed: "false",
        website: "#",
        date: "03/11/2023"
    },
    {
        name: "Tool Title",
        type: "Missing",
        category: "Software",
        expensed: "true",
        website: "#",
        date: "03/11/2023"
    },
    
]