import responsivGif from '../../../assets/images/responsive-dialogue.gif';

const NonDesktopDeviceWarning = () => {
  return <div 
    className="bg-[#01042180] absolute z-10 top-0 left-0 bottom-0 right-0 flex justify-center items-center"
  >  
  <div
    style={{
      width: 'calc(100vw - 40px)',
      height: 'calc(100vh - 80px)'
    }}
    className="py-[40px] px-[24px] rounded-[24px] bg-[#363d50] flex items-center justify-center">
    <div>
        <div className="flex justify-center mb-[24px]">
        <img className="w-[268px] rounded-[16px]" src={responsivGif} alt="resize" />
      </div>
      <div>
        <h2 className="font-bold text-[18px] leading-[18px] text-center tracking-wider mb-[8px]">See the bigger picture</h2>
        <p className="text-[14px] leading-5 text-center text-[#A5AEBC] max-w-[400px]">
          Thryft is not yet optimized for small or mobile sized screens. For the best browsing experience,
          switch to a wider browser window on a laptop or tablet device.
        </p>
      </div>
    </div>
  </div>
</div>;
};

export { NonDesktopDeviceWarning };
