import React from 'react'

const OrgSetting = () => {

  return (
    <div className='flex flex-col items-baseline'>
      <h2 className='text-2xl font-bold mb-6'>
      Org setting
      </h2>
      <div className='flex flex-col items-baseline '>
      <span className='font-bold text-base'>
      Total cost visibility
      </span>
      <p className='text-[#A5AEBC]'>Determine what cost totals are visible to org employees when they access reports from their personal dashboards.</p>
      </div>

      <div>
        <div className='flex flex-col'>
          <div className='flex flex-row h-8  mt-6'>
          <input type='checkbox'/>
          <div className='flex flex-col items-baseline justify-center'>
            <span>Employee reports</span>
            <p>
            The total cost off all services that your org pays for an employee
            </p>
          </div>
          </div>
          <div className='flex flex-row h-8  mt-6'>
          <input type='checkbox'/>
          <div className='flex flex-col items-baseline justify-center'>
            <span>Employee reports</span>
            <p>
            The total cost off all services that your org pays for an employee
            </p>
          </div>
          </div>
        
        </div>

      </div>
      </div>
  )
}

export default OrgSetting