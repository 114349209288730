import React, { useState } from 'react'
import BasicModal from '../../../components/models/models';
import Header from './components/header';
import NegativeFeedbackCard from './components/negative-feedback-card';
import PillCards from './components/pill-cards';
import RequestAndUserCostCards from './components/request-and-user-cost-cards';


function TaskerHomeModule({show , setShow, path}) {
    const [popup, setPopup] = useState(false);
    const [state, setState] = useState(0);

    return (
        <div className='relative w-[100%] flex flex-col px-[20px] lg:px-[32px] pt-[16px]'>

            <Header show={show} setShow={setShow} path={path}/>

            <div className='flex flex-col gap-[40px] h-[100vh] lg:h-[530px] py-[20px] overflow-y-scroll scrollbar-hide'>

                <PillCards setPopup={setPopup} setState={setState} />

                <RequestAndUserCostCards setPopup={setPopup} setState={setState} />

                <NegativeFeedbackCard setPopup={setPopup} setState={setState} />

            </div>

            {setPopup && (
                <BasicModal
                    state={state}
                    popup={popup}
                    setPopup={setPopup}
                    setState={setState}
                />
            )}
        </div>
    )
}

export default TaskerHomeModule