import React, { useEffect, useState } from "react";
import Button from "../Buttons/button";
import BasicModal from "../models/models";
import { useDispatch } from "react-redux";
import { updateSubmissionData } from "../../redux/slices/personalDashboardSlice";

function Header({
  setShow,
  show,
  btns,
  title,
  subHeading,
  line = false,
  fullname,
  setFullName,
}) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [serviceType, setServiceType] = useState("");

  const dispatch = useDispatch();

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  return (
    <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[16px] pt-[30px]">
      <div className="flex flex-col gap-[24px] lg:flex-row lg:justify-between">
        <div className="flex gap-[10px]">
          <div
            onClick={() => {
              setShow(!show);
            }}
            className="xl:hidden 2xl:hidden"
          >
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 18C7 18.5523 7.44772 19 8 19H20C20.5523 19 21 18.5523 21 18C21 17.4477 20.5523 17 20 17H8C7.44772 17 7 17.4477 7 18ZM7 12C7 12.5523 7.44772 13 8 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H8C7.44772 11 7 11.4477 7 12ZM8 5C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H8Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex flex-col">
            <span className="text-primary !text-[30px] !leading-[30px] !tracking-[0.3px] lg:!text-[40px] lg:!leading-[40px] xl:!text-[32px] xl:!leading-[48px]">
              {title}
            </span>
          </div>
        </div>
        {btns && (
          <div className="flex flex-col gap-[20px] justify-between items-center md:flex-row">
            <Button
              onClick={() => {
                setPopup(true);
                setState(3);
                handleInput("type", "MissingSubmission");
                setServiceType("missingService");
              }}
              secondary={true}
              hoverColor={true}
              fill={false}
              position={"center"}
              className=" btnHover !w-[100%] lg:!w-[fit-content]"
            >
              Report missing service
            </Button>
            <Button
              onClick={() => {
                setPopup(true);
                setState(1);
                handleInput("type", "NewSubmission");
                setServiceType("newService");
              }}
              hoverColor={true}
              fill={true}
              position={"center"}
              onMouseEnter={() => {
                setToggle(!toggle);
              }}
              onMouseLeave={() => {
                setToggle(toggle && !toggle);
              }}
              className=" btnHover !w-[100%] lg:!w-[fit-content]"
            >
              Suggest new service
            </Button>
          </div>
        )}
      </div>

      {/* {line && <div className="w-full h-[1px] bg-[#41485C]" />} */}
      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          serviceType={serviceType} // or missingService
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </header>
  );
}

export default Header;
