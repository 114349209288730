export const heading = [
    "Service",
    "Type",
    "Category",
    "Expensed",
    "Website",
    "Submission date"
]

export const tableData = [
    {
        name: "Printing Paper",
        type: "New",
        category: "Office Supplies",
        expensed: true,
        website: "",
        date: "03/11/2023"
    },
    {
        name: "Printing Paper",
        type: "New",
        category: "Office Supplies",
        expensed: true,
        website: "",
        date: "03/11/2023"
    },
    {
        name: "Printing Paper",
        type: "New",
        category: "Office Supplies",
        expensed: true,
        website: "",
        date: "03/11/2023"
    },
    {
        name: "Printing Paper",
        type: "New",
        category: "Office Supplies",
        expensed: true,
        website: "",
        date: "03/11/2023"
    },
    {
        name: "Printing Paper",
        type: "New",
        category: "Office Supplies",
        expensed: true,
        website: "",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
    {
        name: "Sales Force",
        type: "Missing",
        category: "CRM Software",
        expensed: false,
        website: "http://localhost:3000",
        date: "03/11/2023"
    },
]