import { useEffect, useRef } from 'react';


const UseScrollToTop = ({ defaultValue = null, timer = 100 }) => {
  const targetRef = useRef(defaultValue);

  useEffect(() => {
    let ref = -1
    if (targetRef.current) {
      ref = setTimeout(() => {
        targetRef.current.scrollIntoView({ block: "start", behavior: 'auto'});
      }, timer)
    }

    return () => {
      clearInterval(ref);
    }
  }, [timer])

  return [targetRef]
};

export { UseScrollToTop };
