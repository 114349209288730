import Image from "../assets/images/TeamUser.svg";

export const heading = [
    "Request Type",
    "Status",
    "Reason",
    "Comments",
    "Requested by",
    "Request date"
]

export const tableData = [
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },

    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
    {
        requestType: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comments: 1,
        requestedBy: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        requestDate: '03/11/2023'
    },
]