import React from "react";
import Button from "../../../../components/Buttons/button";
import { services } from "../../../../services/servicesData";

function PillCards({ setPopup, setState }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 justify-center xl:justify-between items-center gap-[32px]">
      <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] bg-[#363D50] rounded-[32px]">
        <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
          <span className="text-primary !text-[18px]">$808.65</span>
          <span className="text-secondary !leading-[21px]">
            Monthly service cost
          </span>
        </div>
        <Button
          onClick={() => {
            setPopup(true);
            setState(11);
          }}
          secondary={true}
          hoverColor={true}
        >
          History
        </Button>
      </div>

      <div className="flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]">
        <span className="text-primary !text=[18px]">$808.65</span>
        <span className="text-secondary !leading-[21px]">
          User cost average
        </span>
      </div>

      <div className="flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]">
        <div className="flex w-[273px] h-[24px] gap-[16px]">
          <div className="flex gap-[6px] items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1667 3.5C7.3802 3.5 3.5 7.3802 3.5 12.1667C3.5 16.9531 7.3802 20.8333 12.1667 20.8333C16.9531 20.8333 20.8333 16.9531 20.8333 12.1667C20.8333 7.3802 16.9531 3.5 12.1667 3.5ZM2.5 12.1667C2.5 6.82791 6.82791 2.5 12.1667 2.5C17.5054 2.5 21.8333 6.82791 21.8333 12.1667C21.8333 17.5054 17.5054 21.8333 12.1667 21.8333C6.82791 21.8333 2.5 17.5054 2.5 12.1667ZM8.5 8.87085C8.77614 8.87085 9 9.09471 9 9.37085V9.46252C9 9.73866 8.77614 9.96252 8.5 9.96252C8.22386 9.96252 8 9.73866 8 9.46252V9.37085C8 9.09471 8.22386 8.87085 8.5 8.87085ZM15.8333 8.87085C16.1095 8.87085 16.3333 9.09471 16.3333 9.37085V9.46252C16.3333 9.73866 16.1095 9.96252 15.8333 9.96252C15.5572 9.96252 15.3333 9.73866 15.3333 9.46252V9.37085C15.3333 9.09471 15.5572 8.87085 15.8333 8.87085ZM8.97434 13.8419C8.88702 13.5799 8.60386 13.4383 8.34189 13.5257C8.07991 13.613 7.93833 13.8961 8.02566 14.1581C8.53003 15.6712 9.86333 17.25 12.1667 17.25C14.47 17.25 15.8033 15.6712 16.3077 14.1581C16.395 13.8961 16.2534 13.613 15.9914 13.5257C15.7295 13.4383 15.4463 13.5799 15.359 13.8419C14.9467 15.0788 13.915 16.25 12.1667 16.25C10.4183 16.25 9.38664 15.0788 8.97434 13.8419Z"
                fill={`${services[0]["rating"] === 3 ? "#6BE261" : "#A5AEBC"}`}
              />
            </svg>
            <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
              63
            </span>
          </div>

          <div className="flex gap-[6px] items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 12.1667C3.5 7.3802 7.3802 3.5 12.1667 3.5C16.9531 3.5 20.8333 7.3802 20.8333 12.1667C20.8333 16.9531 16.9531 20.8333 12.1667 20.8333C7.3802 20.8333 3.5 16.9531 3.5 12.1667ZM12.1667 2.5C6.82791 2.5 2.5 6.82791 2.5 12.1667C2.5 17.5054 6.82791 21.8333 12.1667 21.8333C17.5054 21.8333 21.8333 17.5054 21.8333 12.1667C21.8333 6.82791 17.5054 2.5 12.1667 2.5ZM9 9.37085C9 9.09471 8.77614 8.87085 8.5 8.87085C8.22386 8.87085 8 9.09471 8 9.37085V9.46252C8 9.73866 8.22386 9.96252 8.5 9.96252C8.77614 9.96252 9 9.73866 9 9.46252V9.37085ZM16.3333 9.37085C16.3333 9.09471 16.1095 8.87085 15.8333 8.87085C15.5572 8.87085 15.3333 9.09471 15.3333 9.37085V9.46252C15.3333 9.73866 15.5572 9.96252 15.8333 9.96252C16.1095 9.96252 16.3333 9.73866 16.3333 9.46252V9.37085ZM8.5 13.5C8.22386 13.5 8 13.7239 8 14C8 14.2762 8.22386 14.5 8.5 14.5H15.8333C16.1095 14.5 16.3333 14.2762 16.3333 14C16.3333 13.7239 16.1095 13.5 15.8333 13.5H8.5Z"
                fill={`${services[0]["rating"] === 2 ? "#FFE766" : "#A5AEBC"}`}
              />
            </svg>
            <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
              23
            </span>
          </div>

          <div className="flex gap-[6px] items-center">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1667 3.5C7.3802 3.5 3.5 7.3802 3.5 12.1667C3.5 16.9531 7.3802 20.8333 12.1667 20.8333C16.9531 20.8333 20.8333 16.9531 20.8333 12.1667C20.8333 7.3802 16.9531 3.5 12.1667 3.5ZM2.5 12.1667C2.5 6.82791 6.82791 2.5 12.1667 2.5C17.5054 2.5 21.8333 6.82791 21.8333 12.1667C21.8333 17.5054 17.5054 21.8333 12.1667 21.8333C6.82791 21.8333 2.5 17.5054 2.5 12.1667ZM8.5 8.87085C8.77614 8.87085 9 9.09471 9 9.37085V9.46252C9 9.73866 8.77614 9.96252 8.5 9.96252C8.22386 9.96252 8 9.73866 8 9.46252V9.37085C8 9.09471 8.22386 8.87085 8.5 8.87085ZM15.8333 8.87085C16.1095 8.87085 16.3333 9.09471 16.3333 9.37085V9.46252C16.3333 9.73866 16.1095 9.96252 15.8333 9.96252C15.5572 9.96252 15.3333 9.73866 15.3333 9.46252V9.37085C15.3333 9.09471 15.5572 8.87085 15.8333 8.87085ZM12.1667 12.5834C9.86333 12.5834 8.53003 14.1622 8.02566 15.6753C7.93833 15.9372 8.07991 16.2204 8.34189 16.3077C8.60386 16.395 8.88702 16.2535 8.97434 15.9915C9.38664 14.7546 10.4183 13.5834 12.1667 13.5834C13.915 13.5834 14.9467 14.7546 15.359 15.9915C15.4463 16.2535 15.7295 16.395 15.9914 16.3077C16.2534 16.2204 16.395 15.9372 16.3077 15.6753C15.8033 14.1622 14.47 12.5834 12.1667 12.5834Z"
                fill={`${services[0]["rating"] === 1 ? "#ED4650" : "#A5AEBC"}`}
              />
            </svg>
            <span className="text-secondary !text-[#A5AEBC] !leading-[21px]">
              36
            </span>
          </div>
        </div>
        <span className="text-secondary !leading-[21px]">Impact rating</span>
      </div>

      <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
        <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
          <span className="text-primary !text-[18px]">92</span>
          <span className="text-secondary !leading-[21px]">Service users</span>
        </div>
        <Button
          onClick={() => {
            setPopup(true);
            setState(19);
          }}
          secondary
          hoverColor
        >
          View
        </Button>
      </div>

      <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
        <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
          <span className="text-primary !text-[18px]">12</span>
          <span className="text-secondary !leading-[21px]">Requests</span>
        </div>
        <Button
          onClick={() => {
            setPopup(true);
            setState(20);
          }}
          secondary={true}
          hoverColor={true}
        >
          View
        </Button>
      </div>

      <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
        <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
          <span className="text-primary !text-[18px]">26</span>
          <span className="text-secondary !leading-[21px]">Feedback</span>
        </div>
        <Button
          onClick={() => {
            setPopup(true);
            setState(21);
          }}
          secondary={true}
          hoverColor={true}
        >
          View
        </Button>
      </div>
    </div>
  );
}

export default PillCards;
