import React from 'react'
import { formatedAmount } from "../../../../../utils/helpers";

function PillCards({data}) {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 justify-center xl:justify-between items-center gap-[40px]'>
      <div className='max-w-[100%] p-[24px] bg-[#363D50] rounded-[32px]'>
        <span className='text-primary !text-[18px] block'>${formatedAmount(data.average_service_cost)}</span>
        <span className='text-secondary !leading-[21px] mt-[6px]'>Average service cost</span>
      </div>
      <div className='max-w-[100%] p-[24px] bg-[#363D50] rounded-[32px]'>
        <span className='text-primary !text-[18px] block'>${formatedAmount(data.average_employee_cost)}</span>
        <span className='text-secondary !leading-[21px] mt-[6px]'>Average employee cost</span>
      </div>
    </div>
  )
}


export default PillCards