import React from "react";
import Button from "../../../../components/Buttons/button";
import { formatedAmount } from "../../../../utils/helpers";
import cn from "classnames";
import {useDispatch, useSelector} from 'react-redux'

function PillCards({
  setPopup,
  setState,
  userId,
  totalTeamCost,
  averageEmployeeCost,
  totalSubmissions,
  totalFeedbacks,
  totalRequests,
  setDashboard
}) {

  return (
    <div className="gap-[32px]">
      <div className="flex flex-wrap gap-[32px]">
        <div className="flex gap-[12px] w-full max-w-[100%] h-[100px] sm:max-w-[calc(50%-16px)] p-[24px] rounded-[32px] bg-[#363D50]">
          <div className="flex justify-center flex-col gap-[6px] w-[203px] h-[51px]">
            <span className="text-primary !text-[18px]">{`$${formatedAmount(
              totalTeamCost
            )}`}</span>
            <span className="text-secondary !leading-[21px]">
              {" "}
              Total team cost
            </span>
          </div>
        </div>

        <div className="flex justify-center flex-col w-full max-w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] sm:max-w-[calc(50%-16px)] rounded-[32px] bg-[#363D50]">
          <span className="text-primary !text-[18px]">{`$${formatedAmount(
            averageEmployeeCost
          )}`}</span>
          <span className="text-secondary !leading-[21px]">
            {" "}
            Average employee cost
          </span>
        </div>
      </div>

      <div className='flex pt-[42px] gap-[32px]'>
        <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]'>
          <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
            <span className='text-primary !text-[18px]'>{totalRequests.length}</span>
            <span className='text-secondary !leading-[21px]'>Requests</span>
          </div>
          <Button
            className={cn({
              'invisible': !totalRequests.length
            })}
            onClick={() => {
              setPopup(true);
              setState(15);
              setDashboard("personalDashboard")
            }}
            secondary
            hoverColor
          >
            View
          </Button>
        </div>

        <div className="flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]">
          <div className="flex flex-col gap-[6px] w-[203px] h-[51px]">
            <span className="text-primary !text-[18px]">
              {totalFeedbacks.length}
            </span>
            <span className="text-secondary !leading-[21px]">Feedback</span>
          </div>
          <Button
            className={cn({
              'invisible': !totalFeedbacks.length
            })}
            onClick={() => {
              setPopup(true);
              setState(16);
              setDashboard("personalDashboard");
            }}
            secondary
            hoverColor
          >
            View
          </Button>
        </div>

        <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]'>
          <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
            <span className='text-primary !text-[18px]'>{totalSubmissions.length}</span>
            <span className='text-secondary !leading-[21px]'>Submissions</span>
          </div>
          <Button
            className={cn({
              'invisible': !totalSubmissions.length
            })}
            onClick={() => {
              setPopup(true);
              setState(17);
              setDashboard("personalDashboard");
            }}
            secondary
            hoverColor
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PillCards;
