import {createSlice} from '@reduxjs/toolkit'
import { getRequest, postRequest, putRequest } from '../../config/apiRequests';

export const userSlice = createSlice({
    name: "user",
    initialState: {
        isLoading: false,
        success: false,
        message: "",
        endPoint: "",
        profile: {},
        passwordReset: {},
        userDetails: {}
    },

    reducers: {
        loginUpdate: (state, action) => {
            if(action.payload.status === 200) {
                state.success = true
                state.endPoint = 'auth/users/login'
                state.message = 'Logged In Successfully'
                state.profile = { ...action.payload.data.user };
                state.isLoading = false
            }else{
                state.success = false
                state.endPoint = 'auth/users/login'
                const keys = Object.keys(action.payload?.data?.errors)
                state.message = action.payload?.data?.errors[keys[0]][0]
                state.isLoading = false
            }
        },

        signUpUpdate: (state, action) => {
            if(action.payload.status === 200) {
                state.success = true
                state.endPoint = 'auth/users'
                state.message = 'Account Created Successfully'
                state.profile = { ...action.payload.data.user };
                state.isLoading = false
            }else{
                state.success = false
                state.endPoint = 'auth/users'
                const keys = Object.keys(action.payload?.data?.errors)
                state.message = action.payload?.data?.errors[keys[0]][0]
                state.isLoading = false
            }
        },

        resetPasswordUpdate: (state, action) => {
            if(action.payload.status === 201) {
                state.success = true
                state.endPoint = 'auth/users/password'
                state.message = 'Check your email for instructions'
                state.isLoading = false
            }else{
                state.success = false
                state.endPoint = 'auth/users/password'
                const keys = Object.keys(action.payload?.data?.errors)
                state.message = action.payload?.data?.errors[keys[0]][0]
                state.isLoading = false
            }
        },
        updatePassword: (state, action) => {
            if(action.payload.status === 204) {
              state.success = true
              state.endPoint = 'auth/users/password'
              state.message = 'Password updated successfully'
              state.isLoading = false
            }else{
              state.success = false
              state.endPoint = 'auth/users/password'
              const keys = Object.keys(action.payload?.data?.errors)
              state.message = action.payload?.data?.errors[keys[0]][0]
              state.isLoading = false
            }
          },
          getUserDetailsUpdate: (state, action) => {
            if(action.payload) {
                state.userDetails = action.payload;
            } else {
                state.message = action.payload
                state.isLoading = false
            }
        },
        
      
          

        setLoading: (state, action) => {
            state.isLoading = action.payload
        }
    }
});

export const {
    loginUpdate,
    signUpUpdate,
    resetPasswordUpdate,
    updatePassword,
    setLoading,
    updateServices,
    updateEmployees,
    updateHistory,
    getUserDetailsUpdate
} = userSlice.actions;

export const selectUser = (state) => state.user;

export const signUpRequest = (body) => (dispatch) => {
    postRequest('auth/users', body, {
        "Content-Type": "application/json",
    }).then((res) => {
        dispatch(signUpUpdate(res))
    });
}

export const logInRequest = (body) => (dispatch) => {
    postRequest('auth/users/login', body, {
        "Content-Type": "application/json",
    }).then((res) => {
        dispatch(loginUpdate(res))
    });
}
export const getUserDetails = () => (dispatch) => {
    const token = localStorage.getItem('accessToken');
    getRequest(`api/v1/users/me`, {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }).then((res) => {
        dispatch(getUserDetailsUpdate(res));
    });
};


export const ResetPasswordRequest = (body) => (dispatch) => {
    postRequest('auth/users/password', body, {
        "Content-Type": "application/json",
    }).then((res) => {
        dispatch(resetPasswordUpdate(res))
    });
}
export const updatePasswordRequest = (body) => (dispatch) => {
    putRequest('auth/users/password', body, {
      "Content-Type": "application/json",
    }).then((res) => {
      dispatch(updatePassword(res))
    });
  }
 

export default userSlice.reducer;