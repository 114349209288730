import React from 'react'

function Second({setWords,Words}) {
  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-col gap-[4px]">
        <span className="text-primary text-left !text-[24px] !leading-[36px]">
          Tell us more about how:
        </span>
        <span className="text-primary text-left !leading-[36px]">
          ”I want to try this service”
        </span>
      </div>
      <div className="flex flex-col p-[2px] border-[1px] border-[#596073] rounded-[16px] md:w-[520px] md:h-[300px]">
        <textarea className='!h-[220px] w-full border-none focus:ring-0 rounded-[12px] bg-[#363D50]' onChange={(e) => {setWords(e.target.value)}} placeholder={'I’d like to opt-in because...'}/>
        <div className='flex flex-start pt-[17px] pb-[17px] pl-[10px]'>
        <span className='text-secondary text-left'>{Words?.length}/500</span>
        </div>
      </div>
    </div>
  )
}

export default Second