import React from "react";
import Breadcrumbs from "./breadcrumbs";
import Button from "../../../../components/Buttons/button";
import { getInitials } from "../../../../shared/lib/getInitials";
import { getRandomHex } from "../../../../shared/lib/getRandomHex";

const [hexColor, hexWithOpacity] = getRandomHex();
const textColor = hexColor;
const bgColor = hexWithOpacity;

function Header({ path, data }) {
  return (
    <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[16px]">
      {path && (
        <div className="w-[100%] flex pt-6">
          <Breadcrumbs path={path} />
        </div>
      )}
      <div className="w-[100%] flex flex-col gap-[12px]">
        <div className="flex flex-col md:flex-row md:justify-between pb-[8px] gap-[24px] md:items-center">
          <div className="flex flex-row justify-start gap-[24px] items-center">
            <div
              style={{
                backgroundColor: bgColor,
                color: textColor,
              }}
              className="w-[72px] h-[72px] flex justify-center items-center rounded-full text-[24px] font-bold"
            >
              {getInitials(data?.name)}
            </div>
            <div className="flex flex-col">
              <span className="text-primary !text-[24px] !leading-[36px]">
                {data?.name}
              </span>
              <span className="text-secondary !text-[14px] !leading-[21px]">
                {data?.distinction}
              </span>
            </div>
          </div>
          {/* <div className='flex justify-end'>
            <Button outlined={true} hoverColor={true} secondary={true} secondaryHoverOutlined={true}>
              Employee Details
            </Button>
           </div> */}
        </div>
        <div className="bg-[#41485C] w-[100%] h-[2px]" />
      </div>
    </header>
  );
}

export default Header;
