const getRandomHex = (opacity = 16) => {
  let currentOpacity = opacity;

  if (currentOpacity < 0) {
    currentOpacity = 1;
  }

  if (currentOpacity > 100) {
    currentOpacity = 100;
  }

  const colors = ['#2284FF', '#E9D689', '#6BE261', '#D6A1F2', '#5D61E2', '#98EBC4', '#7885EB'];

  const getRandomIndex = Math.floor(Math.random() * colors.length)

  const correction = currentOpacity % 7 === 1 && currentOpacity > 6 ? currentOpacity + 1 : currentOpacity
  const opacityDef = Math.round((correction - 1) * (255 - 0) / (100 - 1) + 0)

  let opacityToHex = opacityDef.toString(16)  

  if (currentOpacity <= 6) {
    opacityToHex = '0' + opacityToHex;
  }

  const hex = `${colors[getRandomIndex]}`
  const hexAlpha = hex + 27

  return [hex, hexAlpha]
}

export { getRandomHex }