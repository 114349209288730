import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Button from "../../../../../components/Buttons/button";
import { formatedAmount } from "../../../../../utils/helpers";
import { getSlicedCardData } from "../lib/getSlicedCardData";

function ServicesAndEmployeesCostCards({ data }) {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[32px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.4075 6.11719C17.762 6.82136 17.2444 7.71968 16.8999 8.66671H21.4113C21.9297 8.65425 22.3388 8.51816 22.6441 8.29535C22.9604 8.0645 23.2152 7.70448 23.3538 7.16693C23.6719 5.93322 22.8697 4.66671 21.3327 4.66671C20.2375 4.66671 19.2373 5.21195 18.4075 6.11719ZM15.1642 8.66671C14.7975 7.71459 14.2804 6.81668 13.6444 6.11427C12.8247 5.20891 11.8476 4.66671 10.7555 4.66671C9.21853 4.66671 8.41634 5.93322 8.73442 7.16693C8.87302 7.70448 9.12785 8.0645 9.44414 8.29535C9.74942 8.51816 10.1585 8.65425 10.6769 8.66671H15.1642ZM16.026 7.31082C16.3941 6.54637 16.8643 5.82743 17.4246 5.21622C18.4281 4.12147 19.7612 3.33337 21.3327 3.33337C23.7957 3.33337 25.1667 5.47579 24.6449 7.49981C24.5315 7.93969 24.3549 8.3321 24.1187 8.66881C25.9044 8.73167 27.3327 10.1991 27.3327 12V14.6667C27.3327 16.0336 26.51 17.2083 25.3327 17.7227V22.6667C25.3327 24.5077 23.8403 26 21.9993 26H9.99935C8.1584 26 6.66602 24.5077 6.66602 22.6667V17.7227C5.48875 17.2083 4.66602 16.0336 4.66602 14.6667V12C4.66602 10.1695 6.14154 8.68362 7.9681 8.66685C7.73259 8.33058 7.5565 7.93884 7.44331 7.49981C6.92147 5.47579 8.29254 3.33337 10.7555 3.33337C12.3302 3.33337 13.6416 4.12451 14.6328 5.21938C15.1866 5.83101 15.6528 6.5493 16.026 7.31082ZM10.6627 10H7.99935C6.89478 10 5.99935 10.8955 5.99935 12V14.6667C5.99935 15.7713 6.89478 16.6667 7.99935 16.6667H15.3327V10H10.7555V10.001C10.7245 10.001 10.6935 10.0007 10.6627 10ZM21.3327 10.001V10H16.666V16.6667H23.9993C25.1039 16.6667 25.9993 15.7713 25.9993 14.6667V12C25.9993 10.8955 25.1039 10 23.9993 10H21.4255C21.3947 10.0007 21.3637 10.001 21.3327 10.001ZM16.666 18H23.9993V22.6667C23.9993 23.7713 23.1039 24.6667 21.9993 24.6667H16.666V18ZM15.3327 18V24.6667H9.99935C8.89478 24.6667 7.99935 23.7713 7.99935 22.6667V18H15.3327Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Highest Cost Services
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Services with the highest price tag
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[16px]">
          {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

          <div className="flex flex-col w-[100%] h-[360px] py-[3px] px-[3px]">
            {getSlicedCardData(data?.high_cost_services)?.map((service) => (
              <Link
                key={service.id}
                to={`/company_dashboard/home/service_report/${service.id}`}
                className="flex justify-between items-center card-animate w-[100%] cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
              >
                <div className="flex gap-[16px] items-center">
                  <img
                    src={service?.image_url}
                    alt="company-logo"
                    className="w-[40px] h-[40px] rounded-[8px] card_img"
                  />
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                      {service?.name}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-left">
                      {service?.categories}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                    ${formatedAmount(service?.service_cost)}
                  </span>
                  <span className="text-secondary !leading-[20px] !text-right">
                    Cost per month
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"/company_dashboard/services"}>
            <Button
              className={cn({
                invisible: !data?.high_cost_services?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[32px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="33"
                height="32"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.1667 3.33333C14.0621 3.33333 13.1667 4.22876 13.1667 5.33333V5.33432C13.1672 6.43844 14.0624 7.33333 15.1667 7.33333H17.8333C18.9379 7.33333 19.8333 6.4379 19.8333 5.33333C19.8333 4.22876 18.9379 3.33333 17.8333 3.33333H15.1667ZM15.1667 2C13.554 2 12.2089 3.14517 11.9 4.66667H9.83333C8.94928 4.66667 8.10143 5.01786 7.47631 5.64298C6.85119 6.2681 6.5 7.11594 6.5 8V24C6.5 24.8841 6.85119 25.7319 7.47631 26.357C8.10143 26.9821 8.94928 27.3333 9.83333 27.3333H23.1667C24.0507 27.3333 24.8986 26.9821 25.5237 26.357C26.1488 25.7319 26.5 24.8841 26.5 24V8C26.5 7.11594 26.1488 6.2681 25.5237 5.64298C24.8986 5.01786 24.0507 4.66667 23.1667 4.66667H21.1C20.7911 3.14517 19.446 2 17.8333 2H15.1667ZM9.83333 6H11.9C12.2089 7.52149 13.554 8.66667 15.1667 8.66667H17.8333C19.446 8.66667 20.7911 7.52149 21.1 6H23.1667C23.6971 6 24.2058 6.21071 24.5809 6.58579C24.956 6.96086 25.1667 7.46957 25.1667 8V24C25.1667 24.5304 24.956 25.0391 24.5809 25.4142C24.2058 25.7893 23.6971 26 23.1667 26H9.83333C9.3029 26 8.79419 25.7893 8.41912 25.4142C8.04405 25.0391 7.83333 24.5304 7.83333 24V8C7.83333 7.46957 8.04405 6.96086 8.41912 6.58579C8.79419 6.21071 9.3029 6 9.83333 6ZM13.5 14.3333C13.5 12.6765 14.8431 11.3333 16.5 11.3333C18.1568 11.3333 19.5 12.6765 19.5 14.3333C19.5 15.9902 18.1568 17.3333 16.5 17.3333C14.8431 17.3333 13.5 15.9902 13.5 14.3333ZM16.5 12.6667C15.5795 12.6667 14.8333 13.4129 14.8333 14.3333C14.8333 15.2538 15.5795 16 16.5 16C17.4204 16 18.1666 15.2538 18.1666 14.3333C18.1666 13.4129 17.4204 12.6667 16.5 12.6667ZM16.5 20.6667C14.7337 20.6667 13.2044 21.6127 12.412 22.9977C12.2292 23.3173 11.8219 23.4282 11.5023 23.2453C11.1827 23.0625 11.0718 22.6552 11.2547 22.3356C12.2819 20.5401 14.2502 19.3333 16.5 19.3333C18.7498 19.3333 20.7181 20.5401 21.7453 22.3356C21.9282 22.6552 21.8173 23.0625 21.4977 23.2453C21.1781 23.4282 20.7708 23.3173 20.588 22.9977C19.7956 21.6127 18.2663 20.6667 16.5 20.6667Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Highest cost employees
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Users with a high price tag
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[16px]">
          {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

          <div className="flex flex-col max-w-[100%] h-[360px] px-[3px] py-[3px]">
            {getSlicedCardData(data?.high_cost_employees)?.map((employee) => (
              <Link
                key={employee.id}
                to={`/company_dashboard/home/employee_report/${employee.id}`}
                className="flex justify-between items-center card-animate cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
              >
                <div className="flex gap-[16px] items-center">
                  <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20">
                    <span className="flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]">
                      <span>{employee?.name ? employee.name[0] : ''}</span>
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                      {employee?.name}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-left">
                      {employee?.distinction}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                    ${formatedAmount(employee?.service_cost)}
                  </span>
                  <span className="text-secondary !leading-[20px] !text-right">
                    Cost per month
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"/company_dashboard/employees"}>
            <Button
              className={cn({
                invisible: !data?.high_cost_employees?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ServicesAndEmployeesCostCards;
