import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

import Button from "../../../../components/Buttons/button";
import { formatedAmount } from "../../../../utils/helpers";
import { getSlicedCardData } from "../../../evaluator/company-dashboard/home/lib/getSlicedCardData";
import { useNavigate } from "react-router-dom";

function HighServicesCostCards({ highCostServices, user_id }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[24px]  max-w-[100%]">
      <div className="flex flex-col p-[24px]">
        <div className="flex items-center gap-[16px]">
          <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4075 6.11719C17.762 6.82136 17.2444 7.71968 16.8999 8.66671H21.4113C21.9297 8.65425 22.3388 8.51816 22.6441 8.29535C22.9604 8.0645 23.2152 7.70448 23.3538 7.16693C23.6719 5.93322 22.8697 4.66671 21.3327 4.66671C20.2375 4.66671 19.2373 5.21195 18.4075 6.11719ZM15.1642 8.66671C14.7975 7.71459 14.2804 6.81668 13.6444 6.11427C12.8247 5.20891 11.8476 4.66671 10.7555 4.66671C9.21853 4.66671 8.41634 5.93322 8.73442 7.16693C8.87302 7.70448 9.12785 8.0645 9.44414 8.29535C9.74942 8.51816 10.1585 8.65425 10.6769 8.66671H15.1642ZM16.026 7.31082C16.3941 6.54637 16.8643 5.82743 17.4246 5.21622C18.4281 4.12147 19.7612 3.33337 21.3327 3.33337C23.7957 3.33337 25.1667 5.47579 24.6449 7.49981C24.5315 7.93969 24.3549 8.3321 24.1187 8.66881C25.9044 8.73167 27.3327 10.1991 27.3327 12V14.6667C27.3327 16.0336 26.51 17.2083 25.3327 17.7227V22.6667C25.3327 24.5077 23.8403 26 21.9993 26H9.99935C8.1584 26 6.66602 24.5077 6.66602 22.6667V17.7227C5.48875 17.2083 4.66602 16.0336 4.66602 14.6667V12C4.66602 10.1695 6.14154 8.68362 7.9681 8.66685C7.73259 8.33058 7.5565 7.93884 7.44331 7.49981C6.92147 5.47579 8.29254 3.33337 10.7555 3.33337C12.3302 3.33337 13.6416 4.12451 14.6328 5.21938C15.1866 5.83101 15.6528 6.5493 16.026 7.31082ZM10.6627 10H7.99935C6.89478 10 5.99935 10.8955 5.99935 12V14.6667C5.99935 15.7713 6.89478 16.6667 7.99935 16.6667H15.3327V10H10.7555V10.001C10.7245 10.001 10.6935 10.0007 10.6627 10ZM21.3327 10.001V10H16.666V16.6667H23.9993C25.1039 16.6667 25.9993 15.7713 25.9993 14.6667V12C25.9993 10.8955 25.1039 10 23.9993 10H21.4255C21.3947 10.0007 21.3637 10.001 21.3327 10.001ZM16.666 18H23.9993V22.6667C23.9993 23.7713 23.1039 24.6667 21.9993 24.6667H16.666V18ZM15.3327 18V24.6667H9.99935C8.89478 24.6667 7.99935 23.7713 7.99935 22.6667V18H15.3327Z"
                fill="white"
              />
            </svg>
          </div>

          <div className="flex flex-start flex-col gap-[2px]">
            <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
              Highest Cost Services
            </div>
            <span className="text-secondary !text-[14px] !leading-[20px]">
              Services with the highest price tag
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col px-[16px]">
        {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

        <div className="flex flex-col w-[100%] h-[360px] py-[3px] px-[3px]">
          {getSlicedCardData(highCostServices)?.map((service) => (
            <Link
              key={service.id}
              to="#"
              // to={`/personal_dashboard/team_services/usage_report/${service.id}`}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  `/personal_dashboard/team_services/usage_report/${service.id}`,
                  {
                    state: {
                      userId: user_id,
                    },
                  }
                );
              }}
              className="flex justify-between items-center card-animate w-[100%] cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
            >
              <div className="flex gap-[16px] items-center">
                <img
                  src={service?.image_url}
                  alt="company-logo"
                  className="w-[40px] h-[40px] rounded-[8px] card_img"
                />
                <div className="flex flex-col">
                  <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                    {service?.name}
                  </span>
                  <span className="text-secondary !leading-[20px] !text-left">
                    {service?.categories}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                  ${formatedAmount(service?.service_cost)}
                </span>
                <span className="text-secondary !leading-[20px] !text-right">
                  Cost per month
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="flex justify-center items-center h-[49px]">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/personal_dashboard/team_services`, {
              state: {
                userId: user_id,
              },
            });
          }}
        >
          <Button
            className={cn({
              invisible: !highCostServices?.length,
            })}
            secondary
            hoverColor
          >
            View all
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default HighServicesCostCards;
