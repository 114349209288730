import React,{useState} from 'react';
import {useDispatch} from 'react-redux'
import Button from "../../Buttons/button";
import BasicModal from "../../models/models";
import { selectCompanyDashboard, deleteService, setServiceMenuUpdatedFalse } from "../../../redux/slices/companyDashboardSlice";


const ServiceSetupMenuModel = ({ setstate, setPopup, id }) => {
  const dispatch = useDispatch();
  const [state,setState] = useState(null)
  const [setup, setSetup] = useState("false")
  const [setupId, setSetupId] = useState(null)

  const handleSetupService = (id) => {
    setState(32)
    setSetup(true)
    setSetupId(id)
  }

  const handleRemoveSetupService = (id) => {
    dispatch(deleteService(id));
    dispatch(setServiceMenuUpdatedFalse());
    setPopup(false)
  };

  return (
    <div className="flex flex-col w-[320px] rounded-[24px] bg-[#363D50] popup_body">
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px]">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left" >
                <Button
                  onClick={() => handleSetupService(id)}
                  secondaryHover={true}
                >
                  Setup service
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="px-[24px] flex flex-col py-[16px] popup_details">
        <div className="service_heading">
          <Button
            onClick={() => handleRemoveSetupService(id)}
            secondaryHover={true}
          >
            Remove service from org
          </Button>
        </div>
        <div className="service_text">Contact thryft to delete this service and its data from your org</div>
      </div>*/}

      {/* {setup && ( */}
      {/*   <BasicModal */}
      {/*     state={state} */}
      {/*     popup={setup} */}
      {/*     setPopup={setSetup} */}
      {/*     setState={setState} */}
      {/*     setupId={setupId} */}
      {/*   /> */}
      {/* )} */}

    </div>
  );
};

export default ServiceSetupMenuModel;
