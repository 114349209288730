// export const updatePasswordRequest = (body) => (dispatch) => {
//     putRequest('auth/users/password', body, {
//       "Content-Type": "application/json",
//     }).then((res) => {
//       dispatch(updatePassword(res))
//     });
//   }

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatePasswordRequest } from "../../redux/slices/userSlice";

function NewPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (newPassword === "") {
      setErrorMessage("* New Password is required");
    } else if (confirmPassword === "") {
      setErrorMessage("* Confirm Password is required");
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("* Passwords do not match");
    } else {
      const token = new URLSearchParams(location.search).get(
        "reset_password_token"
      );

      dispatch(
        updatePasswordRequest({
          reset_password_token: token,
          password: newPassword,
          password_confirmation: newPassword,
        })
      );
      setNewPassword("");
      setConfirmPassword("");
      location.pathname = "/login";
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0">
      <form
        onSubmit={handleSubmit}
        className="bg-[#242B3E] shadow-md rounded px-6 pt-10 pb-10 sm:px-12 sm:pt-[70px] sm:pb-[70px] mb-4 w-full sm:w-[450px] min-h-[400px] flex justify-between flex-col"
      >
        <div className="mb-4">
          <label
            htmlFor="new_password"
            className="block text-white font-bold mb-2"
          >
            New Password
          </label>
          <input
            type="password"
            id="new_password"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4 mt-4">
          <label
            htmlFor="confirm_password"
            className="block text-white font-bold mb-2"
          >
            Confirm Password
          </label>
          <input
            type="password"
            id="confirm_password"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="flex items-end justify-between mt-6 flex-col">
          <button
            type="submit"
            className=" bg-[#FFD74A] hover:brightness-125 !text-[#242B3E] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full uppercase"
          >
            Update Password
          </button>
        </div>
        {errorMessage && (
          <div
            className="mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
      </form>
    </div>
  );
}

export default NewPassword;
