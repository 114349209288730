import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './slices/userSlice';
import personalDashboardReducer from './slices/personalDashboardSlice';
import companyDashboardReducer from './slices/companyDashboardSlice';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile'],
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    personalDashboard: personalDashboardReducer,
    companyDashboard: companyDashboardReducer
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);

export default store;
