import Image1 from "../assets/images/employee1.svg";
import Image2 from "../assets/images/employee2.svg";
import Image3 from "../assets/images/employee3.svg";
import Image4 from "../assets/images/employee4.svg";
import Image5 from "../assets/images/employee5.svg";
import Image6 from "../assets/images/employee6.svg";
import Image7 from "../assets/images/employee7.svg";
import Image8 from "../assets/images/employee8.svg";

export const heading = [
    "Employee",
    "Monthly cost",
    "Services",
    "Submissions",
    "Requests",
    "Feedback",
    "",
];

export const tableData = [
    {
        employee: {
            image: Image1,
            name: "Darlene Robertson",
            category: "Work Distinction"
        },
        monthly_cost: 1088.09,
        service: "29",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image2,
            name: "Ronald Richards",
            category: "Work Distinction"
        },
        monthly_cost: 820.44,
        service: "18",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image3,
            name: "Courtney Henry",
            category: "Work Distinction"
        },
        monthly_cost: 748.27,
        service: "20",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image4,
            name: "Brooklyn Simmons",
            category: "Work Distinction"
        },
        monthly_cost: 731.11,
        service: "19",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image5,
            name: "Albert Flores",
            category: "Work Distinction"
        },
        monthly_cost: 678.77,
        service: "17",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image6,
            name: "Esther Howard",
            category: "Work Distinction"
        },
        monthly_cost: 633.66,
        service: "18",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image7,
            name: "Floyd Miles",
            category: "Work Distinction"
        },
        monthly_cost: 595.33,
        service: "15",
        submissions: 3,
        tenure: "1y 2m",
    },
    {
        employee: {
            image: Image8,
            name: "Ralph Edwards",
            category: "Work Distinction"
        },
        monthly_cost: 424.03,
        service: "53",
        submissions: 3,
        tenure: "1y 2m",
    },
];

