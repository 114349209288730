import image1 from "../assets/images/Rectangle 1 (1).svg";
import image2 from "../assets/images/Rectangle 1 (2).svg";
import image3 from "../assets/images/Rectangle 1 (3).svg";
import image4 from "../assets/images/Rectangle 1 (4).svg";
import image5 from "../assets/images/Rectangle 1 (5).svg";
import image6 from "../assets/images/Rectangle 1 (6).svg";

export const services = [
  {
    image: image1,
    title: "PM Management SW",
    price: 70.85,
    rating: 3,
  },
  {
    image: image2,
    title: "PM Management SW",
    price: 90.85,
    rating: 1,
  },
  {
    image: image3,
    title: "PM Management SW",
    price: 53.85,
    rating: 3,
  },
  {
    image: image4,
    title: "PM Management SW",
    price: 81.85,
    rating: 2,
  },
  {
    image: image5,
    title: "PM Management SW",
    price: 19.43,
    rating: 2,
  },
  {
    image: image6,
    title: "PM Management SW",
    price: 99.85,
    rating: 1,
  },
  {
    image: image1,
    title: "PM Management SW",
    price: 46.83,
    rating: 3,
  },
  {
    image: image2,
    title: "PM Management SW",
    price: 34.32,
    rating: 2,
  },
  {
    image: image3,
    title: "PM Management SW",
    price: 86.12,
    rating: 3,
  },
  {
    image: image4,
    title: "PM Management SW",
    price: 44.85,
    rating: 3,
  },
  {
    image: image5,
    title: "PM Management SW",
    price: 94.85,
    rating: 1,
  },
  {
    image: image6,
    title: "PM Management SW",
    price: 25.85,
    rating: 2,
  },
];

export const savingsOpportunities = [
  {
    id: 1,
    img: "https://s3-alpha-sig.figma.com/img/02ce/1a56/f764a76b155bb769137f737d90fd8cbd?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=eMCNCzXjOC0voEgMR8ZzKyM6Xu4G3QrRdVSGqHFxVKoQVBO92S5P4XiewqxGYFq0bGjo2PMHjnZ2ngjy3InkWqZWG~w5eyhWT6foM9F6-j7SgW46wb-TPk1VGDMp3-CA3duz44-2f8jQs16thfkDeJ501NbCOQK0FTwS~Qr7Nag7IUFfPp7-pwWPjUwt~rNBgMnxtvVlvom3Ic1I2KchSMpQWM7pEFrUEzavUkgjCuf371-bkBU517Qe7LA5H1kPxsPn~Xt05v-QllPuuKMSoScEm6iNB2FlcKK0Z6q1xIZ4jChTQvIhFpXs38tov0H8X~U4HqMsbKNKDDzPChTBuA__",
    title: "Do you still use <service name>?",
    description:
      "You have <#> services that provide similar services. If you use these services for similar reasons, thryft suggests that you use only one, and opt-out of the rest.",
    optOut: true,
    viewServices: false,
    proposeChange: false,
    yearlyPrice: 1647,
    totalPrice: 20345,
    percentage: 1.24,
  },
  {
    id: 2,
    img: null,
    title: "Opt-out of similar services",
    description:
      "You have <#> services that provide the same services. If you use these services for similar reasons, thryft suggests that you use only one of the following services, and opt-out of the rest.",
    optOut: false,
    viewServices: true,
    proposeChange: false,
    yearlyPrice: 12165,
    totalPrice: 20345,
    percentage: 5.94,
  },
  {
    id: 3,
    img: "https://s3-alpha-sig.figma.com/img/4f70/4f87/769d1ce95787abef454cd9502dd9905a?Expires=1722816000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=m7CakwzzihbnTVMVrviluE1M69e3z-jjjRBEs4rP2zYYlPqKlEf~5ub0oIg3h9ihpDMaWEygIAi6u2pcIb7E~DLDm7Mq~Fz~uVqTIYJ93xz~MryzDXXByty7-Liw2p21GM9YrZtprPpJ7jcbnPhjxAojdcBpabDRCO19OKohM3TwaEAH7ED9qVmHAIv3K71vnDbTuuEz1emj62KbbYoNfOJYKc-M2aA~OvzbNV5CfaMbZ41kJ8DtXWToeRJd5H8QxyqK6b87hAVlBy2FSHK4VJifkUdUZi0OlLakULVd85ILNMee19oENRQUuRlmQuP~~G--dbfB69RLXOzxlYarFA__",
    title: "Do you still use <service name>?",
    description:
      "You have <#> services that provide similar services. If you use these services for similar reasons, thryft suggests that you use only one, and opt-out of the rest.",
    optOut: true,
    viewServices: false,
    proposeChange: true,
    yearlyPrice: 3847,
    totalPrice: 20345,
    percentage: 1.67,
  },
];
