export const history = [
    {
        month: 'January',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'Feburary',
        amount: '1,352,903.35',
        services: 43,
    },
    {
        month: 'March',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'April',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'May',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'June',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'July',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'August',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'September',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'Octuber',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'Novembar',
        amount: '1,352,903.32',
        services: 43,
    },
    {
        month: 'December',
        amount: '1,352,903.32',
        services: 43,
    },
]