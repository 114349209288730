import React from "react";
import Pill from "../../Pill/pill";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import negativeIcon from "../../../assets/images/negative.png";
import negativeHoverIcon from "../../../assets/images/nagative_hover.png";
import neutralIcon from "../../../assets/images/neutral.png";
import neutralHoverIcon from "../../../assets/images/neutral_hover.png";
import smileyIcon from "../../../assets/images/smiley.png";
import smileyHoverIcon from "../../../assets/images/smiley_hover.png";

function ServiceTableBody({ tableData, userId, teamService, model = false, show, setShow }) {
    const navigate = useNavigate();

    return (
        <tbody>
            {tableData?.map((row, index) => {
              return (
                <tr
                    key={index}
                    className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
                    onClick={() => {
                      if (teamService === "team") {
                        navigate(`/personal_dashboard/team_services/usage_report/${row.id}`, {
                          state: { userId: userId }
                        });
                      } else {
                        navigate(`/company_dashboard/services/service_report/${row.id}`);
                      }
                    }}
                >
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px]"
                  >
                    <div className="flex flex-col md:flex-row items-center gap-[10px]">
                      <img
                        className="w-[40px] h-[40px] card_img rounded-lg"
                        src={row.service.image}
                        alt="service-img"
                      />
                      <div className="flex flex-col">
                        <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium">{row.service.name}</span>
                        <span className="text-secondary !text-left">
                          {row.service.category}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px]"
                  >
                    <div>
                      {row.rating === 'positive' && <img className="w-[24px]" src={smileyHoverIcon} alt="smiley icon"/> }
                      {row.rating === 'general' && <img className="w-[24px]" src={neutralHoverIcon} alt="neutral icon"/>}
                      {row.rating === 'negative' && <img className="w-[24px]" src={negativeHoverIcon} alt="negative icon"/>}
                    </div>
                  </td>
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                  >
                    {row.user_count}
                  </td>
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                  >
                    {row.cost_per_month}
                  </td>
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                  >
                    {row.cost_average}
                  </td>
                  <td
                    className="text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                  >
                    <span className="flex gap-[4px] items-center"><Pill round={true} className={'text-secondary !font-semibold !text-[12px] !leading-[14px]'} fill={true}>EXP</Pill> {row?.contract_end?.split('-').reverse().join('/')}</span>
                  </td>
                </tr>
            )
            })}
        </tbody>
    );
}

export default ServiceTableBody;
