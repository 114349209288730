import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../../../components/Buttons/button";
import Search from "../../../../../assets/images/search.png";
import menuDot from "../../../../../assets/images/menuDot.png";
import BasicModal from "../../../../models/models";
import { getAuthorizedServices, getInitializeServicesData, setServiceCreatedFalse, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { getUserDetails, selectUser } from "../../../../../redux/slices/userSlice";
import Pill from "../../../../Pill/pill";
import Unicon from "../../../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";
import { useLocation } from "react-router-dom";

const ManageServices = ({setModel}) => {
  const dispatch = useDispatch();
  const [popUp, setPopUp] = useState(false);
  const [id, setId] = useState("")
  const [state, setState] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [menuDotPopup, setMenuDotPopup] = useState(false);
  const [newServiceAdded, setNewServiceAdded] = useState(false);
  const [sortedServices, setSortedServices] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  // const [saveSetup, setSaveSetup] = useState(false);
  const State = useSelector(selectCompanyDashboard);
  const { authorizedServicesData, serviceUpdated, serviceMenuUpdated } = State;
  const userState = useSelector(selectUser);
  const { userDetails } = userState
  const adminRole = userDetails.user.role

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const service_id = params.get('service_id');

  useEffect(()=>{
    if(service_id){
      setPopUp(true);
      setState(32);
      setId(service_id);
    }
  },[service_id])

  useEffect(() => {
    dispatch(getAuthorizedServices());
    dispatch(getInitializeServicesData());
  }, [dispatch, serviceUpdated, state, serviceMenuUpdated]);
  
  useEffect(() => {
    if (authorizedServicesData?.authorized_services) {
      setSortedServices([...authorizedServicesData.authorized_services]);
    }
  }, [authorizedServicesData]);


  const displayServices = sortedServices.filter(
    (service) => service.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSetup = (serviceId) => {
    setPopUp(true);
    setState(32);
    setId(serviceId);
  }

  function handleManage(serviceId) {
    setPopUp(true);
    setState(35);
    setId(serviceId);
  }

  const handleMenuDotClick = (service) => {
    setMenuDotPopup(true);
    if (service.reporting_status === false) {
      setState(31);
      setId(service.id);
    } else {
      setState(30);
      setId(service.id);
    }
  }

  useEffect(() => {
    if (newServiceAdded && newServiceAdded) {
      dispatch(getAuthorizedServices());
      dispatch(getInitializeServicesData());
      setNewServiceAdded(false);
      setState(29);
    }
  }, [newServiceAdded]);

  const handleSortByName = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  const handleIntegrations = () => {
    // const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
    //   if (sortOrder === "asc") {
    //     return a.name.localeCompare(b.name);
    //   } else {
    //     return b.name.localeCompare(a.name);
    //   }
    // });
    // setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // setSortedServices(sorted);
  };

  const handleReporting = () => {
    const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.reporting_status - b.reporting_status;
      } else {
        return b.reporting_status - a.reporting_status;
      }
    });
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setSortedServices(sorted);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const handleLatestReporting = () => {
      const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
        const dateA = parseDate(a.added);
        const dateB = parseDate(b.added);
        if (sortOrder === "asc") {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
      });

      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      setSortedServices(sorted);
  };

  const handleAdded = () => {
      const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
        const dateA = parseDate(a.added);
        const dateB = parseDate(b.added);
        if (sortOrder === "asc") {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
      });

      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      setSortedServices(sorted);
  };


  return (
    <div className="ManageServicesBox">
      <div className="headingBox flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">Manage Services</h2>
        {(adminRole == "admin") && (
          <Button
            onClick={() => {
              setPopUp(true);
              setState(28);
              dispatch(setServiceCreatedFalse());
            }}
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
          >
            + Add Service
          </Button>
        )}
      </div>

      <div className="searchBox">
        <img src={Search} alt="icon" className="searchIcon" />
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search services"
          className="searchInput"
        />
      </div>

      <div className="rounded-t-xl px-[8px] pb-[20px] w-[100%] scrollbar-hide scrollable_data">
        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
          <table className="table-head sticky top-0">
            <thead className="table-head sticky top-0 bg-[#363D50]">
              <tr>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleSortByName}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Service
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleIntegrations}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Integrations
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleReporting}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Reporting
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleLatestReporting}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Latest Report Uploaded
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper_service"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleAdded}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Added
                      <img
                        className={cn("sortingicon", {
                          "rotate-180": sortOrder === "desc"
                        })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >&nbsp;</th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >&nbsp;</th>
              </tr>
            </thead>
            
            <tbody>
             {displayServices?.map((service) => (
              <tr key={service.id} className="hover:bg-[#363D50] hover:brightness-110 ">
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px]"
                >
                  <div className="flex flex-col md:flex-row items-center gap-[10px]">
                    <img
                      className="w-[40px] h-[40px] card_img rounded-lg"
                      src={service.image}
                      alt="service-img"
                    />
                    <div className="flex flex-col">
                      <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                        {service.name}
                      </span>
                      <span className="text-secondary lineHeight !text-left">
                        {service.category}
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] font_12px"
                >
                  {service.status === "Active" ? (
                    <Pill greenFill={true}>Active</Pill>
                  ) : (
                    <Pill redFill={true}>Inactive</Pill>
                  )}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium font_12px"
                >
                  {/* <div>{service.reporting_status}</div> */}
                  {service.reporting_status === true ? (
                    <Pill greenFill={true}> Active </Pill>
                  ) : (
                    <Pill redFill={true}> Needs setup </Pill>
                  )}
                </td>
                <td
                  className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                >
                  {service.latest_report}
                </td>
                <td
                  className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                >
                  {service.added}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium lineHeight21 btn_td"
                >
                  {service.reporting_status === false ? (
                    <Button
                      onClick={() => handleSetup(service.id)}
                      outlined={true}
                      hoverColor={true}
                      secondary={false}
                      secondaryHoverOutlined={true}
                      fill={true}
                    >
                      Setup
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleManage(service.id)}
                      outlined={true}
                      hoverColor={true}
                      secondary={true}
                      secondaryHoverOutlined={true}
                    >
                      Manage
                    </Button>
                  )}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                >
                  <div
                    className="menuDot cursor-pointer"
                    onClick={() => handleMenuDotClick(service)}
                  >
                    <img src={menuDot} alt="icon" />
                  </div>
                </td>
              </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
      {popUp && (
        <BasicModal
          state={state}
          popup={popUp}
          setPopup={setPopUp}
          setState={setState}
          id={id}
          setNewServiceAdded={setNewServiceAdded}
          handleSetup={handleSetup}
          // setSaveSetup={setSaveSetup}
        />
      )}

      {menuDotPopup && (
        <BasicModal
          popup={menuDotPopup}
          setPopup={setMenuDotPopup}
          state={state}
          setModel={setModel}
          id = {id}
        />
      )}
    </div>
  )
}

export default ManageServices