const findMaxCountOfReview = (data) => {
  if (!data) {
    return -1;
  }

  const counters = [data.general_ratings_count, data.negative_ratings_count, data.positive_ratings_count];

  // if counters has all the same numbers we shouldn't highlight the icon
  const isTheSameCountOfReviews = counters.every((el,_, arr) => el === arr[0]);

  if (isTheSameCountOfReviews) {
    return -1
  }

  const maxCountOfReviews = Math.max(...counters);

  // if we have two max тumbers then shouldn't highlight the icon too
  const hasMultipleMax = counters.filter(number => number === maxCountOfReviews).length > 1;

  if (hasMultipleMax) {
    return - 1
  }

  return maxCountOfReviews;
}

export { findMaxCountOfReview };
