import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

import DragDrop from "../../../../DragDrop/DragDrop";
import StartDate from "../../../../DatePicker/StartDate";
import EndDate from "../../../../DatePicker/EndDate";

function ContractData({formValid, id, start_date, setStart_date, end_date, setEnd_date, contract_file, setContract_file}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const setupServices = SetServicesData?.setup_services;
  const [hasWebsite, setHasWebsite] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [serviceName, setServiceName] = useState("");

  useEffect(()=>{
    dispatch(getInitializeServicesData())
  },[])

  useEffect(()=>{
    setStart_date(setupServices.start_date)
    setEnd_date(setupServices.end_date)
    setContract_file(setupServices.contract_file)
  },[id])

  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start flex-col text-left">
        <div className="text-primary !text-[24px] !leading-[36px]">Contract data</div>
        <div className="flex flex-start step_heading">Upload a new service contract PDF file. Thryft will generate any new reports using the latest contract provided.</div>
      </div>


      <div className="dateRangeBox">
        <div className="flex flex-col MonthPickerWrap">
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Start date
            {
              !formValid &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <StartDate start_date={start_date} setStart_date={setStart_date}/>
        </div>

        <div className="flex flex-col MonthPickerWrap">
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            End date
            {
              !formValid &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <EndDate end_date={end_date} setEnd_date={setEnd_date} />
        </div>
      </div>

      <DragDrop setFile={setContract_file}/>
      

    </div>
  );
}

export default ContractData;
