import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard, getSetupServicesData } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

function ServiceSetup({formValid, setTab, id, owner_name, service_name, category_name, file, selectedDate, poc_name, poc_email, poc_phone, contract_file}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const setupServices = SetServicesData?.setup_services;

  useEffect(()=>{
    dispatch(getSetupServicesData(id))
  },[id])


  const handleServiceInfo = () => {
    setTab(2)
  };

  const handleServiceOwner = () => {
    setTab(3)
  };

  const handleContractData = () => {
    setTab(4)
  };

  const handleUserUsageData = () => {
    setTab(5)
  };

  const handleProcurementPOC = () => {
    setTab(6)
  };


  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start step_heading">Complete the steps below to finish setup and activate the service</div>
      <div className="flex flex-row justify-between items-center">
        <div className="serviceBox text-left">
          <label className="flex flex-start justify-between text-primary !font-semibold">Service info</label>
          <small>{ setupServices?.name } • { setupServices?.category }</small>
        </div>
        <div className="serviceAction">
          <a className="editService" onClick={handleServiceInfo} > Edit </a>
        </div>
      </div>
      
      <div className="flex flex-row justify-between items-center">
        <div className="serviceBox text-left">
          <label className="flex flex-start justify-between text-primary !font-semibold">Service owner</label>
          <small>{setupServices?.owner_name}</small>
        </div>
        <div className="serviceAction">
          <a className="editService" onClick={handleServiceOwner} > Edit </a>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center">
        <div className="serviceBox text-left">
          <label className="flex flex-start justify-between text-primary !font-semibold">Contract data</label>
          <small><i>{setupServices?.contract_file}</i></small>
        </div>
        <div className="serviceAction">
          <Button
            onClick={handleContractData}
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
          >
            {setupServices?.contract_file ? 'Edit' : 'Start'}
          </Button>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center">
        <div className="serviceBox text-left">
          <label className="flex flex-start justify-between text-primary !font-semibold">User usage data</label>
          <small><i>{setupServices?.useges_file_name}</i></small>
        </div>
        <div className="serviceAction">
          <Button
            onClick={handleUserUsageData}
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
          >
            {setupServices?.useges_file_name ? 'Edit' : 'Start'}
          </Button>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center">
        <div className="serviceBox text-left">
          <label className="flex flex-start justify-between text-primary !font-semibold">Procurement POC</label>
          <small>{setupServices?.poc_name}</small>
        </div>
        <div className="serviceAction">
          <Button
            onClick={handleProcurementPOC}
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
          >
            {(setupServices?.poc_name) ? 'Edit' : 'Start'}
          </Button>
        </div>
      </div>

      
    </div>
  );
}

export default ServiceSetup;
