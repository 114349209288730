import React from 'react'
import First from './tabs/first';
import Second from './tabs/second';

function Tabs({ Tab, formValidOwner, formValidService, onServiceNameChange, onServiceIdChange, onCategoryChange, onOwnerChange }) {

  return (
    <div>
      {Tab === 1 && <First formValidService={formValidService} formValidOwner={formValidOwner} onServiceIdChange={onServiceIdChange} onServiceNameChange={onServiceNameChange} onCategoryChange={onCategoryChange} onOwnerChange={onOwnerChange}/>}
      {Tab === 2 && <Second />}
    </div>
  )
}

export default Tabs