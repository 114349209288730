import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function EndDate({end_date, setEnd_date}) {
  // const [endDate, setEndDate] = useState(new Date());


  return (
    <DatePicker

      selected={end_date}
      onChange={(date) => setEnd_date(date)}
      dateFormat="MMM dd yyyy"
    />
  );
};

export default EndDate;