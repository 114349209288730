import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
  );

    const options = {
        scales: {
            y:  {
                display: false,
                gridLines: {
                    display: false
                },
                ticks: {
                    display: false
                }
            },
            x: {
                display: false,
                gridLines: {
                    display: false
                },
                ticks: {
                    display: false
                }
            }
            
        },
        interaction: {
            intersect: false,
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };
  
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
    const data = {
        labels,
        datasets: [
            {
                data: [23,56,34,67,98,23,45],
                borderColor: '#FFFFFF',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                tension: 0.5,
                pointStyle: false
            }
        ],
    };

function UsageReportChart() {
  return (
    <div className='chart-wrapper p-[20px]'>
        <Line options={options} data={data} />
    </div>
  )
}

export default UsageReportChart