import React,{useState} from 'react';
import {useDispatch} from 'react-redux'
import Button from "../../Buttons/button";
import BasicModal from "../../models/models";
import { selectCompanyDashboard, setServiceMemberUpdatedFalse, deleteService } from "../../../redux/slices/companyDashboardSlice";


const ManageServiceMenuModel = ({ setstate, setPopup, id, userId }) => {
  const dispatch = useDispatch();
  const [state,setState] = useState(null)
  const [setup, setSetup] = useState("false")
  const [setupId, setSetupId] = useState(null)

  const handleChangeMemberType = (id) => {
    setstate(42)
    setSetup(true)
    dispatch(setServiceMemberUpdatedFalse())
    // setSetupId(id)
  }

  const handleRemoveMember = () => {
    setstate(39)
    setSetup(true)
    dispatch(setServiceMemberUpdatedFalse())
  };

  return (
    <div className="flex flex-col w-[320px] rounded-[24px] bg-[#363D50] popup_body">
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left" >
                <Button
                  onClick={() => handleChangeMemberType()}
                  secondaryHover={true}
                >
                  Change member type
                </Button>
                <div className="service_text">Updates will take effect immediately</div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] popup_details">
        <div className="service_heading">
          <Button
            onClick={() => handleRemoveMember()}
            secondaryHover={true}
          >
            <span className="text-red-500 hover:text-red-600" >
              Remove member
            </span>
          </Button>
        </div>
        <div className="service_text">Employee will no longer have access to sign in to this service’s dashboard</div>
      </div>

     {/* {popup && (
        <BasicModal
          state={state}
          popup={setup}
          setPopup={setSetup}
          setState={setState}
          setupId={setupId}
        />
      )} */}

    </div>
  );
};

export default ManageServiceMenuModel;
