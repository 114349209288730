import React, { useEffect, useState } from 'react'
import BasicModal from '../../../../components/models/models';
import ChartCard from './components/chart-card';
import Header from './components/header';
import PillCards from './components/pill-cards';
import ServicesRequestAndFeedbackCards from './components/services-request-and-feedback-card';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getCompanyUsageReportData, getEmployeeSpecficData, selectCompanyDashboard, getUsageserviceDataDetails } from '../../../../redux/slices/companyDashboardSlice';
import { selectPersonalDashboard, getEmployeeDetailRequest } from '../../../../redux/slices/personalDashboardSlice';

function UsageCompanyReportModule({ path }) {
  const location = useLocation();
  const dispatch = useDispatch();
	const { id } = useParams()
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [userServiceId, setuserServiceId] = useState()
  const [feedbackID, setFeedbackID] = useState()
  const [requestId, setRequestId] = useState()
  const [employeedata, setEmployeeData] = useState();
  const personalState = useSelector(selectPersonalDashboard);
  const { employeeDetailData } = personalState;
  const State = useSelector(selectCompanyDashboard);
  const { usagecompanyReportData, employeeData } = State;

  const queryParams = new URLSearchParams(location.search);
  const dataId = queryParams.get('dataId');

  useEffect(() => {
    if (path.includes('Teams')) {
      dispatch(getEmployeeDetailRequest(dataId));
    } else {
     dispatch(getEmployeeSpecficData(dataId));
    }
  }, [dispatch, path, dataId]);

  useEffect(() => {
    if (path.includes('Teams')) {
        setEmployeeData(employeeDetailData && employeeDetailData);
    } else {
        setEmployeeData(employeeData && employeeData);
    }
  }, [path, employeeDetailData, employeeData]);

  // const data = location.state
  useEffect(() => {
    // if (data) {
    //   dispatch(getUsageserviceDataDetails(data?.serviceid, data?.id));
    // } else {
      if (employeedata?.id){
        dispatch(getCompanyUsageReportData(id, employeedata?.id));
      }
  }, [popup, dispatch, employeedata, id]);
  return (
    <div className='right_panel relative flex flex-col px-[20px] lg:px-[32px] pt-[16px]'>
			<Header path={path} data={usagecompanyReportData} />
      <div className="card-section">
        <div className='cards_wrap flex flex-col gap-[40px] h-[100vh] lg:h-[680px] pt-[32px] pb-[20px] overflow-y-scroll scrollbar-hide'>
          <PillCards data={usagecompanyReportData} setPopup={setPopup} setState={setState} setRequestId={setRequestId}/>
          <ChartCard />
          <ServicesRequestAndFeedbackCards data={usagecompanyReportData} setFeedbackID={setFeedbackID} setPopup={setPopup} setState={setState} setRequestId={ setRequestId } id={{ id: id, service_emp_id:employeedata?.id}} />
        </div>
      </div>

			{setPopup && (
        <BasicModal
          id={{ id: requestId && requestId, userId :usagecompanyReportData?.user?.id}}
          feedBackId={feedbackID}
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          serviceId= { usagecompanyReportData?.service?.id }
          dashboard="companyDahboard"
          feedid={{ id: usagecompanyReportData?.user?.id, feedbackId:feedbackID}}
        />
      )}
    </div>
  )
}

export default UsageCompanyReportModule;