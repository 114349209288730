import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard, getSetupServicesData } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

function ServiceInfo({formValid, id, service_name, setService_name, category_name, setCategory_name}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const [selected, setSelected] = useState(null);
  const setupServices = SetServicesData?.setup_services;
  const [searchText, setSearchText] = useState( setupServices && setupServices?.name);
  // const [serviceName, setServiceName] = useState("");

  useEffect(() => {
    const setupServices = SetServicesData?.setup_services;
    if (setupServices) {
      setService_name(setupServices.name || "");
      setSelected(SetServicesData?.service|| "");

    }
  }, [SetServicesData?.name]);


  const handleServiceName = (event) => {
    setService_name(event.target.value);
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory_name(selectedCategory)
  };


  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start flex-col text-left">
        <div className="text-primary !text-[24px] !leading-[36px]">Service info</div>
        <div className="flex flex-start step_heading">Labels will be displayed in all reports</div>
      </div>
      
      <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Display name?
            {
              !formValid &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Enter service name"}
            value={service_name}
            onChange={handleServiceName}
            // onKeyDown={}
            // onFocus={}
            // onBlur={}
          />
          
        </div>
      </div>
      {/* getCategories */}

      <div className="flex flex-col">
        <div>
          <label className=" flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Category
            {
              !formValid &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <Select
            // selectedCategory={selectedCategory}
            category={CategoriesAndUsersData}
            onCategoryChange={handleCategoryChange}
            selectedService={selected}
            // readOnly={}
          />
        </div>
      </div>

    </div>
  );
}

export default ServiceInfo;
