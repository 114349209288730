import React, { useState, useEffect } from "react";
import FeedbackCard from "../../card/feedbackerCards/feedbackCard";
import { feedbacks } from "../../../services/feedbackData";
import ModelsSidebar from "../../models-Sidebar/models-sidebar";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  employeeFeedbacksData,
  teamEmpFeedbacksData,
  selectCompanyDashboard,
} from "../../../redux/slices/companyDashboardSlice";

function EmployeeFeedbacksModel({ setstate, setPopup, empId, dashboard }) {
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { employeefeedbackData } = State;
  const [show, setShow] = useState(false);
  const [feedbackId, setFeedbackId] = useState();
  const [userId, setuserId] = useState();

  useEffect(() => {
    if (dashboard === "personalDashboard") {
      dispatch(teamEmpFeedbacksData(empId));
    } else {
      dispatch(employeeFeedbacksData(empId));
    }
  }, [dispatch]);

  const closeSidebar = () => {
    if (show === true) {
      setShow(false);
    }
  };

  return (
    <div className="relative px-[20px] min-w-[100vw] w-auto 2xl:min-w-full 2xl:w-[1300px]">
      <div
        style={{
          height: "calc(100vh - 80px)",
        }}
        className="relative overflow-x-scroll pt-[81px] overflow-scroll scrollbar-hide bg-[#363D50] rounded-[16px] flex flex-col"
      >
        <div className="flex w-full absolute bg-[#363D50] z-[2] rounded-t-[16px] top-0 left-0 justify-between px-[24px] py-[16px] border-b-[1px] border-[#41485C]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.93303 6.87691C7.54534 6.49622 8.2922 6.39841 8.98189 6.60861L14.0119 8.14162C14.7016 8.35184 15.0414 9.12775 14.728 9.77719C14.4842 10.2825 13.9173 10.5451 13.3742 10.4044L10.8502 9.75052C10.7993 9.7368 10.7479 9.73148 10.6976 9.73379C10.546 9.74065 10.4056 9.81667 10.3167 9.93809C10.2841 9.98266 10.2584 10.0333 10.2417 10.089C10.2249 10.1446 10.2182 10.201 10.2208 10.2562C10.2299 10.4585 10.3623 10.641 10.5614 10.7075L12.5717 11.4019C13.2408 11.633 13.9643 11.6558 14.6466 11.4672L20.0238 9.98134C20.4014 9.877 20.804 10.0182 21.0337 10.3356C21.3423 10.7619 21.2399 11.3585 20.8069 11.6577L14.4845 16.0254C13.8754 16.4461 13.1112 16.5753 12.3977 16.3781L6.90309 14.8598C6.59945 14.7759 6.28588 14.7334 5.97087 14.7334H3.48264C3.2065 14.7334 2.98264 14.9573 2.98264 15.2334C2.98264 15.5095 3.2065 15.7334 3.48264 15.7334H5.97087C6.19588 15.7334 6.41986 15.7638 6.63674 15.8237L12.1313 17.342C13.1303 17.6181 14.2002 17.4372 15.0529 16.8481L21.3753 12.4804C22.2698 11.8625 22.4812 10.6299 21.8438 9.74927C21.3693 9.09369 20.5375 8.80191 19.7574 9.01746L15.657 10.1505C16.187 8.96161 15.5582 7.5675 14.3034 7.18506L9.27343 5.65205C8.30786 5.35777 7.26226 5.4947 6.40503 6.02767L2.73613 8.30878C2.50161 8.45459 2.4297 8.76289 2.57551 8.99741C2.72131 9.23192 3.02962 9.30383 3.26413 9.15802L6.93303 6.87691Z"
                  fill="white"
                ></path>
              </svg>
            </div>
            <div className="flex flex-col">
              <span className="text-primary !text-[24px] !text-left">
                All feedback given
              </span>
              <span className="text-secondary !text-left">
                {" "}
                {employeefeedbackData?.total_count} totals
              </span>
            </div>
          </div>

          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div className="py-[20px]">
          <div className="grid grid-cols-1 mdl:grid-cols-2 6xl:grid-cols-3 py-[5px] px-[23px] overflow-scroll scrollbar-hide gap-[32px]">
            {employeefeedbackData.feedbacks &&
              employeefeedbackData.feedbacks.map((feedback, index) => (
                <FeedbackCard
                  key={index}
                  empid={empId}
                  feedbackId={feedback.id}
                  userName={feedback.user_name}
                  outlined={true}
                  image={feedback.image_url}
                  userFeedback={feedback.feedback_details}
                  name={feedback.service_name}
                  rating={feedback.rating}
                  date={feedback.date}
                  model={true}
                  show={show}
                  setShow={setShow}
                  setFeedbackId={setFeedbackId}
                  setuserId={setuserId}
                />
              ))}
          </div>
        </div>

        <AnimatePresence>
          {show === true && (
            <motion.div
              className="w-full h-full absolute z-50 backdrop-blur-sm"
              initial={{ opacity: 0, x: 500 }}
              transition={{ duration: 0.3 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 500 }}
              onClick={closeSidebar}
            >
              <ModelsSidebar
                model={"feedback"}
                show={show}
                setShow={setShow}
                dashboard={dashboard}
                id={{ id: empId, feedbackId: feedbackId }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default EmployeeFeedbacksModel;
