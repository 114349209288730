import React, { useState } from 'react';
import Button from "../../../../Buttons/button";
import BasicModal from "../../../../models/models";
import Checkbox from "../../../../Checkbox/Checkbox";
import DropdownArrow from "../../../../../assets/images/ic_arrow_right.png";

const ThryftSetting = ({id}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className='relative flex flex-col items-baseline'>
      <div className='flex justify-between w-full'>
        <h2 className='text-2xl font-bold mb-6'>
          Thryft setting
        </h2>
        <div className='relative'>
          <Button
            onClick={toggleDropdown}
            outlined={true}
            hoverColor={true}
            secondary={false}
            secondaryHoverOutlined={true}
            fill={true}
          >
            Updated data
            <img src={DropdownArrow} alt="icon"  />
          </Button>
          {dropdownOpen && (
            <div className="absolute mt-2 w-90 rounded-[24px] right-0">
              <div className="flex flex-col w-[320px] rounded-[24px] bg-[#293043] popup_body px-4 py-4">
                <div className="flex flex-col gap-[16px] header_popup pb-4">
                  <div className={`flex justify-between ${(state === 43 && popup) ? 'highlightedBtn' : ''}`}>
                    <div className="flex flex-start items-center gap-[16px]">
                      <div className="flex flex-col">
                        <span className="text-primary !text-left">
                          <Button
                            onClick={() => {
                              setState(43)
                              setPopup(true)
                            }}
                            // secondaryHover={true}
                          >
                            Update user usage data
                          </Button>
                          <div className="service_text">Updates will take effect immediately</div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`flex flex-col mt-4 popup_details ${(state === 44 && popup) ? 'highlightedBtn' : ''}`}>
                  <div className="service_heading">
                    <Button
                      onClick={() => {
                        setState(44)
                        setPopup(true)
                      }}
                      // secondaryHover={true}
                    >
                      Upload new contract
                    </Button>
                  </div>
                  <div className="service_text">Employee will no longer have access to sign in to this service’s dashboard</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-baseline'>
        <span className='font-bold text-base'>
          Total cost visibility
        </span>
        <p className='text-[#A5AEBC]'>
          Cost totals are visible to users of this service when they access this usage report from their personal dashboard.
        </p>
      </div>
      <div>
        <div className='flex flex-col'>
          <div className='flex flex-row h-8 mt-6'>
            <Checkbox />
            <div className='flex flex-col items-baseline justify-center ml-2'>
              <span>Usage reports</span>
              <p>
                The total cost of an individual service that your org pays for an employee.
              </p>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          id={id}
        />
      )}
    </div>
  );
}

export default ThryftSetting;
