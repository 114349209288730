import Image from "../assets/images/model_Image.svg";

export const heading = [
    "Service",
    "Impact rating",
    "Users #",
    "Cost",
    "Average user cost",
    "Contract end"
]

export const tableData = [
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 3,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 2,
        contract_end: "05/10/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 2,
        contract_end: "11/03/2023",
        user_count: 234,
        cost_average: 423.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 3,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 3,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 2,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 3,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 1,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 2,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },
    {
        service: {
            image: Image,
            name: "Tool title",
            category: "Category"
        },
        rating: 3,
        contract_end: "03/11/2023",
        user_count: 346,
        cost_average: 446.34,
        cost_per_month: 32435.94
    },

]