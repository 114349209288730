import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import fileUpload from "./../../assets/images/fileUpload.png";

const fileTypes = ["XLSX"];

function DragDrop({setFile}) {
  // const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <div className="customDragDrop">
      <div className="DragDropContainer">
        <img src={fileUpload} alt="icon" className="DragDropImg" />
        <div className="DragDropHeading">Drag and drop Excel file</div>
        <div className="fileText">
          <span>or</span>
          <span className="selectText">Select a file</span>
        </div>
      </div>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
    </div>
  );
}

export default DragDrop;