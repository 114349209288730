import React,{useState,useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import Button from '../../../../../components/Buttons/button';
import menuDot from "../../../../../assets/images/menuDot.png";
import { getServiceMembers, setServiceMemberCreatedFalse, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { getInitials } from '../../../../../shared/lib/getInitials';
import { getRandomHex } from '../../../../../shared/lib/getRandomHex';
import BasicModal from "../../../../models/models";
import Unicon from "../../../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";

const Members = ({id}) => {
  const dispatch = useDispatch();
  const companyDashboardState  = useSelector(selectCompanyDashboard);
  const {serviceMemberData, serviceMemberCreated, serviceMemberUpdated} = companyDashboardState
  const serviceMembers = serviceMemberData.service_members;
  const [hexColor, hexWithOpacity] = getRandomHex();
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [userId, setUserId] = useState(null);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortOrderName, setSortOrderName] = useState("asc");
  const [sortOrderType, setSortOrderType] = useState("asc");
  const [sortOrderAdded, setSortOrderAdded] = useState("asc");
  const [sortOrderLastActive, setSortOrderLastActive] = useState("asc");
  const [userType, setUserType] = useState("");

  useEffect(()=>{
    dispatch(getServiceMembers(id))
  },[id, serviceMemberCreated, serviceMemberUpdated])

  useEffect(() => {
    if (serviceMembers) {
      setSortedUsers([...serviceMembers]);
    }
  }, [serviceMembers]);


  const handleMenuClick = (userId,userTpye) => {
    setPopup(true);
    setState(38);
    setUserId(userId);
    setUserType(userTpye)
  }

  const handleAddServiceMember = () => {
    setPopup(true);
    setState(40);
    // setUserId(userId);
  }

  const handleMemberType = () => {
    setPopup(true);
    setState(41);
    // setUserId(userId);
  }


  const handleMember = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      if (sortOrderName === "asc") {
        return a.name.localeCompare(b.name);
      } else {
        return b.name.localeCompare(a.name);
      }
    });
    setSortOrderName(sortOrderName === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const handleType = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const typeA = a.type || '';
      const typeB = b.type || '';

      if (sortOrderType === "asc") {
        return typeA.localeCompare(typeB);
      } else {
        return typeB.localeCompare(typeA);
      }
    });
    setSortOrderType(sortOrderType === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const handleAdded = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const dateA = parseDate(a.added);
      const dateB = parseDate(b.added);
      if (sortOrderAdded === "asc") {
          return dateA - dateB;
      } else {
          return dateB - dateA;
      }
    });

    setSortOrderAdded(sortOrderAdded === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  const handleLastActive = () => {
    const sorted = [...serviceMembers].sort((a, b) => {
      const dateA = parseDate(a.last_active);
      const dateB = parseDate(b.last_active);
      if (sortOrderLastActive === "asc") {
          return dateA - dateB;
      } else {
          return dateB - dateA;
      }
    });

    setSortOrderLastActive(sortOrderLastActive === "asc" ? "desc" : "asc");
    setSortedUsers(sorted);
  };

  return (
    <div className='manage-service-wrapper flex flex-col gap-[24px] ManageServicesBox'>
      <div className="flex flex-col md:flex-row md:justify-between gap-[24px] md:items-start">
          <div className="flex flex-row justify-start gap-[24px] items-center">
            <div className="flex flex-col">
              <span className="text-primary !text-[24px] !leading-[36px] text-left">Service members</span>
              <span className="text-secondary !text-[14px] !leading-[21px] text-left">
                <span>Service members have access to this service’s dashboard and more depending on their </span>
                <span>
                  <a
                    href="#"
                    className="memberLink"
                    onClick={() => handleMemberType()}
                  >
                    member type.
                  </a>
                </span>
              </span>
            </div>
          </div>
          
          <Button
            outlined={true}
            hoverColor={true}
            secondary={true}
            secondaryHoverOutlined={true}
            onClick={() => {
              handleAddServiceMember()
              dispatch(setServiceMemberCreatedFalse())
            }}
          >
            + Add Member
          </Button>
      </div>

      <div className="rounded-t-xl px-[8px] pb-[20px] w-[100%] scrollbar-hide scrollable_data">
        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
          <table className="table-head sticky top-0">
            <thead className="table-head sticky top-0 bg-[#363D50]">
              <tr>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleMember}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Member
                      <img
                        className={cn("sortingicon", { "rotate-180": sortOrderName === "desc" })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleType}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Type
                      <img
                        className={cn("sortingicon", { "rotate-180": sortOrderType === "desc" })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleAdded}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Added
                      <img
                        className={cn("sortingicon", { "rotate-180": sortOrderAdded === "desc" })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
                  <Button
                    className="sorting_btn_wrapper"
                    secondaryHoverOutlined={true}
                    outlined={true}
                    onClick={handleLastActive}
                  >
                    <span className="flex items-center text-[#A5AEBC]">
                      Last active
                      <img
                        className={cn("sortingicon", { "rotate-180": sortOrderLastActive === "desc" })}
                        src={Unicon}
                        alt="icon"
                      />
                    </span>
                  </Button>
                </th>
                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >&nbsp;</th>
              </tr>
            </thead>
            
            <tbody>
            {sortedUsers?.map((user) => (
             <tr key={user.id} className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer">
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px]"
                >
                  <div className="flex flex-col md:flex-row items-center gap-[10px]">
                    <div
                      style={{
                        backgroundColor: hexWithOpacity,
                        color: hexColor,
                      }}
                      className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                    >
                      {getInitials(user.name)}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
                        {user.name}
                      </span>
                      <span className="text-secondary lineHeight !text-left">
                        {user.distinction}
                      </span>
                    </div>
                  </div>
                </td>

                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] font_12px"
                >
                  {user.type}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium font_12px"
                >
                  {user.added}
                </td>
                <td
                  className="text-left lineHeight text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                >
                  {user.last_active}
                </td>
                <td
                  className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                >
                  <div
                    className="menuDot"
                    onClick={() => handleMenuClick(user.id, user.type)}
                  >
                    <img src={menuDot} alt="icon" />
                  </div>
                </td>
              </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
      {popup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
          id={id}
          userId={userId}
          userType={userType}
        />
      )}
    </div>
  )
}

export default Members