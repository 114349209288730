import React, { useEffect, useState, useCallback } from "react";
import Image from "../../../assets/images/model_Image.svg";
import Button from "../../Buttons/button";
import Pill from "../../Pill/pill";
import RadioButton from "../../RadioButton/RadioButton";
import { useDispatch, useSelector } from "react-redux";
import {
  createRequestComments,
  getRequestDetails,
  selectPersonalDashboard,
  updateRequest,
  statusUpdateRequest,
} from "../../../redux/slices/personalDashboardSlice";
import {
  getUsageRequestDataDetails,
  selectCompanyDashboard,
} from "../../../redux/slices/companyDashboardSlice";

import { formatDate } from "../../../modules/feedbacker/feedback-module/feedbackModule";
import { Link } from "react-router-dom";
import { selectUser } from "../../../redux/slices/userSlice";
import { getInitials } from "../../../shared/lib/getInitials";

function ServiceRequestInfoModel({
  setstate,
  setPopup,
  model = false,
  show,
  setShow,
  id,
  setId,
  dashboard,
}) {
  const dispatch = useDispatch();
  const personalState = useSelector(selectPersonalDashboard);
  const companyState = useSelector(selectCompanyDashboard);
  const { requestDetailsData: personalRequestDetails } = personalState;
  const { requestDetailsData: companyRequestDetails } = companyState;
  const [Words, setWords] = useState("");
  const [reason, setReason] = useState();
  const [detailsData, setDetailsData] = useState(personalRequestDetails);
  const [openbutton, setOpenbutton] = useState(false);
  const [commentsData, setCommentsData] = useState(false);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (dashboard === "companyDahboard") {
      dispatch(getUsageRequestDataDetails(id.service_id, id.id));
      setOpenbutton();
    } else if (dashboard === "personal_dashboard_request") {
      dispatch(getRequestDetails(id.reqid));
      setOpenbutton();
    } else {
      dispatch(getRequestDetails(id.id));
      setOpenbutton();
    }

    setCommentsData(false);
  }, [openbutton, commentsData]);

  useEffect(() => {
    if (dashboard === "companyDahboard") {
      setDetailsData(companyRequestDetails);
    } else if (dashboard === "personal_dashboard_request") {
      setDetailsData(personalRequestDetails);
    }
  }, [personalRequestDetails, companyRequestDetails]);

  const postComment = () => {
    if (dashboard === "companyDahboard") {
      dispatch(
        createRequestComments(id.id, {
          comment: {
            user_id: user?.profile?.id,
            content: Words,
          },
        })
      );
    } else if (dashboard === "personal_dashboard_request") {
      dispatch(
        createRequestComments(id.reqid, {
          comment: {
            user_id: user?.profile?.id,
            content: Words,
          },
        })
      );
    } else {
      dispatch(
        createRequestComments(id.id, {
          comment: {
            user_id: user?.profile?.id,
            content: Words,
          },
        })
      );
    }

    setCommentsData(true);
    setWords("");
  };

  const handleInput = useCallback(async (field, value) => {
    if (dashboard === "companyDahboard") {
      await dispatch(
        statusUpdateRequest(id.id, { status: value, user_id: id.userId || {} })
      );
      setOpenbutton(!openbutton);
    } else if (dashboard === "personal_dashboard_request") {
      await dispatch(
        statusUpdateRequest(id.reqid, {
          status: value,
          user_id: id.userId || {},
        })
      );
      setOpenbutton(!openbutton);
    } else {
      await dispatch(
        statusUpdateRequest(id.id, { status: value, user_id: id.userId || {} })
      );
      setOpenbutton(!openbutton);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      postComment();
    }
  };

  return (
    <div
      className={`flex flex-col bg-[#363D50] scroll-to-top rounded-[24px] ${
        model
          ? ""
          : "w-[350px] h-[650px] md:w-[591px] lg:w-[591px] xl:w-[591px] 2xl:w-[591px]"
      } overflow-y-scroll scrollbar-hide relative`}
    >
      <div className="sticky top-0 z-20 flex justify-between px-[24px] pt-[16px] bg-[#363D50]">
        <div className="flex flex-start items-center gap-[16px]">
          <img
            className="w-[48px] h-[48px] card_img rounded-lg"
            src={detailsData?.request?.image_url}
            alt="service-img"
          />
          <div className="flex flex-col">
            <span className="text-primary !text-left">
              {detailsData?.request?.service_name}
            </span>
            <span className="text-secondary !text-left">
              {detailsData?.request?.category}
            </span>
          </div>
        </div>

        <div className="flex gap-[43px]">
          {!model ? (
            <div className="flex h-[40px] justify-end items-center">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  setPopup(false);
                }}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className="flex h-[40px] justify-end items-center">
              <svg
                onClick={() => {
                  setShow(!show);
                }}
                className="cursor-pointer"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5001 3.08984L15.0001 7.58984V3.99984H13.0001V10.9998H20.0001V8.99984H16.4101L20.9101 4.49984L19.5001 3.08984ZM4.00009 12.9998V14.9998H7.59009L3.09009 19.4998L4.50009 20.9098L9.00009 16.4098V19.9998H11.0001V12.9998H4.00009Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <input type="text" className="absolute top-2 left-8 max-h-0" />
      <div className="flex flex-col px-[24px] py-[24px] gap-[24px]">
        <div className="w-full flex flex-col pb-[8px]">
          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Request Type
              </span>
            </div>
            <div>
              <Pill fill={true} className="!text-[12px] !py-[4px] 1px-[6px]">
                {detailsData?.request?.request_type}
              </Pill>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Status</span>
            </div>
            <div className="flex gap-[8px] items-center">
              {detailsData?.request?.status === "open" ? (
                <>
                  {" "}
                  <Pill
                    greenFill={true}
                    className="!text-[12px] !py-[4px] 1px-[6px]"
                  >
                    {detailsData?.request?.status}
                  </Pill>{" "}
                </>
              ) : (
                <>
                  <Pill
                    redFill={true}
                    className="!text-[12px] !py-[4px] 1px-[6px]"
                  >
                    {detailsData?.request?.status}
                  </Pill>
                </>
              )}
              <div className="flex gap-[8px] items-center">
                <RadioButton
                  small={true}
                  checked={detailsData?.request?.status === "open"}
                  onChange={() => handleInput("status", "open")}
                />
                <div className="flex items-center">
                  <span className="text-secondary !font-normal !leading-[20px]">
                    Open
                  </span>
                </div>
              </div>

              <div className="flex gap-[8px] items-center">
                <RadioButton
                  small={true}
                  checked={detailsData?.request?.status === "closed"}
                  onChange={() => handleInput("status", "closed")}
                />
                <div className="flex items-center">
                  <span className="text-secondary !font-normal !leading-[20px]">
                    Close
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Requested By
              </span>
            </div>
            <div className="flex gap-[4px] items-center">
              <div className="rounded-full bg-gradient-to-r from-blue-100 via-blue-100 to-white !text-[#044597] !text-[11px] !text-center text-primary">
                <span className="flex justify-center items-center w-[25.5px] h-[24px] ">
                  <span>
                    {getInitials(detailsData?.request?.requested_by_string)}
                  </span>
                </span>
              </div>
              <div>
                <span className="text-primary !text-left !font-normal !text-[14px]">
                  {detailsData?.request?.requested_by_string}
                </span>
              </div>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Service Manager
              </span>
            </div>
            <div className="flex gap-[4px]">
              <span className="text-primary !text-left !font-normal !text-[14px]">
                {detailsData?.request?.service_managers_string}
              </span>
              {/* <span className='text-primary !text-left !font-normal !text-[14px]'>Ronald Richards,</span>
              <span className='text-primary !text-left !font-normal !text-[14px]'>Richards Miles,</span> */}
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Requested</span>
            </div>
            <div className="flex gap-[4px]">
              <span className="text-primary !text-left !font-normal !text-[14px]">
                {detailsData?.request?.requested_on}
              </span>
            </div>
          </div>
        </div>

        <div className="w-full h-[2px] bg-[#41485C]" />

        <div className="w-full flex flex-col gap-[16px]">
          <div className="flex gap-[4px] items-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 7C3.5 6.17157 4.17157 5.5 5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V15C20.5 15.8284 19.8284 16.5 19 16.5H14.8284C14.1654 16.5 13.5295 16.7634 13.0607 17.2322L9.14645 21.1464C8.95118 21.3417 8.95118 21.6583 9.14645 21.8536C9.34171 22.0488 9.65829 22.0488 9.85355 21.8536L13.7678 17.9393C14.0491 17.658 14.4306 17.5 14.8284 17.5H19C20.3807 17.5 21.5 16.3807 21.5 15V7C21.5 5.61929 20.3807 4.5 19 4.5H5C3.61929 4.5 2.5 5.61929 2.5 7V15C2.5 16.3807 3.61929 17.5 5 17.5H8.5C8.77614 17.5 9 17.2761 9 17C9 16.7239 8.77614 16.5 8.5 16.5H5C4.17157 16.5 3.5 15.8284 3.5 15V7ZM8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5H8.01C8.28614 11.5 8.51 11.2761 8.51 11C8.51 10.7239 8.28614 10.5 8.01 10.5H8ZM12 10.5C11.7239 10.5 11.5 10.7239 11.5 11C11.5 11.2761 11.7239 11.5 12 11.5H12.01C12.2861 11.5 12.51 11.2761 12.51 11C12.51 10.7239 12.2861 10.5 12.01 10.5H12ZM16 10.5C15.7239 10.5 15.5 10.7239 15.5 11C15.5 11.2761 15.7239 11.5 16 11.5H16.01C16.2861 11.5 16.51 11.2761 16.51 11C16.51 10.7239 16.2861 10.5 16.01 10.5H16Z"
                fill="#fff"
              />
            </svg>
            <div className="flex items-center">
              <span className="text-secondary !text-left !text-[11px] !font-semibold">
                Survey response
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-[13px]">
            {/*<div className='flex items-center'><span className='text-primary !text-left !italic !text-[14px] !font-semibold !leading-[21px]'>{requestDetailsData?.request?.request_reason}</span></div>*/}
            <div className="flex items-center">
              <span className="text-primary !text-left !italic !text-[14px] !font-semibold !leading-[21px]">
                I would like to suggest a change on this service...
              </span>
            </div>

            {detailsData?.request?.request_type === "Opt-in" && (
              <>
                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type === "try_service"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      I want to try this service
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "try_service"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type === "better_than_other"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      I like it better than another tool I use
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "better_than_other"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type === "more_efficient"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      It will make my work more efficient
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "more_efficient"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={detailsData?.request?.reason_type === "other"}
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      Other
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "other"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>
              </>
            )}

            {detailsData?.request?.request_type === "Opt-out" && (
              <>
                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type ===
                      "not_use_this_service"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      I don’t use this service
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "not_use_this_service"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type ===
                      "expense_this_service"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      I personally expense this service for work
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "expense_this_service"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type ===
                      "service_not_provide"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      This service doesn’t provide what I need
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "service_not_provide"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={detailsData?.request?.reason_type === "other"}
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      Other
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "other"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>
              </>
            )}

            {detailsData?.request?.request_type === "change" && (
              <>
                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type === "better_suites"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      So it better suites my job
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "better_suites"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                {detailsData?.request?.reason_details && (
                  <div className="flex items-center">
                    <span className="text-secondary !text-left !font-normal !leading-[20px]">
                      {detailsData?.request?.reason_details}
                    </span>
                  </div>
                )}

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={
                      detailsData?.request?.reason_type === "make_it_cheaper"
                    }
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      To make it cheaper for the company
                    </span>
                  </div>
                </div>

                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "make_it_cheaper"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>

                <div className="flex gap-[8px] items-center">
                  <RadioButton
                    small={true}
                    white={true}
                    checked={detailsData?.request?.reason_type === "other"}
                  />
                  <div className="flex items-center">
                    <span className="text-secondary !font-normal !leading-[20px]">
                      Other
                    </span>
                  </div>
                </div>
                <div className="text-secondary !font-normal !leading-[20px] text-left">
                  {detailsData?.request?.reason_type === "other"
                    ? `"${detailsData?.request?.description}"`
                    : null}
                </div>
              </>
            )}
          </div>
        </div>

        <div className="w-full h-[2px] bg-[#41485C]" />

        <div className="flex flex-col gap-[24px] relative">
          <div className=" flex flex-col h-auto pt-[36px] overflow-y-auto scrollbar-hide gap-[12px]">
            <div className="flex absolute top-0 left-0 w-full gap-[4px] items-center bg-[#363D50]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25 1.25C6.5266 1.25165 4.87426 1.93701 3.65563 3.15563C2.43701 4.37426 1.75165 6.0266 1.75 7.75V13.0188C1.75 13.3453 1.87972 13.6585 2.11062 13.8894C2.34153 14.1203 2.6547 14.25 2.98125 14.25H8.25C9.97391 14.25 11.6272 13.5652 12.8462 12.3462C14.0652 11.1272 14.75 9.47391 14.75 7.75C14.75 6.02609 14.0652 4.37279 12.8462 3.15381C11.6272 1.93482 9.97391 1.25 8.25 1.25ZM8.25 12.75H3.25V7.75C3.25 6.76109 3.54324 5.79439 4.09265 4.97215C4.64206 4.1499 5.42295 3.50904 6.33658 3.1306C7.25021 2.75216 8.25555 2.65315 9.22545 2.84607C10.1954 3.039 11.0863 3.5152 11.7855 4.21447C12.4848 4.91373 12.961 5.80464 13.1539 6.77455C13.3469 7.74445 13.2478 8.74979 12.8694 9.66342C12.491 10.577 11.8501 11.3579 11.0279 11.9073C10.2056 12.4568 9.23891 12.75 8.25 12.75ZM10.75 6.75C10.75 6.94891 10.671 7.13968 10.5303 7.28033C10.3897 7.42098 10.1989 7.5 10 7.5H6.25C6.05109 7.5 5.86032 7.42098 5.71967 7.28033C5.57902 7.13968 5.5 6.94891 5.5 6.75C5.5 6.55109 5.57902 6.36032 5.71967 6.21967C5.86032 6.07902 6.05109 6 6.25 6H10C10.1989 6 10.3897 6.07902 10.5303 6.21967C10.671 6.36032 10.75 6.55109 10.75 6.75ZM10.75 9.25C10.75 9.44891 10.671 9.63968 10.5303 9.78033C10.3897 9.92098 10.1989 10 10 10H6.25C6.05109 10 5.86032 9.92098 5.71967 9.78033C5.57902 9.63968 5.5 9.44891 5.5 9.25C5.5 9.05109 5.57902 8.86032 5.71967 8.71967C5.86032 8.57902 6.05109 8.5 6.25 8.5H10C10.1989 8.5 10.3897 8.57902 10.5303 8.71967C10.671 8.86032 10.75 9.05109 10.75 9.25Z"
                  fill="#A5AEBC"
                />
              </svg>
              <div className="flex items-center">
                <span className="text-secondary !text-left !text-[11px] !font-semibold">
                  COMMENTS
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-[12px]">
              {/*      <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request opened - {requestDetailsData?.request?.requested_on}</span>
              </div>*/}
              {detailsData?.request?.comments.length > 0
                ? detailsData?.request?.comments.map((item, index) => (
                    <div key={index} className="flex flex-start gap-[10px]">
                      <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20">
                        <span className="flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]">
                          <span>{getInitials(item.commented_by)}</span>
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex gap-[4px]">
                          <span className="text-secondary !text-left !text-[#FFFFFF] !font-medium !leading-[21px]">
                            {item.commented_by}
                          </span>
                          <span className="text-secondary !text-left !font-medium !leading-[21px]">
                            {formatDate(item.commented_on)}
                          </span>
                        </div>
                        <span className="text-secondary !text-left">
                          {item.content}
                        </span>
                      </div>
                    </div>
                  ))
                : null}

              {/*              <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request closed - Oct 26, 2022</span>
              </div>*/}
            </div>

            {/* <div className='flex flex-col gap-[12px]'>

              <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request opened - Oct 22, 2022</span>
              </div>

              <div className="flex flex-start gap-[10px]">
                <div className='w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20'>
                  <span className='flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]'><span>EP</span></span>
                </div>
                <div className="flex flex-col">
                  <div className='flex gap-[4px]'>
                    <span className="text-secondary !text-left !text-[#FFFFFF] !font-medium !leading-[21px]">Eleanor Pena</span>
                    <span className="text-secondary !text-left !font-medium !leading-[21px]">Oct 22, 2020</span>
                  </div>
                  <span className="text-secondary !text-left">
                    I wanted to prioritize this
                  </span>
                </div>
              </div>

              <div className="flex flex-start gap-[10px]">
                <div className='w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#46F9CE] bg-opacity-20'>
                  <span className='flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#46F9CE]'><span>FM</span></span>
                </div>
                <div className="flex flex-col">
                  <div className='flex gap-[4px]'>
                    <span className="text-secondary !text-left !text-[#FFFFFF] !font-medium !leading-[21px]">Floyd Miles</span>
                    <span className="text-secondary !text-left !font-medium !leading-[21px]">Oct 23, 2020</span>
                  </div>
                  <span className="text-secondary !text-left">
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
                  </span>
                </div>
              </div>


              <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request closed - Oct 26, 2022</span>
              </div>

            </div> */}
          </div>

          <div className="w-full flex flex-col p-4 border-[1px] border-[#596073] rounded-[16px]">
            <textarea
              className="!h-[100px] p-0 scrollbar-hide w-full resize-none border-none focus:ring-0 bg-[#363D50]"
              onChange={(e) => {
                setWords(e.target.value);
              }}
              onKeyDown={handleKeyDown}
              placeholder={"Write comment"}
              value={Words}
            />
            <hr className="border-[#41485C]" />
            <div className="flex justify-between pt-[17px]">
              <span className="text-secondary text-left">
                {Words.length}/500
              </span>
              <Button
                onClick={postComment}
                className={"!w-[fit-content] !h-[fit-content]"}
                secondary={true}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceRequestInfoModel;
