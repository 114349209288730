import { useNavigate } from "react-router-dom";
import Button from "../../Buttons/button";
import { selectPersonalDashboard } from "../../../redux/slices/personalDashboardSlice";
import { useSelector } from "react-redux";
// import GifPlayerModel from "./gifPlayer";
// import Gif from "../../../assets/images/Rectangle.gif";

export default function ServiceOptInSuccessModel({ setstate, setPopup }) {
  const navigate = useNavigate();
  const State = useSelector(selectPersonalDashboard);
  const { updaterequestsData } = State;
  return (
    <div>
      {/* <div className="fixed top-0 w-[100%] h-[100vh]">
        <GifPlayerModel className="bg-transparent" gif={Gif} autoplay={true} />
      </div> */}
      <div className="transform rounded-[24px] bg-[#363D50] transition-all md:w-[458px] md:h-[400px] flex">
        <div className="flex flex-col px-[24px] py-[24px]">
          <div className="flex justify-center items-center md:w-[410px] md:h-[143px]">
            <div className="relative">
              <div className="p-[10px] bg-[#242B3E] rounded-[16px]">
                <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.16683 16.3333C8.16683 14.4003 9.73383 12.8333 11.6668 12.8333H44.3335C46.2665 12.8333 47.8335 14.4003 47.8335 16.3333V35C47.8335 36.933 46.2665 38.5 44.3335 38.5H34.5998C33.0527 38.5 31.569 39.1146 30.475 40.2085L21.3419 49.3417C20.8863 49.7973 20.8863 50.536 21.3419 50.9916C21.7975 51.4472 22.5362 51.4472 22.9918 50.9916L32.125 41.8585C32.7813 41.2021 33.6716 40.8333 34.5998 40.8333H44.3335C47.5552 40.8333 50.1668 38.2217 50.1668 35V16.3333C50.1668 13.1117 47.5552 10.5 44.3335 10.5H11.6668C8.44517 10.5 5.8335 13.1117 5.8335 16.3333V35C5.8335 38.2217 8.44517 40.8333 11.6668 40.8333H19.8335C20.4778 40.8333 21.0002 40.311 21.0002 39.6667C21.0002 39.0223 20.4778 38.5 19.8335 38.5H11.6668C9.73383 38.5 8.16683 36.933 8.16683 35V16.3333ZM18.6668 24.5C18.0225 24.5 17.5002 25.0223 17.5002 25.6667C17.5002 26.311 18.0225 26.8333 18.6668 26.8333H18.6902C19.3345 26.8333 19.8568 26.311 19.8568 25.6667C19.8568 25.0223 19.3345 24.5 18.6902 24.5H18.6668ZM28.0002 24.5C27.3558 24.5 26.8335 25.0223 26.8335 25.6667C26.8335 26.311 27.3558 26.8333 28.0002 26.8333H28.0235C28.6678 26.8333 29.1902 26.311 29.1902 25.6667C29.1902 25.0223 28.6678 24.5 28.0235 24.5H28.0002ZM37.3335 24.5C36.6892 24.5 36.1668 25.0223 36.1668 25.6667C36.1668 26.311 36.6892 26.8333 37.3335 26.8333H37.3568C38.0012 26.8333 38.5235 26.311 38.5235 25.6667C38.5235 25.0223 38.0012 24.5 37.3568 24.5H37.3335Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="fixed top-[80px] left-[190px] md:top-[89px] md:left-[248px]">
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    width="32"
                    height="32"
                    rx="16"
                    fill="#6BE261"
                    fill-opacity="0.08"
                  />
                  <path
                    d="M13.8331 18.1332L21.6997 10.2666C21.9442 10.0221 22.2553 9.8999 22.6331 9.8999C23.0109 9.8999 23.322 10.0221 23.5664 10.2666C23.8108 10.511 23.9331 10.8221 23.9331 11.1999C23.9331 11.5777 23.8108 11.8888 23.5664 12.1332L14.7664 20.9332C14.4997 21.1999 14.1886 21.3332 13.8331 21.3332C13.4775 21.3332 13.1664 21.1999 12.8997 20.9332L9.43307 17.4666C9.18863 17.2221 9.06641 16.911 9.06641 16.5332C9.06641 16.1555 9.18863 15.8443 9.43307 15.5999C9.67752 15.3555 9.98863 15.2332 10.3664 15.2332C10.7442 15.2332 11.0553 15.3555 11.2997 15.5999L13.8331 18.1332Z"
                    fill="#6BE261"
                  />
                </svg>
              </div>
            </div>
          </div>

          <div className="md:w-[410px] md:h-[191px] flex flex-col gap-[32px]">
            <div className="flex flex-col gap-[4px] px-[14px]">
              <span className="text-primary">
                Opt-in request submitted for {updaterequestsData?.request?.name || updaterequestsData?.data?.request?.service_name}!
              </span>

              <span className="text-secondary">
                The service manager will review your request and may contact you
                for more details. Keep track of your request from your requests
                page.
              </span>
            </div>
            <div className="flex flex-col md:flex-row justify-center items-center gap-[16px]">
              <Button
                onClick={() => {
                  navigate("/personal_dashboard/requests", {
                    state: {
                      openPopup: true,
                      locationSetId: {
                        id: updaterequestsData?.data?.request?.id,
                        userId: updaterequestsData?.data?.request?.user_id,
                      },
                    },
                  });
                }}
                className={"!w-[197px]"}
                hoverColor={true}
                position={"center"}
                outlined={true}
                secondary={true}
              >
                View Request
              </Button>
              <Button
                onClick={() => {
                  setPopup(false);
                }}
                className={"!w-[197px]"}
                fill={true}
                position={"center"}
                hoverColor={true}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
