import PropTypes from 'prop-types';
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useDispatch } from 'react-redux';
import { updateSubmissionData } from '../../redux/slices/personalDashboardSlice';

const defaultCategories = [
  { name: 'Choose Category',id:0},
  { name: 'Category 1', id: 1 },
  { name: 'Category 2', id: 2 },
  { name: 'Category 3', id: 3 },
];

const Select = ({category, onCategoryChange, services, selectedService,readOnly}) => {
  const [items, setItems] = useState(defaultCategories);
  const [selected, setSelected] = useState(null);
  const [isService, setIsService] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (category && category.categories ) {
      setItems(category.categories);
      setSelected(category.categories[0]);
      setIsService(false);
    } else if(services && services.suggestions ){
      setItems(services.suggestions);
      setSelected(services.suggestions[0]);
      setIsService(true);
    } else {
      // setSelected(category.categories[0]);
      setIsService(false);
    }
    if (selectedService) {
      const matchedCategory = items.find((item) => item.id === selectedService.category_id);
      setSelected(matchedCategory  || items[0]);
    }
  }, [category, services, items, selectedService]);

  const handleInput = (field, value) => {
    dispatch(updateSubmissionData({ field, value }));
  };

  useEffect(() => {
    if(selected){
      handleInput(isService ? 'service_to_replace_id' : 'category_ids', selected.id);
    }
    if (onCategoryChange) {
      onCategoryChange(selected?.name);
    }
  }, [selected, isService, dispatch, onCategoryChange]);

  return (
    <div>
      <Listbox value={selected} onChange={setSelected} disabled={readOnly}>
        <div className="relative mt-1">
          <Listbox.Button className="custom_text relative bg-[#363D50] border border-[#596073] rounded-[12px] focus:ring-[#596073] focus:border-[#596073] block w-full px-[16px] py-[12px] text-left">
            <span className="">{selected?.name ?? 'Software'}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-secondary"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-40 scrollbar-hide w-full overflow-auto rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
              {items.map((item, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `custom_text relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-[#596073] text-secondary' : 'text-secondary'
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`flex flex-start${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {item?.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

Select.propTypes = {
  category: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })),
  }),
  services: PropTypes.shape({
    services: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      category_name: PropTypes.string,
    })),
  }),
  selectedService: PropTypes.object,
};

export default Select;
