import React, { useState } from "react";

function Second({ Words, setWords }) {
  return (
    <div className="flex flex-col md:w-[536px] md:h-[390px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-col gap-[4px]">
        <span className="text-primary text-left !text-[18px] !leading-[27px]">
          Tell us more about how:
        </span>
        <span className="text-left !text-[18px] !leading-[27px] font-normal italic">
          ”I don’t use this service”
        </span>
      </div>
      <div className="flex flex-col p-[2px] border-[1px] border-[#596073] rounded-[16px] md:w-[520px] md:h-[257px]">
        <textarea
          className="!h-[220px] placeholder:text-[#A5AEBC]  placeholder:!text-[18px] w-full resize-none border-none focus:ring-0 rounded-[12px] bg-[#363D50]"
          onChange={(e) => {
            setWords(e.target.value);
          }}
          placeholder={"Its features? Your subrscription? Anything!"}
        />
        <div className="flex flex-start pt-[40px] pb-2.5 pl-[12px]">
          <span className="text-secondary text-left">{Words?.length}/500</span>
        </div>
      </div>
    </div>
  );
}

export default Second;
