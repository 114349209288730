import React, { useState, useEffect, useCallback } from "react";
import Pill from "../../Pill/pill";
import Button from "../../Buttons/button";
import RadioButton from "../../RadioButton/RadioButton";
import {
  companySubmissionDetail,
  selectCompanyDashboard,
} from "../../../redux/slices/companyDashboardSlice";
import {
  createSubmissionComments,
  selectPersonalDashboard,
  statusUpdateSubmission,
} from "../../../redux/slices/personalDashboardSlice";
import { selectUser } from "../../../redux/slices/userSlice";

import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../modules/feedbacker/feedback-module/feedbackModule";
import { getInitials } from "../../../shared/lib/getInitials";

function SubmittedServicesInfoModel({
  setstate,
  setPopup,
  model = false,
  show,
  setShow,
  tablename,
  id,
}) {
  const [Words, setWords] = useState("");
  const [openbutton, setOpenbutton] = useState(false);
  const [commentsData, setCommentsData] = useState(false);
  const userData = useSelector(selectUser);
  const personalData = useSelector(selectPersonalDashboard);
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);

  const { submissionDetailsData } = personalData;
  const { companySubmissionDetailData } = State;
  const { submission } = companySubmissionDetailData || {};
  const { userDetails } = userData;

  useEffect(() => {
    dispatch(companySubmissionDetail(id.id, id.user_id));
    setOpenbutton();
    setCommentsData(false);
  }, [commentsData, openbutton, id.id, id.user_id, dispatch]);

  const postComment = () => {
    dispatch(
      createSubmissionComments(id.id, {
        comment: {
          user_id: userDetails?.user?.id,
          content: Words,
        },
      })
    );
    // setPopup(false);
    setCommentsData(true);
    setWords("");
  };

  const initials = getInitials(submission?.submitted_by_detail);

  const handleInput = useCallback(
    async (field, value) => {
      await dispatch(
        statusUpdateSubmission(id.id, {
          status: value,
          user_id: id.user_id || -1,
        })
      );
      setOpenbutton(!openbutton);
    },
    [dispatch, id.id, id.user_id, openbutton]
  );

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      postComment();
    }
  };

  return (
    <div
      className={`flex flex-col bg-[#363D50] rounded-[24px] relative w-[350px] h-[650px] scrollbar-hide md:w-[591px] lg:w-[591px] xl:w-[591px] 2xl:w-[591px] overflow-y-scroll scrollbar-hide`}
    >
      <div className="sticky left-0 right-0 top-0 bg-[#363D50]">
        <div className="flex items-center justify-between px-[24px] py-[16px]">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] w-[48px] h-[48px] rounded-[16px] ">
              <svg
                width="14"
                height="19"
                viewBox="0 0 14 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.375 6.625C1.375 3.5184 3.8934 1 7 1C10.1066 1 12.625 3.5184 12.625 6.625C12.625 7.84842 12.235 8.97927 11.5725 9.90195C10.8089 10.9654 10 12.257 10 13.739V14H7.5V10.125C7.5 10.0372 7.47735 9.9546 7.43756 9.88286C7.75365 9.78754 8.04336 9.58635 8.24808 9.27927L9.41603 7.52735C9.5692 7.29759 9.50711 6.98715 9.27735 6.83397C9.04759 6.6808 8.73715 6.74289 8.58397 6.97265L7.41603 8.72458C7.21811 9.02144 6.78189 9.02144 6.58398 8.72458L5.41603 6.97265C5.26285 6.74289 4.95241 6.6808 4.72265 6.83397C4.49289 6.98715 4.4308 7.29759 4.58397 7.52735L5.75192 9.27927C5.95664 9.58635 6.24635 9.78754 6.56244 9.88286C6.52265 9.9546 6.5 10.0372 6.5 10.125V14H4V13.739C4 12.257 3.19112 10.9654 2.42753 9.90195C1.765 8.97927 1.375 7.84842 1.375 6.625ZM4 15H7H10V16C10 16.8284 9.32843 17.5 8.5 17.5H5.5C4.67157 17.5 4 16.8284 4 16V15ZM3 14.5V13.739C3 12.6058 2.37703 11.5461 1.61525 10.4852C0.83471 9.39819 0.375 8.06454 0.375 6.625C0.375 2.96611 3.34111 0 7 0C10.6589 0 13.625 2.96611 13.625 6.625C13.625 8.06454 13.1653 9.39819 12.3847 10.4852C11.623 11.5461 11 12.6058 11 13.739V14.5V16C11 17.3807 9.88071 18.5 8.5 18.5H5.5C4.11929 18.5 3 17.3807 3 16V14.5Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="flex items-center">
              <span className="text-primary !text-left">
                {submission?.type ?? "New"} Service Submission
              </span>
            </div>
          </div>

          {!model ? (
            <div className="flex h-[40px] justify-end items-center">
              <svg
                className="cursor-pointer"
                onClick={() => {
                  setPopup(false);
                }}
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className="flex h-[40px] justify-end items-center">
              <svg
                onClick={() => {
                  setShow(!show);
                }}
                className="cursor-pointer"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5001 3.08984L15.0001 7.58984V3.99984H13.0001V10.9998H20.0001V8.99984H16.4101L20.9101 4.49984L19.5001 3.08984ZM4.00009 12.9998V14.9998H7.59009L3.09009 19.4998L4.50009 20.9098L9.00009 16.4098V19.9998H11.0001V12.9998H4.00009Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="h-[2px] bg-[#41485C] absolute left-[24px] right-[24px]" />
      </div>

      <div className="flex flex-col px-[24px] py-[24px] gap-[24px]">
        <div className="w-full flex flex-col pb-[8px]">
          <div className="flex items-center">
            <span className="text-primary !text-left !text-[24px] !leading-[36px] mb-[10px]">
              {submission?.service_name}
            </span>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Submission Type
              </span>
            </div>
            <div>
              <Pill fill={true}>{submission?.type}</Pill>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Category</span>
            </div>
            <div className="flex gap-[8px] items-center">
              {submission?.categories_name_array.map((item, index) => (
                <Pill key={index} outline={true}>
                  {" "}
                  {item}{" "}
                </Pill>
              ))}
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Status</span>
            </div>
            <div className="flex gap-[8px] items-center">
              {companySubmissionDetailData?.submission?.status === "open" ? (
                <>
                  {" "}
                  <Pill
                    greenFill={true}
                    className="text-[12px] py-[4px] px-[11px]"
                  >
                    {companySubmissionDetailData?.submission?.status}
                  </Pill>{" "}
                </>
              ) : (
                <>
                  <Pill
                    redFill={true}
                    className="text-[12px] py-[4px] px-[11px]"
                  >
                    {companySubmissionDetailData?.submission?.status}
                  </Pill>
                </>
              )}
              <div className="flex gap-[8px] items-center">
                <RadioButton
                  small={true}
                  checked={
                    companySubmissionDetailData?.submission?.status === "open"
                  }
                  onChange={() => handleInput("status", "open")}
                />
                <div className="flex items-center">
                  <span className="text-secondary !font-normal !leading-[20px]">
                    Open
                  </span>
                </div>
              </div>

              <div className="flex gap-[8px] items-center">
                <RadioButton
                  small={true}
                  checked={
                    companySubmissionDetailData?.submission?.status === "closed"
                  }
                  onChange={() => handleInput("status", "closed")}
                />
                <div className="flex items-center">
                  <span className="text-secondary !font-normal !leading-[20px]">
                    Close
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Website</span>
            </div>
            <div className="flex gap-[8px] items-center">
              <Pill link={true} className={"!px-[0px]"} />
              <span className="text-primary !text-[#FFD74A] !text-left !font-normal !text-[14px]">
                {submission?.website_url}
              </span>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Expensed</span>
            </div>
            <div className="flex gap-[8px] pl-0  px-[24px]">
              <span>
                {submission?.is_expensed ? (
                  <Pill fill={true} check={true} greenFill={true} />
                ) : (
                  <Pill fill={true} cross={true} redFill={true} />
                )}
              </span>
              <span className="text-primary !text-left !font-normal !text-[14px]">
                {submission?.expensed_detail}
              </span>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">
                Submitted By{" "}
              </span>
            </div>
            <div className="flex gap-2  items-center">
              <div className="rounded-full bg-[#2284FF]/[15%] to-white !text-[#2284FF] !text-[11px] !text-center w-[24px] h-[24px] text-primary flex justify-center items-center pl-[2px] pt-[1px]">
                {initials}
              </div>
              <div>
                <span className="text-primary !text-left !font-normal !text-[14px]">
                  {submission?.submitted_by_detail}
                </span>
              </div>
            </div>
          </div>

          <div className="flex py-[8px] gap-[4px] items-center justify-left">
            <div className="w-[156px] h-[20px] flex items-center !text-left">
              <span className="text-secondary !leading-[20px]">Submitted</span>
            </div>
            <div className="flex gap-[4px]">
              <span className="text-primary !text-left !font-normal !text-[14px]">
                {submission?.submitted_on}
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col py-[8px] gap-[8px]">
          <span className="text-primary !text-left !leading-[21px] !font-extrabold">
            Reason for service suggestion
          </span>
          <span className="text-secondary !text-left !leading-[21px]">
            {" "}
            {submission?.suggesting_reason_detail}{" "}
          </span>
          {submission?.submission_details !== "Not Provided" && (
            <span className="text-secondary !text-left !leading-[20px]">
              {" "}
              {submission?.submission_details}
            </span>
          )}
        </div>
        <div className="w-full h-[2px] bg-[#41485C]" />
        <div className="flex flex-col gap-[24px]">
          <div className=" flex flex-col h-auto overflow-y-auto scrollbar-hide gap-[12px]">
            <div className="flex w-full gap-[4px] items-center bg-[#363D50]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.25 1.25C6.5266 1.25165 4.87426 1.93701 3.65563 3.15563C2.43701 4.37426 1.75165 6.0266 1.75 7.75V13.0188C1.75 13.3453 1.87972 13.6585 2.11062 13.8894C2.34153 14.1203 2.6547 14.25 2.98125 14.25H8.25C9.97391 14.25 11.6272 13.5652 12.8462 12.3462C14.0652 11.1272 14.75 9.47391 14.75 7.75C14.75 6.02609 14.0652 4.37279 12.8462 3.15381C11.6272 1.93482 9.97391 1.25 8.25 1.25ZM8.25 12.75H3.25V7.75C3.25 6.76109 3.54324 5.79439 4.09265 4.97215C4.64206 4.1499 5.42295 3.50904 6.33658 3.1306C7.25021 2.75216 8.25555 2.65315 9.22545 2.84607C10.1954 3.039 11.0863 3.5152 11.7855 4.21447C12.4848 4.91373 12.961 5.80464 13.1539 6.77455C13.3469 7.74445 13.2478 8.74979 12.8694 9.66342C12.491 10.577 11.8501 11.3579 11.0279 11.9073C10.2056 12.4568 9.23891 12.75 8.25 12.75ZM10.75 6.75C10.75 6.94891 10.671 7.13968 10.5303 7.28033C10.3897 7.42098 10.1989 7.5 10 7.5H6.25C6.05109 7.5 5.86032 7.42098 5.71967 7.28033C5.57902 7.13968 5.5 6.94891 5.5 6.75C5.5 6.55109 5.57902 6.36032 5.71967 6.21967C5.86032 6.07902 6.05109 6 6.25 6H10C10.1989 6 10.3897 6.07902 10.5303 6.21967C10.671 6.36032 10.75 6.55109 10.75 6.75ZM10.75 9.25C10.75 9.44891 10.671 9.63968 10.5303 9.78033C10.3897 9.92098 10.1989 10 10 10H6.25C6.05109 10 5.86032 9.92098 5.71967 9.78033C5.57902 9.63968 5.5 9.44891 5.5 9.25C5.5 9.05109 5.57902 8.86032 5.71967 8.71967C5.86032 8.57902 6.05109 8.5 6.25 8.5H10C10.1989 8.5 10.3897 8.57902 10.5303 8.71967C10.671 8.86032 10.75 9.05109 10.75 9.25Z"
                  fill="#A5AEBC"
                />
              </svg>
              <div className="flex items-center">
                <span className="text-secondary !text-left !text-[11px] !font-semibold">
                  COMMENTS
                </span>
              </div>
            </div>

            <div className="flex flex-col gap-[12px]">
              {/* <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request opened - {requestDetailsData?.request?.requested_on}</span>
              </div>*/}
              {companySubmissionDetailData?.submission?.comments?.length > 0 &&
                companySubmissionDetailData?.submission?.comments.map(
                  (item) => (
                    <div
                      key={item.commented_on}
                      className="flex flex-start gap-[10px]"
                    >
                      <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20">
                        <span className="flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]">
                          <span>{getInitials(item.commented_by)}</span>
                        </span>
                      </div>
                      <div className="flex flex-col">
                        <div className="flex gap-[4px]">
                          <span className="text-secondary !text-left !text-[#FFFFFF] !font-medium !leading-[21px]">
                            {item.commented_by}
                          </span>
                          <span className="text-secondary !text-left !font-medium !leading-[21px]">
                            {formatDate(item.commented_on)}
                          </span>
                        </div>
                        <span className="text-secondary !text-left">
                          {item.content}
                        </span>
                      </div>
                    </div>
                  )
                )}
              {/* <div className='flex items-center'>
                <span className='text-secondary !italic !text-[12px] !font-normal !leading-[18px]'>Request closed - Oct 26, 2022</span>
              </div>*/}
            </div>

            <div className="w-full flex flex-col p-4 border-[1px] border-[#596073] rounded-[16px]">
              <textarea
                className="!h-[100px] p-0 scrollbar-hide w-full resize-none border-none focus:ring-0 bg-[#363D50]"
                onChange={(e) => {
                  setWords(e.target.value);
                }}
                onKeyDown={handleKeyDown}
                placeholder={"Write comment"}
                value={Words}
              />
              <hr className="border-[#41485C]" />
              <div className="flex justify-between pt-[17px]">
                <span className="text-secondary text-left">
                  {Words.length}/500
                </span>
                <Button
                  onClick={postComment}
                  className={"!w-[fit-content] !h-[fit-content]"}
                  secondary={true}
                >
                  Send
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubmittedServicesInfoModel;
