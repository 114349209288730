import React from 'react'
import First from './tabs/first';
import Second from './tabs/second';

function Tabs({Tab, setReason,setWords,Words, setReasonType, reasonType}) {

  return (
    <div>
      {Tab === 1 && <First setReason={setReason} setReasonType={setReasonType} reasonType={reasonType}/>}
      {Tab === 2 && <Second setWords={setWords} Words={Words}/>}
    </div>
  )
}

export default Tabs