import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import cn from "classnames";

import { getRandomHex } from "../../../shared/lib/getRandomHex";
import { getInitials } from "../../../shared/lib/getInitials";

function EmployeeCell({ data }) {
  const [hex, hexAlpha] = getRandomHex();
  const userInitialsColor = hex;
  const userInitialsBg = hexAlpha;

  return (
    <div
      className="flex pl-[48px] relative flex-start items-center gap-[16px]"
      style={{ cursor: "pointer" }}
    >
      <div
        style={{
          backgroundColor: userInitialsBg,
          color: userInitialsColor,
        }}
        className="absolute top-[50%] font-bold -translate-y-1/2 flex justify-center items-center left-0 w-[32px] h-[32px] rounded-full"
      >
        {getInitials(data.name)}
      </div>
      <div className="flex flex-col">
        <span className="text-secondary !text-left !text-[#FFFFFF] inline_text !font-medium">
          {data.name}
        </span>
        <span className="text-secondary !text-left inline_text">
          {data.work}
        </span>
      </div>
    </div>
  );
}

function TableCell({ item, value }) {
  switch (item) {
    case "employee":
      return <EmployeeCell data={value} />;
    default:
      return value;
  }
}

function TeamTableBody({
  tableData,
  setManagerId,
  handleNameChange,
  setFullName,
  fullName,
}) {
  const navigate = useNavigate();

  return (
    <tbody>
      {tableData.map((row, index) => (
        <tr
          key={index}
          className="hover:bg-[#434a5d] cursor-pointer rounded-[24px] text-left"
        >
          {Object.keys(row)
            .filter((item) => item !== "id")
            .map((item, index2, arr) => (
              <td
                key={index2}
                className={cn(
                  "text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium"
                )}
                onClick={() => {
                  if (index2 === arr.length - 1 && row.also_manager) {
                    // Execute your action when the condition is true
                    setManagerId(row.id);
                    handleNameChange(row.employee.name);
                  } else {
                    // Execute the default action when the condition is false
                    navigate(
                      `/personal_dashboard/teams/${row.id}/employee_report`
                    );
                  }
                }}
              >
                {index2 === arr.length - 1 && row.also_manager ? (
                  <Link
                    to={`/personal_dashboard/teams/${row.id}`}
                    className="custom_btn inline_text"
                  >
                    See Team Details
                  </Link>
                ) : (
                  <span>
                    <TableCell item={item} value={row[item]} />
                  </span>
                )}
              </td>
            ))}
        </tr>
      ))}
    </tbody>
  );
}

export default TeamTableBody;
