import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Button from "../../../../../components/Buttons/button";
import Pill from "../../../../../components/Pill/pill";
import { formatDate } from "../../../../feedbacker/feedback-module/feedbackModule";
import {
  getUsageRequestData,
  getUsageFeedbackData,
  getCompanyServicesRequest,
  getCompanyServicesFeedback,
} from "../../../../../redux/slices/companyDashboardSlice";

import negativeHoverIcon from "./../../../../../assets/images/nagative_hover.png";
import neutralHoverIcon from "./../../../../../assets/images/neutral_hover.png";
import smileyHoverIcon from "./../../../../../assets/images/smiley_hover.png";

function ServicesRequestAndFeedbackCards({
  setPopup,
  setState,
  data,
  setFeedbackID,
  setRequestId,
  id,
}) {
  const dispatch = useDispatch();
  const date = formatDate(data?.feedback?.updated_at);
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.66634 9.33333C4.66634 8.22876 5.56177 7.33333 6.66634 7.33333H25.333C26.4376 7.33333 27.333 8.22876 27.333 9.33333V20C27.333 21.1046 26.4376 22 25.333 22H19.7709C18.8869 22 18.039 22.3512 17.4139 22.9763L12.1949 28.1953C11.9346 28.4556 11.9346 28.8777 12.1949 29.1381C12.4553 29.3984 12.8774 29.3984 13.1377 29.1381L18.3567 23.9191C18.7318 23.544 19.2405 23.3333 19.7709 23.3333H25.333C27.174 23.3333 28.6663 21.8409 28.6663 20V9.33333C28.6663 7.49238 27.174 6 25.333 6H6.66634C4.82539 6 3.33301 7.49238 3.33301 9.33333V20C3.33301 21.8409 4.82539 23.3333 6.66634 23.3333H11.333C11.7012 23.3333 11.9997 23.0349 11.9997 22.6667C11.9997 22.2985 11.7012 22 11.333 22H6.66634C5.56177 22 4.66634 21.1046 4.66634 20V9.33333ZM10.6663 14C10.2982 14 9.99967 14.2985 9.99967 14.6667C9.99967 15.0349 10.2982 15.3333 10.6663 15.3333H10.6797C11.0479 15.3333 11.3463 15.0349 11.3463 14.6667C11.3463 14.2985 11.0479 14 10.6797 14H10.6663ZM15.9997 14C15.6315 14 15.333 14.2985 15.333 14.6667C15.333 15.0349 15.6315 15.3333 15.9997 15.3333H16.013C16.3812 15.3333 16.6797 15.0349 16.6797 14.6667C16.6797 14.2985 16.3812 14 16.013 14H15.9997ZM21.333 14C20.9648 14 20.6663 14.2985 20.6663 14.6667C20.6663 15.0349 20.9648 15.3333 21.333 15.3333H21.3463C21.7145 15.3333 22.013 15.0349 22.013 14.6667C22.013 14.2985 21.7145 14 21.3463 14H21.333Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Your Service Requests
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Action you’ve asked your company to take
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[270px] px-[16px] overflow-y-scroll scrollbar-hide">
          {data?.requests?.map((item) => (
            <div className="flex flex-col px-[8px]" key={item?.id}>
              <div
                onClick={() => {
                  setPopup(true);
                  setState(25);
                  setRequestId(item?.id && item?.id);
                }}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col h-[90px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <Pill fill={true}>{item?.request_type}</Pill>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {item?.comments_count === 0
                            ? "-"
                            : item.comments_count}
                        </span>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 1.25C7.0266 1.25165 5.37426 1.93701 4.15563 3.15563C2.93701 4.37426 2.25165 6.0266 2.25 7.75V13.0188C2.25 13.3453 2.37972 13.6585 2.61062 13.8894C2.84153 14.1203 3.1547 14.25 3.48125 14.25H8.75C10.4739 14.25 12.1272 13.5652 13.3462 12.3462C14.5652 11.1272 15.25 9.47391 15.25 7.75C15.25 6.02609 14.5652 4.37279 13.3462 3.15381C12.1272 1.93482 10.4739 1.25 8.75 1.25ZM8.75 12.75H3.75V7.75C3.75 6.76109 4.04324 5.79439 4.59265 4.97215C5.14206 4.1499 5.92295 3.50904 6.83658 3.1306C7.75021 2.75216 8.75555 2.65315 9.72545 2.84607C10.6954 3.039 11.5863 3.5152 12.2855 4.21447C12.9848 4.91373 13.461 5.80464 13.6539 6.77455C13.8469 7.74445 13.7478 8.74979 13.3694 9.66342C12.991 10.577 12.3501 11.3579 11.5279 11.9073C10.7056 12.4568 9.73891 12.75 8.75 12.75ZM11.25 6.75C11.25 6.94891 11.171 7.13968 11.0303 7.28033C10.8897 7.42098 10.6989 7.5 10.5 7.5H6.75C6.55109 7.5 6.36032 7.42098 6.21967 7.28033C6.07902 7.13968 6 6.94891 6 6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H10.5C10.6989 6 10.8897 6.07902 11.0303 6.21967C11.171 6.36032 11.25 6.55109 11.25 6.75ZM11.25 9.25C11.25 9.44891 11.171 9.63968 11.0303 9.78033C10.8897 9.92098 10.6989 10 10.5 10H6.75C6.55109 10 6.36032 9.92098 6.21967 9.78033C6.07902 9.63968 6 9.44891 6 9.25C6 9.05109 6.07902 8.86032 6.21967 8.71967C6.36032 8.57902 6.55109 8.5 6.75 8.5H10.5C10.6989 8.5 10.8897 8.57902 11.0303 8.71967C11.171 8.86032 11.25 9.05109 11.25 9.25Z"
                            fill="#A5AEBC"
                          />
                        </svg>
                        <span className="text-secondary">
                          {item?.requested_on}
                        </span>
                      </div>
                      {item?.status === "open" ? (
                        <Pill greenFill={true} className="text-[12px] py-[4px]">
                          {item?.status}
                        </Pill>
                      ) : (
                        <Pill redFill={true} className="text-[12px] py-[4px]">
                          {item?.status}
                        </Pill>
                      )}
                    </div>
                  </div>
                  <span className="px-1 text-secondary !leading-[20px]">
                    {item?.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            className={cn({
              invisible: !data?.requests?.length,
            })}
            onClick={() => {
              setPopup(true);
              setState(20);
              dispatch(
                getCompanyServicesRequest(data.service.id, data.user.id)
              );
            }}
            secondary
            hoverColor
          >
            View all
          </Button>
        </div>
      </div>

      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px]  max-w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.91063 9.66914C10.727 9.16154 11.7229 9.03114 12.6424 9.3114L19.3491 11.3554C20.2688 11.6357 20.7217 12.6703 20.3039 13.5362C19.9788 14.2099 19.223 14.56 18.4989 14.3725L15.1335 13.5006C15.0657 13.4823 14.9972 13.4752 14.9301 13.4783C14.728 13.4874 14.5407 13.5888 14.4222 13.7507C14.3787 13.8101 14.3445 13.8777 14.3222 13.9519C14.2997 14.026 14.2909 14.1013 14.2943 14.1748C14.3065 14.4446 14.483 14.6879 14.7485 14.7766L17.4288 15.7024C18.321 16.0105 19.2856 16.0409 20.1954 15.7895L27.3649 13.8084C27.8684 13.6693 28.4053 13.8576 28.7115 14.2807C29.1229 14.8491 28.9865 15.6446 28.4092 16.0435L19.9792 21.8671C19.1671 22.4281 18.1482 22.6003 17.1968 22.3374L9.8707 20.313C9.46585 20.2012 9.04776 20.1445 8.62774 20.1445H5.31011C4.94192 20.1445 4.64345 20.4429 4.64345 20.8111C4.64345 21.1793 4.94192 21.4778 5.31011 21.4778H8.62774C8.92776 21.4778 9.22639 21.5183 9.51557 21.5982L16.8417 23.6226C18.1736 23.9907 19.6001 23.7495 20.7371 22.9641L29.167 17.1405C30.3596 16.3166 30.6415 14.6732 29.7917 13.4989C29.159 12.6248 28.0499 12.2358 27.0098 12.5232L21.5426 14.0339C22.2493 12.4487 21.4109 10.5899 19.7378 10.08L13.0312 8.03599C11.7437 7.64362 10.3496 7.82618 9.20662 8.53682L4.31475 11.5783C4.00207 11.7727 3.90619 12.1838 4.1006 12.4965C4.295 12.8091 4.70608 12.905 5.01876 12.7106L9.91063 9.66914Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Your Service Feedback
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Feedback you’ve given about this service
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[270px] px-[16px] overflow-y-scroll scrollbar-hide">
          {data?.feedback?.map((item) => (
            <div className="flex flex-col px-[8px]" key={item.id}>
              <div
                onClick={() => {
                  setPopup(true);
                  setState(13);
                  setFeedbackID(item.id);
                }}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col py-[10px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <div>
                      {item?.rating === "positive" && (
                        <img
                          src={smileyHoverIcon}
                          alt="rating-img"
                          className="rating_icon active w-[24px]"
                        />
                      )}

                      {item?.rating === "general" && (
                        <img
                          src={neutralHoverIcon}
                          alt="rating-img"
                          className="rating_icon active w-[24px]"
                        />
                      )}

                      {item?.rating === "negative" && (
                        <img
                          src={negativeHoverIcon}
                          alt="rating-img"
                          className="rating_icon active w-[24px]"
                        />
                      )}
                    </div>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {" "}
                          {item?.feedback_on}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    {item?.feedback_details}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            className={cn({
              invisible: !data?.feedback?.length,
            })}
            onClick={() => {
              setPopup(true);
              setState(21);
              dispatch(
                getCompanyServicesFeedback(data.service.id, data.user.id)
              );
            }}
            secondary
            hoverColor
          >
            View all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ServicesRequestAndFeedbackCards;
