import React from 'react'
import Account from './tabs/Account'
import OrgSetting from './tabs/OrgSetting'
import Integration from './tabs/Integration'
import ManageServices from './tabs/ManageServices'
import ManageEmployees from './tabs/ManageEmployees'


function Tabs({Tab, setModel}) {
  return (
    <div style={{ 
      position: 'absolute', 
      top: '16%', 
      left: '32%', 
  }}>
      {Tab === 1 && <Account/>}
      {Tab === 2 && <OrgSetting/>}
      {Tab === 3 && <Integration/>}
      {Tab === 4 && <ManageServices setModel={setModel}/>}
      {Tab === 5 && <ManageEmployees setModel={setModel}/>}
    </div>
  )
}

export default Tabs