import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store, { persistor } from './redux/store';
import { ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { PersistGate } from 'redux-persist/integration/react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <App />
    </PersistGate>
    <ToastContainer
      position='top-right'
      autoClose={2000}
      hideProgressBar={true}
      newestOnTop={false}
      draggable={false}
      closeOnClick
      pauseOnHover
      closeButton={false}
      toastClassName="!rounded-[32px] !p-6 border border-1 border-[#434A5D] !bg-black"
      bodyClassName="bg-black !p-0 !font-medium !text-base !text-white"
    />
    </Provider>
    
  </React.StrictMode>
);

reportWebVitals();
