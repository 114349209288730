import React from 'react'
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import Button from '../../../../../components/Buttons/button'
import { getUsageTeamFeedbackData, getUsageTeamRequestData, getUsageTeamServiceUserData } from '../../../../../redux/slices/companyDashboardSlice';
import { formatedAmount } from "../../../../../utils/helpers";
import { findMaxCountOfReview } from '../lib/findMaxCountOfReview';

import negativeIcon from "../../../../../assets/images/negative.png";
import negativeHoverIcon from "../../../../../assets/images/nagative_hover.png";
import neutralIcon from "../../../../../assets/images/neutral.png";
import neutralHoverIcon from "../../../../../assets/images/neutral_hover.png";
import smileyIcon from "../../../../../assets/images/smiley.png";
import smileyHoverIcon from "../../../../../assets/images/smiley_hover.png";

function PillCards({ setPopup, setState, data, id, serviceId, setFeedServiceID, setDashboard, userId }) {
  const maxCountOfReviews = findMaxCountOfReview(data);

  const dispatch = useDispatch()
  // const getServiceRequest = (id) => {
  //   dispatch(getUsageRequestData(id))
  // }
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 justify-center xl:justify-between items-center gap-[32px]'>

    <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] bg-[#363D50] rounded-[32px]'>
      <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
        <span className='text-primary !text-[18px]'>${formatedAmount(data?.monthly_service_cost)}</span>
        <span className='text-secondary !leading-[21px]'>Monthly service cost</span>
      </div>
      {/*  <Button onClick={() => {
            setPopup(true);
            setState(11);
        }} secondary={true} hoverColor={true}>
            History
        </Button> */}
    </div>

      <div className='flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]'>
        <span className='text-primary !text=[18px]'>${formatedAmount(data?.user_cost_average)}</span>
        <span className='text-secondary !leading-[21px]'>User cost average</span>
      </div>

      <div className='flex flex-col justify-center w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] bg-[#363D50] rounded-[32px]'>
        <div className='flex w-[273px] h-[24px] gap-[16px]'>
          <div className='flex gap-[6px] items-center'>
              {data?.positive_ratings_count === maxCountOfReviews ? (
                <img src={smileyHoverIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon active" />
              ) : (
                <img src={smileyIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon inactive" />
              )}
              <span className='text-secondary !text-[#A5AEBC] !leading-[21px]'>{data?.positive_ratings_count}</span>
          </div>

          <div className='flex gap-[6px] items-center'>
            {data?.general_ratings_count === maxCountOfReviews ? (
              <img src={neutralHoverIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon active" />
            ) : (
              <img src={neutralIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon inactive" />
            )}
            <span className='text-secondary !text-[#A5AEBC] !leading-[21px]'>{data?.general_ratings_count}</span>
          </div>

          <div className='flex gap-[6px] items-center'>
            {data?.negative_ratings_count === maxCountOfReviews ? (
              <img src={negativeHoverIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon active" />
            ) : (
              <img src={negativeIcon} alt="rating-img" className="w-[28px] h-[28px] rating_icon inactive" />
            )}
            <span className='text-secondary !text-[#A5AEBC] !leading-[21px]'>{data?.negative_ratings_count}</span>
          </div>

        </div>
          <span className='text-secondary !leading-[21px]'>Impact rating</span>
      </div>

      <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]'>
        <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
          <span className='text-primary !text-[18px]'>{data?.service_users_count}</span>
          <span className='text-secondary !leading-[21px]'>Service users</span>
        </div>
        <Button
          // className={cn({
          //   'invisible': !data?.service_users_count
          // })} 
          onClick={() => {
            setPopup(true);
            setState(19);
            dispatch(getUsageTeamServiceUserData(id, userId))
          }} 
          secondary
          hoverColor
        >
          View
        </Button>
      </div>

      <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]'>
        <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
          <span className='text-primary !text-[18px]'>{data?.requests_count}</span>
          <span className='text-secondary !leading-[21px]'>Requests</span>
        </div>
        <Button 
          // className={cn({
          //   'invisible': !data?.requests_count
          // })} 
          onClick={() => {
            setPopup(true);
            setState(20);
            setDashboard("companyDahboard")
            dispatch(getUsageTeamRequestData(id, userId))
          }}
          secondary
          hoverColor
        >
          View
        </Button>
      </div>

      <div className='flex justify-between items-center w-[100%] h-[100px] p-[24px] gap-[16px] bg-[#363D50] rounded-[32px]'>
        <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
          <span className='text-primary !text-[18px]'>{data?.feedbacks_count}</span>
          <span className='text-secondary !leading-[21px]'>Feedback</span>
        </div>
        <Button 
          // className={cn({
          //   'invisible': !data?.feedbacks_count
          // })} 
          onClick={() => {
            setPopup(true);
            setState(21);
            dispatch(getUsageTeamFeedbackData(id, userId))
            setFeedServiceID(serviceId);
            setDashboard("companyDahboardfeedback")
          }} 
          secondary
          hoverColor
        >
          View
        </Button>
      </div>
    </div>
  )
}

export default PillCards