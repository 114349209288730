import React from "react";
import Pill from "../../Pill/pill";
// import { Link } from "react-router-dom";
import { getInitials } from '../../../shared/lib/getInitials';
import { getRandomHex } from '../../../shared/lib/getRandomHex';

function ServiceRequestTableBody({ tableData, model=false, show, setShow, setservicereqid }) {
  return (
    <tbody>
      {tableData.map((row, index) => {
        const [hexColor, hexWithOpacity] = getRandomHex()
        return (
          <tr
            key={index}
            className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
            onClick={()=> {
              model && setShow(!show)
              setservicereqid({id :row?.reason?.id, userId: row?.reason?.user_id, service_id:row?.reason?.service_id })
            }}
          >
            {Object.keys(row).map((item, index2) => (
              <td
                key={index2}
                className="text-secondary !text-[#FFFFFF] !text-left py-[24px] px-[16px]"
              >
                {item === "id" && null}
                {item === "requestType" && <Pill fill={true}>{row[item]}</Pill>}
                {item === "status" && (
                  <span>
                    {row[item] === "open" ? (
                      <Pill greenFill={true} className={"!px-1.5 !py-1 !text-[12px]"}>{row[item]}</Pill>
                    ) : (
                      <Pill redFill={true} className={"!px-1.5 !py-1 !text-[12px]"}>{row[item]}</Pill>
                    )}
                  </span>
                )}
                {item === "requestedBy" && (
                  <div className="flex flex-col md:flex-row items-center justify-left gap-[16px]">
                    {/* <img
                      className="w-[40px] h-[40px] rounded-lg"
                      src={row[item]["image"]}
                      alt="service-img"
                    /> */}
                    <div
                      style={{
                        backgroundColor: hexWithOpacity,
                        color: hexColor,
                      }}
                      className="w-[40px] h-[40px] rounded-full flex items-center justify-center !font-semibold"
                    >
                      {getInitials(row[item]["name"])}
                    </div>
                    <div className="flex flex-col">
                      <span className="text-secondary !font-medium !text-[#FFFFFF] !text-left">{row[item]["name"]}</span>
                      <span className="text-secondary !text-left">
                        {row[item]["work"]}
                      </span>
                    </div>
                  </div>
                )}
                {item === "reason" && (
                  <div className="flex flex-col gap-[3px]">
                  <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium">{row[item]["heading"]}</span>
                  <span className="text-secondary !text-left !text-[12px]">
                    {row[item]["subHeading"]}
                  </span>
                  </div>
                )}
                {item === "requestDate" && (                  
                  <span className="!font-medium !text-[16px]">
                    {row[item]}
                  </span>
                )}
                {item !== "requestType" &&
                  item !== "status" &&
                  item !== "requestedBy" &&
                  item !== "reason" &&
                  item !== "requestDate" &&
                  row[item]}
              </td>
            ))}
          </tr>
        )
      })}
    </tbody>
  );
}

export default ServiceRequestTableBody;
