import React from 'react';
import {useDispatch} from 'react-redux'
import Button from "../../Buttons/button";
import { useNavigate } from 'react-router-dom';
import { selectCompanyDashboard, deleteService, deactivateService, setServiceMenuUpdatedFalse } from "../../../redux/slices/companyDashboardSlice";

const ServiceMenuModel = ({ setstate, setModel,setPopup, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleManageService = (id) => {
  }

  const handleViewReportService = (id) => {
     navigate(`/company_dashboard/services/usage_report/${id}`);
     setModel(false)
     setPopup(false)
  }

  const handleDeactivateService = (id) => {
    dispatch(deactivateService(id));
    dispatch(setServiceMenuUpdatedFalse());
    setPopup(false)
  }

  const handleRemoveService = (id) => {
    dispatch(deleteService(id));
    dispatch(setServiceMenuUpdatedFalse());
    setPopup(false)
  }

  return (
    <div className="flex flex-col w-[320px] rounded-[24px] bg-[#363D50] popup_body">
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleManageService(id)}
                  secondaryHover={true}
                >
                  Manage service
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px] header_popup">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleViewReportService(id)}
                  secondaryHover={true}
                >
                  View service report
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[24px] flex flex-col py-[16px] gap-[16px]">
        <div className="flex justify-between">
          <div className="flex flex-start items-center gap-[16px]">
            <div className="flex flex-col">
              <span className="text-primary !text-left">
                <Button
                  onClick={() => handleDeactivateService(id)}
                  secondaryHover={true}
                >
                  Deactivate reporting for users
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="px-[24px] flex flex-col py-[16px] popup_details">
        <div className="service_heading">
          <Button
            onClick={() => handleRemoveService(id)}
            secondaryHover={true}
          >
            Remove service from org
          </Button>
        </div>
        <div className="service_text">Contact thryft to delete this service and its data from your org</div>
      </div>*/}
    </div>
  );
};

export default ServiceMenuModel;
