import Image from "../assets/images/TeamUser.svg";

export const heading = [
    "Employee",
    "Monthly cost",
    "Team Cost",
    // "Services",
    // "Submissions",
    // "Requests",
    // "Feedback",
    ""
]

export const tableData = [
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$540.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$540.44",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$840.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$510.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$240.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$570.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$340.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$740.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$900.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
    {
        employee: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        cost: "$140.43",
        service: "",
        submission: "2,345.83",
        request: 23,
        feedback: 3,
        tenure: "1y 2m"
    },
  
]