import React from "react";
import Pill from "../../Pill/pill";
import linkIcon from "../../../assets/images/link-icon.svg";
import crossIcon from "../../../assets/images/cros-icon-red.svg";
import checkIcon from "../../../assets/images/check-icon.svg";

function RequestTableBody({
  model = false,
  tableData,
  show,
  setShow,
  setPopup,
  setState,
  setId,
}) {
  return (
    <tbody>
      {tableData.map((row, index) => (
        <tr
          key={index}
          className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
          onClick={() => {
            if (model) {
              setShow(!show);
              setId({ id: row?.id, user_id: row?.userId });
            } else {
              setPopup(true);
              setState(26);
              setId(row.id);
            }
          }}
        >
          {Object.keys(row)
            .filter(
              (item) =>
                item !== "id" && item !== "userId" && item !== "serviceId"
            )
            .map((item, index2) => (
              <td
                key={index2}
                className={`text-secondary text-left !text-[#FFFFFF] !font-medium py-[24px] px-[16px] 
                  ${item === "date" && "w-[10%]"} 
                  ${item === "website" && "w-[10%]"} 
                  ${item === "expensed" && "w-[10%]"} 
                  ${item === "category" && "w-[25%]"}
                  ${item === "type" && "w-[15%]"}
                `}
              >
                {item === "type" && <Pill fill={true}>{row[item]}</Pill>}
                <div className="flex items-center gap-1">
                  {item === "category" &&
                    (row[item].length > 0 ? (
                      row[item].map((el, index) => (
                        <Pill key={index} fill={false} outline={true}>
                          {el}
                        </Pill>
                      ))
                    ) : (
                      <Pill outline={true}>N/A</Pill>
                    ))}
                </div>
                {item === "expensed" && (
                  <span>
                    {row[item] === true ? (
                      <img
                        className="w-[24px] h-[24px]"
                        src={checkIcon}
                        alt="link-img1"
                      />
                    ) : (
                      <img
                        className="w-[24px] h-[24px]"
                        src={crossIcon}
                        alt="link-img"
                      />
                    )}
                  </span>
                )}

                {item === "website" && (
                  <span>
                    {row[item] === null ? (
                      "-"
                    ) : (
                      <a href={row[item]}>
                        <img
                          className="w-[24px] h-[24px]"
                          src={linkIcon}
                          alt="link-img"
                        />
                      </a>
                    )}
                  </span>
                )}

                {item === "date" && (
                  <span className="text-base">{row[item]}</span>
                )}
                {item !== "type" &&
                  item !== "category" &&
                  item !== "expensed" &&
                  item !== "website" &&
                  item !== "date" &&
                  row[item]}
              </td>
            ))}
        </tr>
      ))}
    </tbody>
  );
}

export default RequestTableBody;
