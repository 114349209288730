import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Sidebar from "../sidebar/sidebar";

import { useWindowSize } from "../../shared/hooks/useWindowSize";
import { NonDesktopDeviceWarning } from "../../shared/ui/NonDesctopDeviceWarning";

const Layout = ({ children, ...props }) => {
  let location = useLocation();
  var url = location.pathname.split("/");
  var dashboardUrl = "/" + location.pathname.split("/")[1];
  const [width] = useWindowSize();

  return (
    <>
      {width >= 1280 && (
        <div
          className={`container flex max-xs:justify-center sm:justify-start xl:pl-[350px] xl:justify-between xl:gap-[20px] 6xl:gap-[56px] mx-h-[100vh] overflow-hidden ${
            dashboardUrl ? "service_layout" : ""
          }`}
        >
          <Sidebar show={props.show} setShow={props.setShow} />
          {React.Children.map(children, (child) =>
            React.cloneElement(child, props)
          )}
        </div>
      )}

      {width < 1280 && <NonDesktopDeviceWarning />}
    </>
  );
};

export default Layout;
