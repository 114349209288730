import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "../../../components/Buttons/button";
// import { getsimilarServices } from '../../../redux/slices/personalDashboardSlice';
import { getUserDetails, selectUser } from "../../../redux/slices/userSlice";
import Unicon from "../../../assets/images/arrow-down-circle.svg";
import cn from "classnames";
import BasicModal from "../../../components/models/models";

const SimilarServices = ({setPopup, similarServices, setstate, state, popup, userId}) => {

	const [sortOrder, setSortOrder] = useState("asc");
	const [state1, setState1] = useState(0)
  const [popup1, setPopup1] = useState(false);
  const [id, setId] = useState(false);
  const [serviceData, setServiceData] = useState({});

	const handleSortByName = () => {
    // const sorted = [...authorizedServicesData.authorized_services].sort((a, b) => {
    //   if (sortOrder === "asc") {
    //     return a.name.localeCompare(b.name);
    //   } else {
    //     return b.name.localeCompare(a.name);
    //   }
    // });
    // setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    // setSortedServices(sorted);
	};

	const handleSimilarServices = (serviceId, service) => {
		setId({userId: userId, serviceId: serviceId })
		setServiceData(service)
		setState1(6)
		setPopup1(true)
	}

  return (
    <div className="transform rounded-[24px] bg-[#363D50] transition-all flex">
      <div className="w-[800px] h-[500px] pt-[16px] relative flex flex-col">
        <div className="flex flex-col SetupServiceModel">
          <div className="flex h-[40px] justify-between items-center pt-8 model_header">
						<div>
							<div className="text-primary !text-[24px] !leading-[36px]">Similar Services</div>
						</div>
            <div>
	            <svg
	              className="cursor-pointer"
	              onClick={() => {
	                setPopup(false);
	              }}
	              width="25"
	              height="25"
	              viewBox="0 0 25 25"
	              fill="none"
	              xmlns="http://www.w3.org/2000/svg"
	            >
	              <path
	                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
	                fill="white"
	              />
	            </svg>
	        </div>
          </div>
        </div>
        <div className="rounded-t-xl px-[8px] pb-[20px] w-[100%] scrollbar-hide scrollable_data popup_scrollbody">
	        <div className="overflow-x-auto scrollbar-hide custom_scroll_hide">
	          <table className="table-head sticky top-0 w-[100%]">
	            <thead className="table-head sticky top-0 bg-[#363D50]">
	              {/*<tr>
	                <th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >
	                  <Button
	                    className="sorting_btn_wrapper_service"
	                    secondaryHoverOutlined={true}
	                    outlined={true}
	                    onClick={handleSortByName}
	                  >
	                    <span className="flex items-center text-[#A5AEBC]">
	                      Service
	                      <img
	                        className={cn("sortingicon", {
	                          "rotate-180": sortOrder === "desc"
	                        })}
	                        src={Unicon}
	                        alt="icon"
	                      />
	                    </span>
	                  </Button>
	                </th>
									<th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >&nbsp;</th>
									<th className={`text-left text-secondary border-[#434A5D] py-[21px] px-[16px]`} >&nbsp;</th>
	              </tr>*/}
	            </thead>
	            <tbody>
	             { similarServices && similarServices?.map((service, i)=>(
								<tr key={i}>
									<td className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] w-[50%]">
										<div className="flex flex-col md:flex-row items-center gap-[10px]">
		                  <img
		                    className="w-[40px] h-[40px] card_img rounded-lg"
		                    src={service.image}
		                    alt="service-img"
		                  />
		                  <div className="flex flex-col">
		                    <span className="text-secondary lineHeight !text-[#FFFFFF] !text-left !font-medium">
		                      {service.name}
		                    </span>
		                    <span className="text-secondary lineHeight !text-left">
		                      {service.category}
		                    </span>
		                  </div>
		                </div>
		              </td>
									<td className="text-left text-secondary !text-[#FFFFFF] py-[21px] px-[16px] !font-medium lineHeight21 btn_td w-[50%]">
										<div className="flex justify-end">
											<Button
			                  onClick={()=>handleSimilarServices(service?.id, service)}
			                  outlined={true}
			                  hoverColor={true}
			                  secondary={false}
			                  secondaryHoverOutlined={true}
			                  fill={true}
			                >
												Opt Out
			                </Button>
			              </div>
		              </td>
		             </tr>

	             	))
		           }
	            </tbody>

	          </table>
	        </div>
      	</div>
      </div>
      {popup1 && (
        <BasicModal
          state={state1}
          popup={popup1}
          setPopup={setPopup1}
          setState={setState1}
          id={id}
          serviceData={serviceData}
        />
      )}
    </div>
  )
}

export default SimilarServices

