import React from "react";
import { useState, useEffect, useCallback } from "react";
import BasicModal from "../../../components/models/models";
import Header from "../../../components/Headers/Header";
import Button from "../../../components/Buttons/button";
import { heading, tableData } from "../../../services/servicesTableData";
import Table from "../../../components/Table/table";
import {
  getServicesData,
  getTeamServicesData,
  selectCompanyDashboard,
  updatedpersonalServices,
} from "../../../redux/slices/companyDashboardSlice";
import { updatedTeam, getEmployeesRequest, selectPersonalDashboard } from "../../../redux/slices/personalDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { formatedAmount } from "../../../utils/helpers";
import { useLocation } from 'react-router-dom';

const initialState = {
  monthly_cost: false,
  rating: false,
  contract_end: false,
  user_count: false,
  user_cost_average: false,
  upDown: false,
};

function TeamsSerivceModule({ show, setShow }) {
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("monthly_cost");
  const [orderType, setOrderType] = useState("asc")
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { servicesData } = State;
  const perState = useSelector(selectPersonalDashboard);
  const { highCostServices } = perState;
  const [Services, setServices] = useState([]);
  const transformedData = transformResponse(highCostServices);
  const [teamService, setTeamService] = useState("")
  const location = useLocation();
  const { userId } = location.state || {};

  useEffect(() => {
    dispatch(getServicesData());
    setTeamService("team");
    // dispatch(getTeamServicesData());
  }, [dispatch]);

  //   useEffect(() => {
  //   dispatch(getEmployeesRequest(user_id));
  // }, [dispatch, user_id]);

  function transformResponse(response = []) {
    return response.map((item) => {
      return {
        id: item.id,
        service: {
          image: item.image_url,
          name: item.name,
          category: item.service_type,
        },
        rating: item.rating,
        contract_end: item.contract_end,
        user_count: item.user_count,
        cost_average:`$${formatedAmount(item?.user_cost_average)}`,
        cost_per_month:`$${formatedAmount(item.service_cost) }`,
      };
    });
  }

  function ArrowIcon({ upDown }) {
    return (
      <div className="pt-[4px] pl-[6px] pb-[4px] pr-[6px]">
        <svg
          width="10"
          height="10"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={upDown ? "M1 3L8 11L15 3" : "M1 11L8 3L15 11"}
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    );
  }

  const renderArrowIcon = (isActive) => (
    isActive && <ArrowIcon upDown={btns.upDown} />
  );

  const handleServiceName = (orderType, set) => {
    setBtns({
      ...btns,
      user: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "user" && active]: false,
    });
    setActive("user");
    let sort = null;
    if (orderType === "asc") {
    sort = [...servicesData?.company_services].sort((a, b) => a.name.localeCompare(b.name));
  } else {
    sort = [...servicesData?.company_services].sort((a, b) => b.name.localeCompare(a.name));
  }
    dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };

  const handleMonthlyCost = (orderType, set) => {
    setBtns({
      ...btns,
      monthly_cost: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "monthly_cost" && active]: false,
    });
    setActive("monthly_cost");
    let sort = null;
    orderType === "asc"
      ? (sort = [...servicesData?.company_services].sort((a, b) => a.cost_per_month - b.cost_per_month))
      : (sort = [...servicesData?.company_services].sort((a, b) => b.cost_per_month - a.cost_per_month));
      dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };

  const handleRating = (orderType, set) => {
    setBtns({
      ...btns,
      rating: true,
      cost: false,
      // upDown: set ? !btns.upDown : false,
      [active !== "rating" && active]: false,
    });
    setActive("rating");
  
    const ratingValues = {
      negative: 1,
      general: 2,
      positive: 3,
    };

    let sort = null;
    if (orderType === "asc") {
      sort = [...servicesData?.company_services].sort(
        (a, b) =>   (ratingValues[b.rating] ?? 0) - (ratingValues[a.rating] ?? 0)
      );
    } else {
      sort = [...servicesData?.company_services].sort(
        (a, b) => (ratingValues[a.rating] ?? 0) - (ratingValues[b.rating] ?? 0)
      );
    }

    dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };

  const handleContractEnd = (orderType, set) => {
    setBtns({
      ...btns,
      contract_end: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "contract_end" && active]: false,
    });
    setActive("contract_end");
    let sort = null;
    if (orderType === "asc") {
      sort = [...servicesData?.company_services].sort(
        (a, b) => new Date(a.contract_end).getTime() - new Date(b.contract_end).getTime()
      );
    } else {
      sort = [...servicesData?.company_services].sort(
        (a, b) => new Date(b.contract_end).getTime() - new Date(a.contract_end).getTime()
      );
    }
    dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };

  const handleUserCount = (orderType, set) => {
    setBtns({
      ...btns,
      user_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "user_count" && active]: false,
    });
    setActive("user_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...servicesData?.company_services].sort((a, b) => a.user_count - b.user_count))
      : (sort = [...servicesData?.company_services].sort((a, b) => b.user_count - a.user_count));

    dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };

  const handleUserCostAvarage = (orderType, set) => {
    setBtns({
      ...btns,
      user_cost_average: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "user_cost_average" && active]: false,
    });
    setActive("user_cost_average");
    let sort = null;
    orderType === "asc"
      ? (sort = [...servicesData?.company_services].sort((a, b) => a.user_cost_average - b.user_cost_average))
      : (sort = [...servicesData?.company_services].sort((a, b) => b.user_cost_average - a.user_cost_average));
    dispatch(updatedpersonalServices(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc")
  };


  return (
    <div className="right_panel flex flex-col w-[100%] gap-[17px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] pr-[24px]">
      <Header
        title={"Services"}
        subHeading={"A list of all the services contracted by the company"}
        show={show}
        setShow={setShow}
        btns={false}
      />

      <div className="card-section pb-[37px]">
        <div className="cards_wrap bottom_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide !h-[100%]">
          <Table
            headings={heading}
            tableData={transformedData}
            teamService={teamService}
            userId={userId}
            table={"service"}
            orderType={orderType}
            handleServiceName={handleServiceName}
            handleMonthlyCost={handleMonthlyCost}
            handleRating={handleRating}
            handleContractEnd={handleContractEnd}
            handleUserCount={handleUserCount}
            handleUserCostAvarage={handleUserCostAvarage}
          />
        </div>
      </div>

      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </div>
  );
}

export default TeamsSerivceModule;