import React from "react";
import RadioButton from "../../../../RadioButton/RadioButton";
import TextField from "../../../../TextField/TextField";

function First({ setReason, reason, setReasonType, reasonType }) {
  const handleRadioButtonChange = (value) => {
    setReasonType(value);
  };

  return (
    <div className="flex flex-col md:w-[536px] md:h-[404px] pr-[16px] pb-[16px] gap-[24px]">
      <div className="flex flex-start text-left">
        <span className="text-primary !text-[18px] !leading-[27px]">
          Why would you like to change this service?
        </span>
      </div>
      <div className="">
        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "better_suites"}
            onChange={() => handleRadioButtonChange("better_suites")}
          />
          <div className="text-left">
            <span className="text-secondary !text-[18px]">
              So it better suites my job
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "make_it_cheaper"}
            onChange={() => handleRadioButtonChange("make_it_cheaper")}
          />
          <div className="text-left">
            <span className="text-secondary !text-[18px]">
              To make it cheaper for the company
            </span>
          </div>
        </div>

        <div className="flex flex-start pt-[12px] pb-[8px] pr-[24px] gap-[8px]">
          <RadioButton
            checked={reasonType === "other"}
            onChange={() => handleRadioButtonChange("other")}
          />
          <div className="flex flex-col w-full text-left">
            <span className="text-secondary text-left !text-[18px] mb-[4px]">
              Other
            </span>
            {reasonType === "other" && (
              <TextField
                onChange={(e) => setReason(e.target.value)}
                placeholder={"Please explain"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default First;
