import image1 from '../assets/images/feedback1.svg'
import image2 from '../assets/images/feedback2.svg'
import image3 from '../assets/images/feedback3.svg'
import image4 from '../assets/images/feedback4.svg'
import image5 from '../assets/images/feedback5.svg'


export const feedbacks = [
    {
        image: image1,
        feedback: "You won't regret it. I love it. I didn't even need training. I will let my mum know about this, she could really make use of it!",
        service_name: 'Bluth Company',
        sub_name: 'Connector - Zendesk',
        impact_rating: 3,
        feedback_date: '04 Feb, 2023',
    },
    {
        image: image2,
        feedback: "You won't regret it. I love it. I didn't even need training. I will let my mum know about this, she could really make use of it!",
        service_name: 'Bluth Company',
        sub_name: 'Connector - Zendesk',
        impact_rating: 1,
        feedback_date: '04 Feb, 2023',
    },
    {
        image: image3,
        feedback: "You won't regret it. I love it. I didn't even need training. I will let my mum know about this, she could really make use of it!",
        service_name: 'Bluth Company',
        sub_name: 'Connector - Zendesk',
        impact_rating: 2,
        feedback_date: '04 Feb, 2023',
    },
    {
        image: image4,
        feedback: "You won't regret it. I love it. I didn't even need training. I will let my mum know about this, she could really make use of it!",
        service_name: 'Bluth Company',
        sub_name: 'Connector - Zendesk',
        impact_rating: 1,
        feedback_date: '04 Feb, 2023',
    },
    {
        image: image5,
        feedback: "You won't regret it. I love it. I didn't even need training. I will let my mum know about this, she could really make use of it!",
        service_name: 'Bluth Company',
        sub_name: 'Connector - Zendesk',
        impact_rating: 3,
        feedback_date: '04 Feb, 2023',
    },
]