import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

import Button from "../../../../components/Buttons/button";
import { services } from "../../../../services/servicesData";
import { tableData } from "../../../../services/teamsTableData";
import Pill from "../../../../components/Pill/pill";

function RequestAndUserCostCards({ setPopup, setState }) {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[24px] w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 2.5C10.1716 2.5 9.5 3.17157 9.5 4C9.5 4.82843 10.1716 5.5 11 5.5H13C13.8284 5.5 14.5 4.82843 14.5 4C14.5 3.17157 13.8284 2.5 13 2.5H11ZM11 1.5C9.79052 1.5 8.78164 2.35888 8.55001 3.5H7C6.33696 3.5 5.70107 3.76339 5.23223 4.23223C4.76339 4.70107 4.5 5.33696 4.5 6V18C4.5 18.663 4.76339 19.2989 5.23223 19.7678C5.70107 20.2366 6.33696 20.5 7 20.5H17C17.663 20.5 18.2989 20.2366 18.7678 19.7678C19.2366 19.2989 19.5 18.663 19.5 18V6C19.5 5.33696 19.2366 4.70107 18.7678 4.23223C18.2989 3.76339 17.663 3.5 17 3.5H15.45C15.2184 2.35888 14.2095 1.5 13 1.5H11ZM17 4.5H15.45C15.2184 5.64112 14.2095 6.5 13 6.5H11C9.79052 6.5 8.78164 5.64112 8.55001 4.5H7C6.60218 4.5 6.22064 4.65804 5.93934 4.93934C5.65804 5.22064 5.5 5.60218 5.5 6V18C5.5 18.3978 5.65804 18.7794 5.93934 19.0607C6.22064 19.342 6.60218 19.5 7 19.5H17C17.3978 19.5 17.7794 19.342 18.0607 19.0607C18.342 18.7794 18.5 18.3978 18.5 18V6C18.5 5.60218 18.342 5.22064 18.0607 4.93934C17.7794 4.65804 17.3978 4.5 17 4.5ZM9.74998 10.75C9.74998 9.50736 10.7573 8.5 12 8.5C13.2426 8.5 14.25 9.50736 14.25 10.75C14.25 11.9926 13.2426 13 12 13C10.7573 13 9.74998 11.9926 9.74998 10.75ZM12 9.5C11.3096 9.5 10.75 10.0596 10.75 10.75C10.75 11.4404 11.3096 12 12 12C12.6903 12 13.25 11.4404 13.25 10.75C13.25 10.0596 12.6903 9.5 12 9.5ZM12 15.5C10.6753 15.5 9.52829 16.2095 8.934 17.2483C8.79687 17.488 8.4914 17.5711 8.25171 17.434C8.01202 17.2969 7.92888 16.9914 8.066 16.7517C8.83644 15.405 10.3127 14.5 12 14.5C13.6873 14.5 15.1636 15.405 15.934 16.7517C16.0711 16.9914 15.988 17.2969 15.7483 17.434C15.5086 17.5711 15.2031 17.488 15.066 17.2483C14.4717 16.2095 13.3247 15.5 12 15.5Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Highest cost users
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Users with a high price tag
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col px-[16px]">
          {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

          <div className="flex flex-col max-w-[100%] h-[360px] px-[3px] py-[3px] overflow-y-scroll scrollbar-hide">
            {tableData.map((employee, index) => (
              <div
                onClick={() => {}}
                key={index}
                className="flex justify-between items-center card-animate cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
              >
                <div className="flex gap-[16px] items-center">
                  <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20">
                    <span className="flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]">
                      <span>{employee.employee.name[0]}</span>
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                      {employee.employee.name}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-left">
                      {employee.employee.work}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                    {employee.cost}
                  </span>
                  <span className="text-secondary !leading-[20px] !text-right">
                    Cost per month
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"#"}>
            <Button
              className={cn({
                invisible: !tableData?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>

      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px] w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[12px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.5 7C3.5 6.17157 4.17157 5.5 5 5.5H19C19.8284 5.5 20.5 6.17157 20.5 7V15C20.5 15.8284 19.8284 16.5 19 16.5H14.8284C14.1654 16.5 13.5295 16.7634 13.0607 17.2322L9.14645 21.1464C8.95118 21.3417 8.95118 21.6583 9.14645 21.8536C9.34171 22.0488 9.65829 22.0488 9.85355 21.8536L13.7678 17.9393C14.0491 17.658 14.4306 17.5 14.8284 17.5H19C20.3807 17.5 21.5 16.3807 21.5 15V7C21.5 5.61929 20.3807 4.5 19 4.5H5C3.61929 4.5 2.5 5.61929 2.5 7V15C2.5 16.3807 3.61929 17.5 5 17.5H8.5C8.77614 17.5 9 17.2761 9 17C9 16.7239 8.77614 16.5 8.5 16.5H5C4.17157 16.5 3.5 15.8284 3.5 15V7ZM8 10.5C7.72386 10.5 7.5 10.7239 7.5 11C7.5 11.2761 7.72386 11.5 8 11.5H8.01C8.28614 11.5 8.51 11.2761 8.51 11C8.51 10.7239 8.28614 10.5 8.01 10.5H8ZM12 10.5C11.7239 10.5 11.5 10.7239 11.5 11C11.5 11.2761 11.7239 11.5 12 11.5H12.01C12.2861 11.5 12.51 11.2761 12.51 11C12.51 10.7239 12.2861 10.5 12.01 10.5H12ZM16 10.5C15.7239 10.5 15.5 10.7239 15.5 11C15.5 11.2761 15.7239 11.5 16 11.5H16.01C16.2861 11.5 16.51 11.2761 16.51 11C16.51 10.7239 16.2861 10.5 16.01 10.5H16Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Recent Requests
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Requests that you have recently interacted with
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[360px] overflow-y-scroll scrollbar-hide px-[16px]">
          <div className="flex flex-col px-[8px] py-[3px]">
            {services.map((i, item) => (
              <div
                onClick={() => {
                  setPopup(true);
                  setState(14);
                }}
                key={i}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col h-[90px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <Pill fill={true}>Change</Pill>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">3</span>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 1.25C7.0266 1.25165 5.37426 1.93701 4.15563 3.15563C2.93701 4.37426 2.25165 6.0266 2.25 7.75V13.0188C2.25 13.3453 2.37972 13.6585 2.61062 13.8894C2.84153 14.1203 3.1547 14.25 3.48125 14.25H8.75C10.4739 14.25 12.1272 13.5652 13.3462 12.3462C14.5652 11.1272 15.25 9.47391 15.25 7.75C15.25 6.02609 14.5652 4.37279 13.3462 3.15381C12.1272 1.93482 10.4739 1.25 8.75 1.25ZM8.75 12.75H3.75V7.75C3.75 6.76109 4.04324 5.79439 4.59265 4.97215C5.14206 4.1499 5.92295 3.50904 6.83658 3.1306C7.75021 2.75216 8.75555 2.65315 9.72545 2.84607C10.6954 3.039 11.5863 3.5152 12.2855 4.21447C12.9848 4.91373 13.461 5.80464 13.6539 6.77455C13.8469 7.74445 13.7478 8.74979 13.3694 9.66342C12.991 10.577 12.3501 11.3579 11.5279 11.9073C10.7056 12.4568 9.73891 12.75 8.75 12.75ZM11.25 6.75C11.25 6.94891 11.171 7.13968 11.0303 7.28033C10.8897 7.42098 10.6989 7.5 10.5 7.5H6.75C6.55109 7.5 6.36032 7.42098 6.21967 7.28033C6.07902 7.13968 6 6.94891 6 6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H10.5C10.6989 6 10.8897 6.07902 11.0303 6.21967C11.171 6.36032 11.25 6.55109 11.25 6.75ZM11.25 9.25C11.25 9.44891 11.171 9.63968 11.0303 9.78033C10.8897 9.92098 10.6989 10 10.5 10H6.75C6.55109 10 6.36032 9.92098 6.21967 9.78033C6.07902 9.63968 6 9.44891 6 9.25C6 9.05109 6.07902 8.86032 6.21967 8.71967C6.36032 8.57902 6.55109 8.5 6.75 8.5H10.5C10.6989 8.5 10.8897 8.57902 11.0303 8.71967C11.171 8.86032 11.25 9.05109 11.25 9.25Z"
                            fill="#A5AEBC"
                          />
                        </svg>
                        <span className="text-secondary">1/8/2022</span>
                      </div>
                      <Pill greenFill={true} className="text-[12px] py-[4px]">
                        Open
                      </Pill>
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    So it better suites my job
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Link to={"/personal_dashboard/requests"}>
            <Button
              className={cn({
                invisible: !services?.length,
              })}
              secondary
              hoverColor
            >
              View all
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RequestAndUserCostCards;
