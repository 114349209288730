import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs({ path }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {path.map((item, index) => (
          <div key={index}>
            {index === 0 && (
              <li className="inline-flex items-center">
                <Link
                  to={`/${item==="Teams"?"personal_dashboard":"company_dashboard"}/${path[0]
                    .toString()
                    .replace(/ /g, "_")
                    .toLowerCase()}${item==="Teams"?"/1":""}`}
                  className="inline-flex items-center text-base text-[#AAADB1] hover:!text-[#FFFFFF]"
                >
                  {item}
                </Link>
              </li>
            )}
            {index > 0 && (
              <li>
                <div className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-[#AAADB1]"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <Link
                    to={`/company_dashboard/${path
                      .slice(0, index + 1)
                      .join("/")
                      .replace(/ /g, "_")
                      .toLowerCase()}`}
                    className={`ml-3 inline-flex items-center text-base text-[#AAADB1] ${
                      path.length - 1 === index
                        ? "!text-[#FFFFFF] pointer-events-none"
                        : "hover:!text-[#FFFFFF]"
                    }`}
                  >
                    {item}
                  </Link>
                </div>
              </li>
            )}
          </div>
        ))}
      </ol>
    </nav>
  );
}

export default Breadcrumbs;
