import Image from "../assets/images/TeamUser.svg";

export const heading = [
    "User",
    "Monthly cost",
    "Rating",
    "Service requests",
    "Feedback given",
    // "Service life"
]

export const tableData = [
    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

    {
        user: {
            image: Image,
            name: "User name",
            work: "Work distinction"
        },
        rating: 3,
        monthly_cost: 346,
        service_request: 2,
        feedback_given: 1,
        service_life: "1y2m",
    },

]