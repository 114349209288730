import React, { useEffect, useRef, useState } from "react";
import Checkbox from "../../../../Checkbox/Checkbox";
import Select from "../../../../Select/select";
import Button from "../../../../Buttons/button";
import TextField from "../../../../TextField/TextField";
import {getInitializeServicesData, selectCompanyDashboard } from '../../../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../../../assets/icons/error-icon.svg'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";

function ProcurementPOC({formValidName, id, poc_name, setPoc_name, poc_email, setPoc_email, poc_phone, setPoc_phone}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const setupServices = SetServicesData?.setup_services;

  const [searchResults, setSearchResults] = useState([]);
  const [searchServices, setSearchServices] = useState([]);
  const [selected, setSelected] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isTextFieldFocused, setTextFieldFocus] = useState(false);
  const [isTextMatch, setIsTextMatch] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const ulItemRefs = useRef([]);
  const servicesUlItemRefs = useRef([]);

  useEffect(()=>{
    dispatch(getInitializeServicesData())
  },[])

  useEffect(()=>{
    setPoc_name(setupServices.poc_name)
    // setPoc_email(setupServices.poc_email)
    // setPoc_phone(setupServices.poc_phone_no)
  },[id])

  // const handlePocName = (e) => {
  //   setPoc_name(e.target.value);
  // };

//   const handlePocEmail = (e) => {
//     setPoc_email(e.target.value);
//   };
// 
//   const handlePocPhone = (e) => {
//     setPoc_phone(e.target.value);
//   };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean)
    ulItemRefs.current[index] = element
  };

  const handleUserNameInput = (field, value, e) => {
    if (value.trim() === '') {
      setSearchText("")
      setPoc_name("");
      setSearchResults([]);
    } else {
      setSearchText(value);

      const filteredUsers = CategoriesAndUsersData?.company_users.filter((user) => {
        const searchTerm = value.toLowerCase();
        return (
          user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm) ||
          user.id.toString().includes(searchTerm)
        );
      });
      setSearchResults(filteredUsers);
      setPoc_name(value);
      // onOwnerChange(value);
    }
  };

  const handleResultClick = (result) => {
    handleUserNameInput('name', result.name);
    setSearchResults([]);
    setSelected(result);
    setIsTextMatch(true);
    // onOwnerChange(result.name);
  };

  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return
    }

    if (e.key === 'ArrowDown') {
      const nextIndex =  (activeIndex + 1) % ulItemRefs.current.length;
      setActiveIndex(nextIndex);
      ulItemRefs.current[nextIndex]?.focus();
      return
    }

    if (e.key === 'ArrowUp') {
      const prevIndex =  (activeIndex - 1 + ulItemRefs.current.length) % ulItemRefs.current.length;
      setActiveIndex(prevIndex);
      ulItemRefs.current[prevIndex]?.focus();
      return
    }

    if (e.key === 'Enter' && result?.name) {
      handleResultClick(result);
    }
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocus(true);
  };

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setTextFieldFocus(false);
      // setSearchServices([]);
    }, 100);
  };

  useEffect(()=>{
    if(poc_name){
      setSearchText(poc_name)
    }
  },[poc_name])

  return (
    <div className="flex flex-col gap-[24px] pr-[16px] pb-[16px] addServiceForm">
      <div className="flex flex-start flex-col text-left">
        <div className="text-primary !text-[24px] !leading-[36px]">Procurement POC</div>
        <div className="flex flex-start step_heading">Add information for this service’s procurement point of contact</div>
      </div>
      
      <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Name
            {
              !formValidName &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Full name"}
            value={searchText}
            onChange={(e) => handleUserNameInput('name', e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
            // onFocus={}
            // onBlur={}
          />
          {searchResults.length > 0 && (
            <div
              className="relative"
            >
              <ul
                tabIndex={0}
                className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-hidden rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                {searchResults.map((result, i) => (
                  <li
                    tabIndex={i}
                    ref={(el) => setButtonRef(el, i)}
                    key={result.id}
                    onKeyDown={(e) => handleKeyDown(e, result)}
                    onClick={() => handleResultClick(result)}
                    className={`custom_text relative cursor-default focus:bg-[#596073] outline-none select-none py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#596073] ${
                      selected === result ? 'bg-[#596073]' : ''
                    }`}
                  >
                    <div className="flex flex-col">
                      <span className="text-primary">{result.name}</span>
                      <span className="text-secondary">{result.distinction}</span>
                    </div>

                    {selected === result && (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            Email
            {
               &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Email"}
            value={poc_email}
            onChange={handlePocEmail}
            // onKeyDown={}
            // onFocus={}
            // onBlur={}
          />
          
        </div>
      </div>

      <div className="flex flex-col">
        <div>
          <label className="flex flex-start justify-between text-primary !font-semibold mb-[8px]">
            <span>
              <span>Phone number </span>
              <span className="optionalText">(optional)</span>
            </span>
            {/* {
              !formValid &&
              (
                <span>
                  <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                </span>
              )
            }
          </label>
          <TextField
            placeholder={"Phone number"}
            value={poc_phone}
            onChange={handlePocPhone}
            // onKeyDown={}
            // onFocus={}
            // onBlur={}
          />
          
        </div>
      </div> */}

    </div>
  );
}

export default ProcurementPOC;
