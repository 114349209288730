import React, { useEffect } from 'react'
import { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "../../../../components/Buttons/button";
import { Link, useParams } from "react-router-dom";
import BasicModal from "../../../../components/models/models";
import Header from "../../../../components/Headers/Header";
import { heading, tableData } from "../../../../services/employeesTableData";
import Table from "../../../../components/Table/table";
import { useDispatch, useSelector } from "react-redux";
import {
  updatedEmployees,
  getEmployeeSortedData,
  selectCompanyDashboard,
  deleteManagerName,
} from "../../../../redux/slices/companyDashboardSlice";
import { formatedAmount } from "../../../../utils/helpers";
import PillCards from "../components/pill-cards";
import Image1 from "../../../../assets/images/employee1.svg";

const initialState = {
  name: false,
  monthly_cost: false,
  services_count: false,
  submissions_count: false,
  feedbacks_count: false,
  requests_count: false,
};

function EmployeesModule({ show, setShow }) {
  const { user_id } = useParams();
  const [managerId, setManagerId] = useState(user_id || "");
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState(0);
  const [Employees, setEmployees] = useState([]);
  const [pageTitle, setPageTitle] = useState("");
  const [btns, setBtns] = useState(initialState);
  const [active, setActive] = useState("monthly_cost");
  const [totalTeamCost, setTotalTeamCost] = useState(0);
  const [averageEmployeeCost, setAverageEmployeeCost] = useState(0);
  const [teamDetailsClicked, setTeamDetailsClicked] = useState(false);
  const [orderType, setOrderType] = useState("asc");
  const [finalData, setFinalData] = useState([]);
  const dispatch = useDispatch();
  const State = useSelector(selectCompanyDashboard);
  const { employeesData, managerName } = State;

  const transformResponse = (response = []) => {
    return response?.map((item) => {
      return {
        id: item.id,
        employee: {
          image: Image1,
          name: item.name,
          category: item.distinction,
        },
        monthly_cost: `$${formatedAmount(item.monthly_cost)}`,
        service: item.services_count,
        submissions: item.submissions_count,
        request: item.requests_count,
        feedback: item.feedbacks_count,
        more_action: [],
        also_manager: item.also_manager,
      };
    });
  };

  useEffect(() => {
    dispatch(getEmployeeSortedData(managerId));
  }, [dispatch, managerId]);

  useEffect(() => {
    if (managerName && managerName !== "" && user_id) {
      setPageTitle(`${managerName}'s Team`);
    } else {
      setPageTitle("Employees");
      dispatch(deleteManagerName());
    }
  }, [dispatch, managerName, user_id]);

  useEffect(() => {
    if (!teamDetailsClicked) {
      setManagerId(user_id || "");
    }
  }, [teamDetailsClicked, user_id]);

  useEffect(() => {
    const transformedData = transformResponse(employeesData);
    setFinalData(transformedData);
    const totalCost = employeesData?.reduce((sum, employee) => {
      return sum + (employee.monthly_cost || 0);
    }, 0);
    setTotalTeamCost(totalCost);
    const presentEmployees = employeesData?.length;
    const avgEmployeeCost =
      presentEmployees > 0 ? totalCost / presentEmployees : 0;
    const formattedAvgEmployeeCost = avgEmployeeCost.toFixed(2);
    setAverageEmployeeCost(formattedAvgEmployeeCost);
  }, [employeesData]);

  const handleEmployeeName = (orderType, set) => {
    setBtns({
      ...btns,
      Employee: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "Employee" && active]: false,
    });
    setActive("Employee");
    let sort = null;
    if (orderType === "asc") {
      sort = [...employeesData].sort((a, b) => a.name.localeCompare(b.name));
    } else {
      sort = [...employeesData].sort((a, b) => b.name.localeCompare(a.name));
    }
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleEmpMonthlyCost = (orderType, set) => {
    setBtns({
      ...btns,
      monthly_cost: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "monthly_cost" && active]: false,
    });
    setActive("monthly_cost");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.monthly_cost - b.monthly_cost
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.monthly_cost - a.monthly_cost
        ));
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleEmpService = (orderType, set) => {
    setBtns({
      ...btns,
      services_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "services_count" && active]: false,
    });
    setActive("services_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.services_count - b.services_count
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.services_count - a.services_count
        ));
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  const handleEmpSubmission = (orderType, set) => {
    setBtns({
      ...btns,
      submissions_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "submissions_count" && active]: false,
    });
    setActive("submissions_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.submissions_count - b.submissions_count
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.submissions_count - a.submissions_count
        ));
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };
  const handleEmpRequest = (orderType, set) => {
    setBtns({
      ...btns,
      requests_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "requests_count" && active]: false,
    });
    setActive("requests_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.requests_count - b.requests_count
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.requests_count - a.requests_count
        ));
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };
  const handleEmpFeedback = (orderType, set) => {
    setBtns({
      ...btns,
      feedbacks_count: true,
      // upDown: set ? !btns.upDown : false,
      [active !== "feedbacks_count" && active]: false,
    });
    setActive("feedbacks_count");
    let sort = null;
    orderType === "asc"
      ? (sort = [...employeesData].sort(
          (a, b) => a.feedbacks_count - b.feedbacks_count
        ))
      : (sort = [...employeesData].sort(
          (a, b) => b.feedbacks_count - a.feedbacks_count
        ));
    dispatch(updatedEmployees(sort));
    setOrderType(orderType == "asc" ? "desc" : "asc");
  };

  return (
    <div className="right_panel flex flex-col w-[100%] gap-[48px] pt-[20px] xl:pt-[32px] xl:max-w-[1099px] 6xl:max-w-[80%] pr-[24px]">
      {pageTitle && (
        <Header
          title={pageTitle}
          // subHeading={"A list of employees and their service activity"}
          show={show}
          setShow={setShow}
          btns={false}
        />
      )}

     {finalData.length > 0 && (
        <>
          <PillCards
            totalTeamCost={totalTeamCost}
            averageEmployeeCost={averageEmployeeCost}
          />

          <div className="card-section">
            <div className="cards_wrap double_space bg-[#363D50] w-[100%] rounded-[24px] overflow-y-scroll scrollbar-hide max-h-[470px] md:max-h-[670px]">
              <Table
                headings={heading}
                tableData={finalData}
                table={"employee"}
                setManagerId={setManagerId}
                orderType={orderType}
                handleEmployeeName={handleEmployeeName}
                handleEmpMonthlyCost={handleEmpMonthlyCost}
                handleEmpService={handleEmpService}
                handleEmpSubmission={handleEmpSubmission}
                handleEmpRequest={handleEmpRequest}
                handleEmpFeedback={handleEmpFeedback}
              />
            </div>
          </div>
        </>
      )}

      {setPopup && (
        <BasicModal
          state={state}
          popup={popup}
          setPopup={setPopup}
          setState={setState}
        />
      )}
    </div>
  );
}

export default EmployeesModule;
