import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function MonthPicker({selectedDate, setSelectedDate, updateSelectedDate}) {
  return (
    <DatePicker
      selected={selectedDate}
      onChange={(date) => {
        setSelectedDate(date);
        updateSelectedDate(date)
        function setDate() {
          const calWrap = document.getElementById('calendarWrap');
          const dateValue = calWrap.querySelector('input').value;
          const elementCal = document.getElementById('calendarVal');
          elementCal.innerHTML = dateValue
        }
        setTimeout(setDate, 200);
      }}
      dateFormat="MMMM, yyyy "
      showMonthYearPicker
    />
  );
};

export default MonthPicker;