import React, { useEffect, useRef, useState, Fragment } from "react";
import { Listbox, Transition } from '@headlessui/react';
import Checkbox from "../../Checkbox/Checkbox";
import Select from "../../Select/select";
import Button from "../../Buttons/button";
import TextField from "../../TextField/TextField";
import {getInitializeServicesData, createServiceMember, getSetupServicesData, selectCompanyDashboard } from '../../../redux/slices/companyDashboardSlice';
import { useDispatch, useSelector } from "react-redux";
import errorIcon from '../../../assets/icons/error-icon.svg';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { current } from "@reduxjs/toolkit";
import downChevron from '../../../assets/images/downChevron.png';
import BasicModal from "../../models/models";

function AddServiceMember({ id, setPopup}){
  const dispatch = useDispatch();
  const State  = useSelector(selectCompanyDashboard);
  const {CategoriesAndUsersData, SetServicesData} = State
  const setupServices = SetServicesData?.setup_services;
  const [hasWebsite, setHasWebsite] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selected, setSelected] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const [validationMessage, setValidationMessage] = useState('');
  const [isTextFieldFocused, setTextFieldFocus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [servicesActiveIndex, setServicesActiveIndex] = useState(-1);
  const ulItemRefs = useRef([]);
  const servicesUlItemRefs = useRef([]);
  const [selectedType, setSelectedType] = useState('Owner');
  const [popUP, setPopUP] = useState(false);
  const [state, setState] = useState(0);
  const isButtonDisabled = !searchText.trim();
  const permissions = {
    read_only: 'Viewer',
    owner_access: 'Owner',
    manager_access: 'Tasker'
  };

  useEffect(()=>{
    dispatch(getInitializeServicesData())
  },[])

  // const setSelectedType = (e) => {
  //   setSelectedType(e.target.value);
  // };

  const handleServiceSave = () => {
    if (selected) {
      const permissionKey = Object.keys(permissions).find(key => permissions[key] === selectedType);
      dispatch(createServiceMember({ service_id: id, user_id: selected.id, permission: permissionKey }));
      setPopup(false);
    }
  };

  const handleMemberType = () => {
    setPopUP(true);
    setState(41);
    // setUserId(userId);
  }


  const handleUserNameInput = (field, value, e) => {
    if (value.trim() === '') {
      setSearchText("")
      // onOwnerChange("");
      setSearchResults([]);
    } else {
      setSearchText(value);
      const filteredUsers = CategoriesAndUsersData?.company_users.filter((user) => {
        const searchTerm = value?.toLowerCase();

        if (user.role && user.role.toLowerCase() === 'admin') {
          return false;
        }
        return (
          user.name.toLowerCase().includes(searchTerm) ||
          user.email.toLowerCase().includes(searchTerm) ||
          user.id.toString().includes(searchTerm)
        );
      });
      setSearchResults(filteredUsers);
    }
  };

  const handleResultClick = (result) => {
    handleUserNameInput('name', result.name);
    setSearchResults([]);
    setSelected(result);
  };

  const setButtonRef = (element, index) => {
    ulItemRefs.current = ulItemRefs.current.filter(Boolean)
    ulItemRefs.current[index] = element
  };

  const setButtonRefForServices = (element, index) => {
    servicesUlItemRefs.current = servicesUlItemRefs.current.filter(Boolean)
    servicesUlItemRefs.current[index] = element
  }
  const handleKeyDown = (e, result) => {
    if (!searchResults.length || !ulItemRefs.current.length) {
      return
    }

    if (e.key === 'ArrowDown') {
      const nextIndex =  (activeIndex + 1) % ulItemRefs.current.length;
      setActiveIndex(nextIndex);
      ulItemRefs.current[nextIndex]?.focus();

      return
    }

    if (e.key === 'ArrowUp') {
      const prevIndex =  (activeIndex - 1 + ulItemRefs.current.length) % ulItemRefs.current.length;

      setActiveIndex(prevIndex);

      ulItemRefs.current[prevIndex]?.focus();

      return
    }

    if (e.key === 'Enter' && result?.name) {
      handleResultClick(result);
    }
  };

  const handleTextFieldFocus = () => {
    setTextFieldFocus(true);
  };

  const handleTextFieldBlur = () => {
    setTimeout(() => {
      setTextFieldFocus(false);
      // setSearchServices([]);
    }, 100);
  };

  return (
    <div className="transform rounded-[24px] bg-[#363D50] transition-all flex">
      <div className="flex md:w-[600px] pt-[16px] pr-[24px] pb-[24px] pl-[24px]">
        <div className="flex flex-col SetupServiceModel">
          <div className="flex h-[40px] justify-end items-center">
            <svg
              className="cursor-pointer"
              onClick={() => {
                setPopup(false);
              }}
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4099 12.0002L17.7099 7.71019C17.8982 7.52188 18.004 7.26649 18.004 7.00019C18.004 6.73388 17.8982 6.47849 17.7099 6.29019C17.5216 6.10188 17.2662 5.99609 16.9999 5.99609C16.7336 5.99609 16.4782 6.10188 16.2899 6.29019L11.9999 10.5902L7.70994 6.29019C7.52164 6.10188 7.26624 5.99609 6.99994 5.99609C6.73364 5.99609 6.47824 6.10188 6.28994 6.29019C6.10164 6.47849 5.99585 6.73388 5.99585 7.00019C5.99585 7.26649 6.10164 7.52188 6.28994 7.71019L10.5899 12.0002L6.28994 16.2902C6.19621 16.3831 6.12182 16.4937 6.07105 16.6156C6.02028 16.7375 5.99414 16.8682 5.99414 17.0002C5.99414 17.1322 6.02028 17.2629 6.07105 17.3848C6.12182 17.5066 6.19621 17.6172 6.28994 17.7102C6.3829 17.8039 6.4935 17.8783 6.61536 17.9291C6.73722 17.9798 6.86793 18.006 6.99994 18.006C7.13195 18.006 7.26266 17.9798 7.38452 17.9291C7.50638 17.8783 7.61698 17.8039 7.70994 17.7102L11.9999 13.4102L16.2899 17.7102C16.3829 17.8039 16.4935 17.8783 16.6154 17.9291C16.7372 17.9798 16.8679 18.006 16.9999 18.006C17.132 18.006 17.2627 17.9798 17.3845 17.9291C17.5064 17.8783 17.617 17.8039 17.7099 17.7102C17.8037 17.6172 17.8781 17.5066 17.9288 17.3848C17.9796 17.2629 18.0057 17.1322 18.0057 17.0002C18.0057 16.8682 17.9796 16.7375 17.9288 16.6156C17.8781 16.4937 17.8037 16.3831 17.7099 16.2902L13.4099 12.0002Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="flex flex-col gap-[24px] pb-[24px] addServiceForm">
            <div className="flex flex-start flex-col text-left">
              <div className="text-primary !text-[24px] !leading-[36px]">Add service member</div>
            </div>
            <div className="flex flex-col gap-[12px]">
              <div className="flex flex-row gap-[12px]">
                <div className="chooseMemebr">
                  <label className="flex flex-start  justify-start items-center text-primary !font-semibold mb-[8px]">
                    <span className="flex-grow text-left">Member</span>
                    {/*{
                      !formValid &&
                      (
                        <span>
                          <img src={errorIcon} alt="Error Icon" className="w-[24px] h-[24px]" />
                        </span>
                      )
                    }*/}
                  </label>
                  <TextField
                    placeholder={"Enter name, email, or employee ID"}
                    value={searchText}
                    onChange={(e) => handleUserNameInput('name', e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    // onFocus={handleTextFieldFocus}
                    // onBlur={handleTextFieldBlur}
                  />
                  {searchResults.length > 0 && (
                    <div
                      className="relative"
                    >
                      <ul
                        tabIndex={0}
                        className="z-10 absolute mt-1 max-h-40 scrollbar-hide w-full overflow-hidden rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                      >
                        {searchResults.map((result, i) => (
                          <li
                            tabIndex={i}
                            ref={(el) => setButtonRef(el, i)}
                            key={result.id}
                            onKeyDown={(e) => handleKeyDown(e, result)}
                            onClick={() => handleResultClick(result)}
                            className={`custom_text relative cursor-default focus:bg-[#596073] outline-none select-none py-2 pl-10 pr-4 text-left transition-colors duration-300 hover:bg-[#596073] ${
                              selected === result ? 'bg-[#596073]' : ''
                            }`}
                          >
                            <div className="flex flex-col">
                              <span className="text-primary">{result.name}</span>
                              <span className="text-secondary">{result.distinction}</span>
                            </div>

                            {selected === result && (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="selectType">
                  <label className="flex flex-start justify-start items-center text-primary !font-semibold mb-[8px]">
                    <span className="flex-grow text-left">Type</span>
                  </label>
                  <div className="selectTypeWrap">
                    <Listbox value={selectedType} onChange={setSelectedType}>
                      <div className="relative mt-1">
                        <Listbox.Button className="custom_text relative bg-[#363D50] border border-[#596073] rounded-[12px] focus:ring-[#596073] focus:border-[#596073] block w-[183px] px-[16px] py-[12px] text-left">
                          <span className="">{selectedType}</span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon
                              className="h-5 w-5 text-secondary"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>
                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute mt-1 max-h-40 scrollbar-hide w-[183px] overflow-auto rounded-md bg-[#363D50] py-1 text-[#596073] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                            {Object.values(permissions).map((perm) => (
                              <Listbox.Option
                                key={perm}
                                className={({ active }) =>
                                  `custom_text relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-[#596073] text-secondary' : 'text-secondary'
                                  }`
                                }
                                value={perm}
                              >
                                {({ selected }) => (
                                  <>
                                    <span
                                      className={`flex flex-start${
                                        selected ? ' font-medium' : ' font-normal'
                                      }`}
                                    >
                                      {perm}
                                    </span>
                                    {selected ? (
                                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                    {/* <img src={downChevron} alt="icon" className="downChevron" /> */}
                  </div>
                </div>
              </div>
              <div className="text-secondary text-left">
                <span>Select an Org employee to become a service member. Members have access to this service’s dashboard and more depending on their  </span>
                <a
                  href="#"
                  className="memberLink"
                  onClick={() => handleMemberType()}
                >
                  member type.
                </a>
                <span> which can be changed by an Admins or Service owner.</span>
              </div>
            </div>
          </div>
          <div className=" flex justify-end items-center gap-[12px] popupFooter">
            {/* { !formValid && (<div className="text-[#ED4650]">{"Incomplete feild"}</div>)}             */}
            <Button
              onClick={() => {
              setPopup(false)
              }}
              className="btnHover bg-gray-700"
              outlined={true}
              hoverColor={true}
              secondary={true}
              secondaryHoverOutlined={true}
            >
              Cancel
            </Button>
            <Button
              onClick={handleServiceSave}
              className="btnHover"
              fill
              disabled={isButtonDisabled}
            >
              Add member
            </Button>
          </div>
        </div>
      </div>
      {popUP && (
        <BasicModal
          state={state}
          setPopup={setPopUP}
          setState={setState}
          id={id}
        />
      )}
    </div>
  );
}

export default AddServiceMember;
