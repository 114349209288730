import React from 'react'
import { formatedAmount } from "../../../../utils/helpers";

function PillCards({totalTeamCost, averageEmployeeCost}) {
    return (
        <div className='flex flex-wrap gap-[32px]'>
            <div className='flex justify-between items-center gap-[12px] w-full max-w-[100%] h-[100px] sm:max-w-[calc(50%-16px)] p-[24px] rounded-[32px] bg-[#363D50]'>
                <div className='flex flex-col gap-[6px] w-[203px] h-[51px]'>
                    <span className='text-primary !text-[18px]'>{`$${formatedAmount(totalTeamCost)}`}</span>
                    <span className='text-secondary !leading-[21px]'> Total team cost</span>
                </div>
            </div>

            <div className='flex justify-center flex-col w-full max-w-[100%] h-[100px] py-[16px] px-[24px] gap-[6px] sm:max-w-[calc(50%-16px)] rounded-[32px] bg-[#363D50]'>
                <span className='text-primary !text-[18px]'>{`$${formatedAmount(averageEmployeeCost)}`}</span>
                <span className='text-secondary !leading-[21px]'> Average employee cost</span>
            </div>
        </div>
    )
}

export default PillCards