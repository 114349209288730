import React, { useState } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function StartDate({start_date, setStart_date}) {
  // const [startDate, setStartDate] = useState(new Date());


  return (
    <DatePicker
      selected={start_date}
      onChange={(date) => setStart_date(date)}
      dateFormat="MMM dd yyyy"
    />
  );
};

export default StartDate;