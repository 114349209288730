import React, { useEffect, useState } from 'react'
import BasicModal from '../../../../components/models/models';
import Header from './components/header';
import NegativeFeedbackCard from './components/negative-feedback-card';
import PillCards from './components/pill-cards';
import RequestAndUserCostCards from './components/request-and-user-cost-cards';
import { useDispatch, useSelector } from 'react-redux';
import { getUsageReportData, selectCompanyDashboard } from '../../../../redux/slices/companyDashboardSlice';
import { useParams } from 'react-router-dom';
import Button from './../../../../components/Buttons/button';
import robotArms from '../../../../assets/images/robotArms.png';

function ServicesModule({show , setShow, path}) {
    const [popup, setPopup] = useState(false);
    const [feedServiceId, setFeedServiceID] = useState();
    const [state, setState] = useState(0);
    const [feedbackID, setFeedbackID] = useState()
    const [fedbackidserve, setfedbackidserve] = useState()
    const [ dashboard, setDashboard ] = useState('')
    const [manageId, setManageId] = useState("")

    const State = useSelector(selectCompanyDashboard);
    const { usageReportData } = State;
    const dispatch = useDispatch();
    const { id } = useParams()
    const serviceId = usageReportData?.service?.id;
    const setupServices = usageReportData?.setup_services
    const showFinishSetupButton = setupServices && setupServices.some(service => service.id === serviceId);

    useEffect(() => {
      if(id){
        dispatch(getUsageReportData(id));
      }
    }, [id, popup]);

    useEffect(() => {
      if (setupServices && setupServices.length > 0) {
          setManageId(serviceId);
      }
    }, [setupServices, serviceId]);

    return (
      <div className='right_panel relative w-[100%] flex flex-col px-[20px] lg:px-[32px] pt-[0px]'>
        <Header
          show={show}
          setShow={setShow}
          path={path}
          data={usageReportData?.service}
          owner_authorized={usageReportData?.owner_authorized}
          setPopup={setPopup}
          setManageId={setManageId}
          setState={setState}
          setupServices={showFinishSetupButton}
        />

        { showFinishSetupButton ? (
          <div className="card-section">
            <div className='cards_wrap inner_space flex flex-col gap-[40px] h-[100vh] lg:h-[calc(100vh-135px)] overflow-y-scroll scrollbar-hide home_module'>
              <div className="imgWrapper">
                <img src={robotArms} alt="img" className="boxImg" />
                <div className="boxHeading">Ready this service for reporting</div>
                <div className="boxText">Finish setup for this service so thryft can use it for report generation on <span className="date">00/00/00</span>.</div>
                <Button
                   onClick={() => {
                     setPopup(true);
                     setState(32);
                   }}
                  primary
                  fill
                  hoverColor
                >
                  Finish setup
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-section">
            <div className='cards_wrap flex flex-col gap-[32px] h-[100vh] lg:h-[680px] pt-[32px] !pb-[37px] overflow-y-scroll scrollbar-hide'>
              <PillCards
                data={usageReportData}
                id={id}
                serviceId={serviceId}
                setPopup={setPopup}
                setState={setState}
                setFeedServiceID={setFeedServiceID}
                setDashboard={setDashboard}
              />

              <RequestAndUserCostCards
                data={usageReportData}
                setPopup={setPopup}
                setState={setState}
                setDashboard={setDashboard}
                setfedbackidserve={setfedbackidserve}
                id={id}
                serviceId={serviceId}
              />

              <NegativeFeedbackCard
                setFeedbackID={setFeedbackID}
                feedback={usageReportData?.negative_feedback}
                setPopup={setPopup}
                id={id}
                setState={setState}
                serviceId={serviceId}
                setDashboard={setDashboard}
              />
            </div>
          </div>
        )}

        {popup && (
            <BasicModal
                feedBackId={feedbackID}
                state={state}
                popup={popup}
                setPopup={setPopup}
                setState={setState}
                id= { fedbackidserve && fedbackidserve || manageId }
                RequestserviceID = { serviceId }
                feedServiceId = {feedServiceId}
                serviceId = {serviceId}
                dashboard={dashboard}
                feedid={{id:id, feedbackId:feedbackID }}
            />
        )}
      </div>
    )
}

export default ServicesModule