import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../../components/Buttons/button";
import Pill from "../../../../../components/Pill/pill";
import {
  getUsageRequestData,
  getUsageServiceData,
  getEmployeeSpecficData,
  getUsageTeamServiceUserData,
  getUsageTeamRequestData,
  selectCompanyDashboard,
} from "../../../../../redux/slices/companyDashboardSlice";
import { formatedAmount } from "../../../../../utils/helpers";

let currentUserId = null;

function RequestAndUserCostCards({
  setPopup,
  setState,
  data,
  id,
  userId,
  setfedbackidserve,
  serviceId,
  setDashboard,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const goToEmployeeReport = async (id, serviceid) => {
    navigate(
      `/company_dashboard/services/employee/employee_report/${serviceid}?dataId=${id}`,
      {
        state: { id: id, serviceid: serviceid, page: "company_dashboard" },
        replace: false,
      }
    );
  };
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-[48px] justify-center">
      <div className="flex flex-col bg-[#363D50] pb-[16px] rounded-[24px] w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.9993 12.5001C18.2085 12.5001 19.9993 10.7092 19.9993 8.50008C19.9993 6.29094 18.2085 4.50008 15.9993 4.50008C13.7902 4.50008 11.9993 6.29094 11.9993 8.50008C11.9993 10.7092 13.7902 12.5001 15.9993 12.5001ZM15.9993 13.8334C18.9449 13.8334 21.3327 11.4456 21.3327 8.50008C21.3327 5.55456 18.9449 3.16675 15.9993 3.16675C13.0538 3.16675 10.666 5.55456 10.666 8.50008C10.666 11.4456 13.0538 13.8334 15.9993 13.8334Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6 24.5002C6 19.7137 9.8802 15.8335 14.6667 15.8335H17.3333C22.1198 15.8335 26 19.7137 26 24.5002V25.8335C26 27.6744 24.5076 29.1668 22.6667 29.1668H9.33333C7.49238 29.1668 6 27.6744 6 25.8335V24.5002ZM14.6667 17.1668C10.6166 17.1668 7.33333 20.4501 7.33333 24.5002V25.8335C7.33333 26.9381 8.22876 27.8335 9.33333 27.8335H22.6667C23.7712 27.8335 24.6667 26.9381 24.6667 25.8335V24.5002C24.6667 20.4501 21.3834 17.1668 17.3333 17.1668H14.6667Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Highest cost users
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Users with a high price tag
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col px-[16px]">
          {/* <div className='px-[8px]'><div className='w-[100%] h-[1px] bg-[#41485C]' /></div> */}

          <div className="flex flex-col max-w-[100%] h-[360px] px-[3px] py-[3px] overflow-y-scroll scrollbar-hide">
            {data?.highest_cost_users?.map((employee) => (
              <div
                key={employee.id}
                className="flex justify-between items-center card-animate cursor-pointer py-[25px] px-[5px] border-t-[1px] border-[#41485C] hover:rounded-[8px]"
                // onClick={() => {
                //   goToEmployeeReport(employee.id, serviceId);
                // }}
              >
                <div className="flex gap-[16px] items-center">
                  <div className="w-[40px] h-[40px] flex justify-center items-center rounded-full bg-[#BD3CFF] bg-opacity-20">
                    <span className="flex w-[40px] h-[40px] items-center justify-center text-primary !text-[14px] !font-semibold !text-[#BD3CFF]">
                      <span>{employee.name[0]}</span>
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-left">
                      {employee.name}
                    </span>
                    <span className="text-secondary !leading-[20px] !text-left">
                      {employee.distinction}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary !font-medium !text-[14px] !leading-[20px] !text-right">
                    ${formatedAmount(employee.cost_per_month)}
                  </span>
                  <span className="text-secondary !leading-[20px] !text-right">
                    Cost per month
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            className={cn({
              invisible: !data?.highest_cost_users?.length,
            })}
            onClick={() => {
              setPopup(true);
              setState(19);
              // dispatch(getUsageServiceData(id));
              dispatch(getUsageTeamServiceUserData(id, userId));
            }}
            secondary
            hoverColor
          >
            View all
          </Button>
        </div>
      </div>

      <div className="flex flex-col bg-[#363D50] rounded-[24px] pb-[16px] w-[100%]">
        <div className="flex flex-col p-[24px]">
          <div className="flex items-center gap-[16px]">
            <div className="flex justify-center items-center bg-[#242B3E] p-[8px] rounded-[16px] w-[48px] h-[48px] gap-[10px]">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.66634 9.33333C4.66634 8.22876 5.56177 7.33333 6.66634 7.33333H25.333C26.4376 7.33333 27.333 8.22876 27.333 9.33333V20C27.333 21.1046 26.4376 22 25.333 22H19.7709C18.8869 22 18.039 22.3512 17.4139 22.9763L12.1949 28.1953C11.9346 28.4556 11.9346 28.8777 12.1949 29.1381C12.4553 29.3984 12.8774 29.3984 13.1377 29.1381L18.3567 23.9191C18.7318 23.544 19.2405 23.3333 19.7709 23.3333H25.333C27.174 23.3333 28.6663 21.8409 28.6663 20V9.33333C28.6663 7.49238 27.174 6 25.333 6H6.66634C4.82539 6 3.33301 7.49238 3.33301 9.33333V20C3.33301 21.8409 4.82539 23.3333 6.66634 23.3333H11.333C11.7012 23.3333 11.9997 23.0349 11.9997 22.6667C11.9997 22.2985 11.7012 22 11.333 22H6.66634C5.56177 22 4.66634 21.1046 4.66634 20V9.33333ZM10.6663 14C10.2982 14 9.99967 14.2985 9.99967 14.6667C9.99967 15.0349 10.2982 15.3333 10.6663 15.3333H10.6797C11.0479 15.3333 11.3463 15.0349 11.3463 14.6667C11.3463 14.2985 11.0479 14 10.6797 14H10.6663ZM15.9997 14C15.6315 14 15.333 14.2985 15.333 14.6667C15.333 15.0349 15.6315 15.3333 15.9997 15.3333H16.013C16.3812 15.3333 16.6797 15.0349 16.6797 14.6667C16.6797 14.2985 16.3812 14 16.013 14H15.9997ZM21.333 14C20.9648 14 20.6663 14.2985 20.6663 14.6667C20.6663 15.0349 20.9648 15.3333 21.333 15.3333H21.3463C21.7145 15.3333 22.013 15.0349 22.013 14.6667C22.013 14.2985 21.7145 14 21.3463 14H21.333Z"
                  fill="white"
                />
              </svg>
            </div>

            <div className="flex flex-start flex-col gap-[2px]">
              <div className="flex gap-[2px] text-primary !text-[16px] !leading-[24px]">
                Recent Requests
              </div>
              <span className="text-secondary !text-[14px] !leading-[20px]">
                Requests that you have recently interacted with
              </span>
            </div>
          </div>
        </div>

        <div className="flex flex-col h-[360px] overflow-y-scroll scrollbar-hide px-[16px]">
          <div className="flex flex-col px-[8px] py-[3px]">
            {data?.recent_requests?.map((item) => (
              <div
                onClick={() => {
                  setPopup(true);
                  setState(14);
                  setDashboard("companyDahboard");
                  setfedbackidserve({ id: item.id, userId: item.user_id });
                }}
                key={item.id}
                className="flex flex-col card-animate border-t-[1px] border-[#41485C] hover:rounded-[8px] cursor-pointer"
              >
                {/* <div className='h-[1px] bg-[#41485C] w-[100%]' /> */}
                <div className="flex flex-col h-[90px] px-[3px] gap-[8px] justify-center">
                  <div className="flex flex-row justify-between gap-[4px]">
                    <Pill fill={true}>{item.request_type}</Pill>
                    <div className="flex flex-row gap-[8px] items-center">
                      <div className="flex flex-row gap-[8px] items-center">
                        <span className="text-secondary">
                          {item.comments_count == 0 ? "-" : item.comments_count}
                        </span>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.75 1.25C7.0266 1.25165 5.37426 1.93701 4.15563 3.15563C2.93701 4.37426 2.25165 6.0266 2.25 7.75V13.0188C2.25 13.3453 2.37972 13.6585 2.61062 13.8894C2.84153 14.1203 3.1547 14.25 3.48125 14.25H8.75C10.4739 14.25 12.1272 13.5652 13.3462 12.3462C14.5652 11.1272 15.25 9.47391 15.25 7.75C15.25 6.02609 14.5652 4.37279 13.3462 3.15381C12.1272 1.93482 10.4739 1.25 8.75 1.25ZM8.75 12.75H3.75V7.75C3.75 6.76109 4.04324 5.79439 4.59265 4.97215C5.14206 4.1499 5.92295 3.50904 6.83658 3.1306C7.75021 2.75216 8.75555 2.65315 9.72545 2.84607C10.6954 3.039 11.5863 3.5152 12.2855 4.21447C12.9848 4.91373 13.461 5.80464 13.6539 6.77455C13.8469 7.74445 13.7478 8.74979 13.3694 9.66342C12.991 10.577 12.3501 11.3579 11.5279 11.9073C10.7056 12.4568 9.73891 12.75 8.75 12.75ZM11.25 6.75C11.25 6.94891 11.171 7.13968 11.0303 7.28033C10.8897 7.42098 10.6989 7.5 10.5 7.5H6.75C6.55109 7.5 6.36032 7.42098 6.21967 7.28033C6.07902 7.13968 6 6.94891 6 6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H10.5C10.6989 6 10.8897 6.07902 11.0303 6.21967C11.171 6.36032 11.25 6.55109 11.25 6.75ZM11.25 9.25C11.25 9.44891 11.171 9.63968 11.0303 9.78033C10.8897 9.92098 10.6989 10 10.5 10H6.75C6.55109 10 6.36032 9.92098 6.21967 9.78033C6.07902 9.63968 6 9.44891 6 9.25C6 9.05109 6.07902 8.86032 6.21967 8.71967C6.36032 8.57902 6.55109 8.5 6.75 8.5H10.5C10.6989 8.5 10.8897 8.57902 11.0303 8.71967C11.171 8.86032 11.25 9.05109 11.25 9.25Z"
                            fill="#A5AEBC"
                          />
                        </svg>
                        <span className="text-secondary">
                          {item.requested_on}
                        </span>
                      </div>
                      {item.status == "open" ? (
                        <Pill greenFill={true} className="text-[12px] py-[4px]">
                          {item.status}
                        </Pill>
                      ) : (
                        <Pill redFill={true} className="text-[12px] py-[4px]">
                          {item.status}
                        </Pill>
                      )}
                    </div>
                  </div>
                  <span className="text-secondary !leading-[20px]">
                    {item.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-center items-center h-[49px]">
          <Button
            className={cn({
              invisible: !data?.recent_requests?.length,
            })}
            onClick={() => {
              setPopup(true);
              setState(20);
              setDashboard("companyDahboard");
              dispatch(getUsageTeamRequestData(id, userId));
            }}
            secondary
            hoverColor
          >
            View all
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RequestAndUserCostCards;
