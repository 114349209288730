import React from "react";

function Pill({
  children,
  fill,
  outline,
  greenFill,
  redFill,
  check,
  cross,
  link,
  className,
  round,
}) {
  return (
    <div
      className={`
        rounded-[6px] manageService w-[fit-content] h-[fit-content] py-[6px] ${
          !link && "px-[8px]"
        } leading-[16px] font-semibold text-[14px]
        ${fill && "bg-[#434A5D]"}
        ${outline && "border-[1px] border-[#596073]"}
        ${round && "!rounded-full"}
        ${
          greenFill &&
          `bg-[#6BE261] bg-opacity-[0.08] ${
            check && "!py-[7.5px] !px-[6px]"
          } !text-[#6BE261]`
        }
        ${
          redFill &&
          `bg-[#FF5555] bg-opacity-[0.08] ${
            cross && "!py-[7.5px] !px-[6px]"
          } !text-[#ED4650]`
        }
        ${className}
    `}
    >
      {children&&typeof(children)!=="object"?(children.charAt(0).toUpperCase() + children.slice(1)):children}
      {check && (
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.99999 6.60078L9.89999 0.700781C10.0833 0.517448 10.3167 0.425781 10.6 0.425781C10.8833 0.425781 11.1167 0.517448 11.3 0.700781C11.4833 0.884114 11.575 1.11745 11.575 1.40078C11.575 1.68411 11.4833 1.91745 11.3 2.10078L4.69999 8.70078C4.49999 8.90078 4.26665 9.00078 3.99999 9.00078C3.73332 9.00078 3.49999 8.90078 3.29999 8.70078L0.699987 6.10078C0.516654 5.91745 0.424988 5.68411 0.424988 5.40078C0.424988 5.11745 0.516654 4.88411 0.699987 4.70078C0.883321 4.51745 1.11665 4.42578 1.39999 4.42578C1.68332 4.42578 1.91665 4.51745 2.09999 4.70078L3.99999 6.60078Z"
            fill="#6BE261"
          />
        </svg>
      )}

      {cross && (
        <svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.50557 6.14555V5.14441L4.65191 5.99854L1.28501 9.36736C1.28497 9.36739 1.28494 9.36742 1.28491 9.36746C1.19998 9.45232 1.08484 9.5 0.964776 9.5C0.844668 9.5 0.729477 9.45229 0.644547 9.36736C0.559616 9.28243 0.511903 9.16724 0.511903 9.04713C0.511903 8.92703 0.559604 8.81186 0.644513 8.72693C0.644525 8.72692 0.644536 8.72691 0.644547 8.7269L4.01279 5.35929L4.36645 5.0057L4.01279 4.65211L0.643878 1.28384L0.643928 1.28379L0.637886 1.27794C0.594681 1.23618 0.560228 1.18622 0.536535 1.13099C0.512843 1.07577 0.500387 1.01638 0.499893 0.956286C0.4994 0.896194 0.510879 0.836606 0.53366 0.780998C0.556442 0.72539 0.59007 0.674875 0.632583 0.632403C0.675095 0.58993 0.725641 0.55635 0.78127 0.533621C0.8369 0.510891 0.896499 0.499469 0.95659 0.500019C1.01668 0.500569 1.07606 0.513082 1.13126 0.536826C1.18647 0.56057 1.23639 0.595071 1.27812 0.638315L1.27806 0.63837L1.28437 0.64468L4.65201 4.01232L5.0056 4.36591L5.35915 4.01229L8.72616 0.644647L8.72621 0.644597C8.76824 0.602554 8.81813 0.5692 8.87304 0.54644C8.92795 0.52368 8.98681 0.511958 9.04625 0.511944C9.10569 0.51193 9.16456 0.523624 9.21948 0.546359C9.2744 0.569093 9.32431 0.602424 9.36635 0.644445C9.4084 0.686469 9.44175 0.736361 9.46451 0.791271C9.48727 0.846183 9.49899 0.905041 9.49901 0.964485C9.49902 1.02393 9.48733 1.08279 9.46459 1.13771C9.44186 1.19264 9.40853 1.24255 9.3665 1.28459L5.99834 4.65211L5.64466 5.00573L5.99838 5.35932L9.36722 8.7269C9.45215 8.81183 9.49987 8.92702 9.49987 9.04713C9.49987 9.16724 9.45215 9.28243 9.36722 9.36736C9.28229 9.45229 9.1671 9.5 9.04699 9.5C8.9269 9.5 8.81172 9.4523 8.7268 9.36739C8.72679 9.36738 8.72678 9.36737 8.72676 9.36736L5.50557 6.14555Z"
            fill="#ED4650"
            stroke="#ED4650"
          />
        </svg>
      )}

      {link && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.544 10.4565C12.7249 9.63768 11.6141 9.17773 10.456 9.17773C9.29784 9.17773 8.1871 9.63768 7.36799 10.4565L4.27899 13.5444C3.45987 14.3636 2.99969 15.4745 2.99969 16.6329C2.99969 17.7914 3.45987 18.9023 4.27899 19.7215C5.09811 20.5406 6.20908 21.0007 7.36749 21.0007C8.52591 21.0007 9.63687 20.5406 10.456 19.7215L12 18.1775"
            stroke="#FFD74A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.456 13.5443C11.2751 14.3631 12.3858 14.823 13.544 14.823C14.7021 14.823 15.8129 14.3631 16.632 13.5443L19.721 10.4563C20.5401 9.63718 21.0003 8.52621 21.0003 7.3678C21.0003 6.20939 20.5401 5.09842 19.721 4.2793C18.9019 3.46018 17.7909 3 16.6325 3C15.4741 3 14.3631 3.46018 13.544 4.2793L12 5.8233"
            stroke="#FFD74A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
}

export default Pill;
