import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { getInitials } from '../../../shared/lib/getInitials';
import { getRandomHex } from '../../../shared/lib/getRandomHex';


function EmployeeTableBody({ tableData, setManagerId, show, setShow, model = false }) {
    const navigate = useNavigate();

    return (
        // <tbody>
        //     {tableData.map((row, index) => (
        //         <tr
        //             key={index}
        //             className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer"
        //             onClick={() => { navigate("/company_dashboard/services/service_details"); }}
        //         >
        //             {Object.keys(row).map((item, index2) => (
        //                 <td
        //                     key={index2}
        //                     className="text-secondary !text-[#FFFFFF] py-[24px] px-[16px]"
        //                 >
        //                     {item === "service" && <span className="flex gap-[4px] items-center"> {row[item]}</span>}
        //                     {item === "employee" && (
        //                         <div className="flex flex-col md:flex-row items-center gap-[10px]">
        //                             <img
        //                                 className="w-[40px] h-[40px]"
        //                                 src={row[item]["image"]}
        //                                 alt="service-img"
        //                             />
        //                             <div className="flex flex-col">
        //                                 <span className="text-secondary !text-[#FFFFFF] !text-left">{row[item]["name"]}</span>
        //                                 <span className="text-secondary !text-left">
        //                                     {row[item]["category"]}
        //                                 </span>
        //                             </div>
        //                         </div>
        //                     )}
        //                     {item === "monthly_cost" && 
        //                     <span className="flex gap-[4px] items-center"> {row[item]}</span>}

                            
        //                     {item !== "service" &&
        //                         item !== "monthly_cost" &&
        //                         item !== "employee" &&
        //                         row[item]}
        //                 </td>
        //             ))}
        //         </tr>
        //     ))}
        // </tbody>
//         <div className="overflow-x-auto">
//   <table className="w-full">
  <tbody>
          {tableData?.map((row, index) => {
            const [hexColor, hexWithOpacity] = getRandomHex()

            return (
              <tr
                key={index}
                className="hover:bg-[#363D50] hover:brightness-110 cursor-pointer text-left"
              >
                {Object.keys(row).filter(item => item !== 'id' && item !== "also_manager").map((item, index2) => (
                  <td
                    key={index2}
                    className="text-secondary !text-[#fff] py-[24px] px-[15px] !font-medium"
                    onClick={() => { item !== "more_action" ? navigate(`/company_dashboard/employees/employee_report/${row.id}`) : setManagerId(row.id) }}
                  >
                    {item === "service" && <span className="flex gap-[4px] items-center !font-medium"> {row[item]}</span>}
                    {item === "employee" && (
                      <div className="flex flex-col md:flex-row items-center sm:items-start max-xs:items-start gap-[10px]">
                        <div
                          style={{
                            backgroundColor: hexWithOpacity,
                            color: hexColor,
                          }}
                          className="w-[40px] h-[40px] rounded-full flex items-center justify-center"
                        >
                          {getInitials(row[item]["name"])}
                        </div>
                        <div className="flex flex-col">
                          <span className="text-secondary !text-[#FFFFFF] !text-left !font-medium inline_text">{row[item]["name"]}</span>
                          <span className="text-secondary !text-left inline_text">
                            {row[item]["category"]}
                          </span>
                        </div>
                      </div>
                    )}
                    {item === "monthly_cost" &&
                      <span className="flex gap-[4px] items-center !font-medium"> {row[item]}</span>}
  
                    { item === "more_action" && row.also_manager &&
                      <Link to={`/company_dashboard/employees/${row.id}`} className="custom_btn inline_text">See Team Details</Link>
                    }
                    
                    {item !== "service" &&
                      item !== "monthly_cost" &&
                      item !== "employee" &&
                      item !== "more_action" &&
                      row[item]}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
//   </table>
// </div>

    );
}

export default EmployeeTableBody;
