import Image from "../assets/images/model_Image.svg";

export const heading = [
    "Service",
    "Request Type",
    "Status",
    "Reason",
    "Comments",
    "Request date"
]

export const tableData = [
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-In",
        status: "Closed",
        reason: {
            heading: "Try this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-out",
        status: "Open",
        reason: {
            heading: "Don’t use this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-In",
        status: "Closed",
        reason: {
            heading: "Try this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-out",
        status: "Open",
        reason: {
            heading: "Don’t use this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-In",
        status: "Closed",
        reason: {
            heading: "Try this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-out",
        status: "Open",
        reason: {
            heading: "Don’t use this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-In",
        status: "Closed",
        reason: {
            heading: "Try this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-out",
        status: "Open",
        reason: {
            heading: "Don’t use this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Change",
        status: "Open",
        reason: {
            heading: "Better suite my job",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-In",
        status: "Closed",
        reason: {
            heading: "Try this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
    {
        service: {
            image: Image,
            name: "Service name",
            category: "Category"
        },
        type: "Opt-out",
        status: "Open",
        reason: {
            heading: "Don’t use this service",
            subHeading: "“Lorem ipsum dolor sit amet, consec...”"
        },
        comment: "-",
        date: "03/11/2023"
    },
]