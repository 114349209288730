import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { ResetPasswordRequest, selectUser, setLoading } from '../../redux/slices/userSlice';
import {toast} from 'react-toastify';
import SuccessIcon from "../../assets/icons/success-check.svg";

function PasswordReset() {

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation()

    const dispatch = useDispatch()
    const user = useSelector(selectUser)
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
  
      if (email === '') {
        setErrorMessage('* Email is required');
      } else {
        // Perform Password Reset logic here
        dispatch(setLoading(true))
        localStorage.setItem('resetPassCalled', 'true')
        dispatch(ResetPasswordRequest({
          email: email,
        }))
      }
    };

    useEffect(() => {

      if(localStorage.getItem('resetPassCalled') === 'true' && !user.isLoading){
        localStorage.removeItem('resetPassCalled')
        dispatch(setLoading(false))
        if(user.success){
          toast.success(user.message, {
            icon: ({theme, type}) =>  <img className="w-8 h-8" src={SuccessIcon} alt="success icon"/>
          });
          location.pathname = '/login'
        }else{
          toast.error(user.message)
        }
      }
      
    }, [user])
  
    return (
        <div className="flex flex-col items-center justify-center min-h-screen px-5 sm:px-0">
        <form onSubmit={handleSubmit} className="bg-[#242B3E] shadow-md rounded px-6 pt-10 pb-10 sm:px-12 sm:pt-[70px] sm:pb-[70px] mb-4 w-full sm:w-[450px] min-h-[400px] flex justify-center gap-10 flex-col">
            <div>
            <label className="block text-white text-[20px] font-bold">
              Reset Password
            </label>
            </div>
          <div>
            <label htmlFor="email" className="block text-white font-bold mb-2">
              Email
            </label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-end justify-between flex-col">
            <button
              type="submit"
              className=" bg-[#FFD74A] hover:brightness-125 !text-[#242B3E] font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full uppercase"
            >
              Reset Password
            </button>
            <span className='mt-4'>Remember your password <Link to="/login" className='underline'>Login</Link></span>
          </div>
          {errorMessage && (
            <div className="mt-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
        </form>
      </div>
    )
}

export default PasswordReset