

export const heading = [
  "Service",
  "Categories",
  "Total Submission",
  "Missing #",
  "Suggested #",
  "Expensed #",
  "Service is Setup",
];
export const tableData = [
  {
    name: "Service Name",
    categories: {
      cat1:  "Software",
       cat2: "HR"
    },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
  {
    name: "Service Name",
    categories: {
        cat1:  "Software",
         cat2: "HR"
      },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
  {
    name: "Service Name",
    categories: {
        cat1:  "Software",
         cat2: "HR"
      },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
  {
    name: "Service Name",
    categories: {
        cat1:  "Software",
         cat2: "HR"
      },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
  {
    name: "Service Name",
    categories: {
        cat1:  "Software",
         cat2: "HR"
      },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
  {
    name: "Service Name",
    categories: {
        cat1:  "Software",
         cat2: "HR"
      },
    missing: 3,
    suggested: 6,
    expensed: 3,
  },
 
];
