const getInitials = (name) => {
  if (!name) {
    return;
  }

  const [firstNameSplit = '', secondNameSplit = ''] = name?.trim().split(' ')

  const firstName = firstNameSplit[0] ?? '';
  const secondName = secondNameSplit[0] ?? '';

  return firstName + secondName;
}

export { getInitials }