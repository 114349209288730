import React from 'react'
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'
import Button from '../../../../components/Buttons/button'
import image from '../../../../assets/images/Rectangle 1 (1).svg'

function Header({path}) {
    return (
        <header className="sticky top-[0px] w-[100%] z-[2] header-gradient flex flex-col gap-[16px]">
            <div className='w-[100%] h-[40px] flex py-[8px]'>
                <Breadcrumbs path={path} />
            </div>
            <div className='w-[100%] flex flex-col gap-[16px]'>
                <div className='flex flex-col md:flex-row md:justify-between pb-[8px] gap-[24px] md:items-center'>
                    <div className='flex flex-row justify-start gap-[24px] items-center'>
                        <img src={image} alt='service' className='w-[72px] h-[72px]' />
                        <div className='flex flex-col'>
                            <span className='text-primary !text-[24px] !leading-[36px]'>Kira Nyres</span>
                            <span className='text-secondary !text-[14px] !leading-[21px]'>Catgeory</span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] justify-end items-end'>
                        <Button outlined={true} hoverColor={true} secondary={true} secondaryHoverOutlined={true}>
                            Service Details
                        </Button>
                        <div className='flex gap-[4px]'>
                            <span className='text-secondary !text-[14px] !leading-[21px]'>Contract Renewal</span>
                            <span className='text-primary !text-[14px] !leading-[21px]'>03/11/2023</span>  
                        </div>
                    </div>
                </div>
                <div className='bg-[#41485C] w-[100%] h-[2px]' />
            </div>
        </header>
    )
}

export default Header